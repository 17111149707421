import {forkJoin as observableForkJoin, Subscription} from 'rxjs';
/**
 * Created by siminski on 25.05.2016.
 */
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  BusinessObjectService,
  FeatureService,
  GrowlService,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
  SettingsService,
  TaskService,
  UserService,
} from '../../bonding_shared/services';
import {TaskGuiService} from './services';
import {
  ActivitySimpleDto,
  BusinessObjectDto,
  BusinessUnitIdDto,
  DictionaryBaseDto,
  SearchResult,
  TaskCriteriaDto,
  TaskMassAssignDto,
  TaskMassClosureDto,
  UserDto,
  UserGroupBaseDto, UserIdDto,
  UserSimpleDto,
} from '../../bonding_shared/model';
import {TaskListComponent} from './components';
import {
  ApplicationModule,
  BusinessObjectType,
  ElementaryRight,
  Feature,
  TaskStatus,
  UserType,
} from '../../bonding_shared/model/dictionary-ids';
import {ComboItem} from '../../bonding_shared/components/combos';
import {TranslateService} from '@ngx-translate/core';
import {BusinessObjectUtils} from '../../bonding_shared/utils/business-object-utils';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog';
import {NgForm, UntypedFormGroup} from '@angular/forms';
import {UserNamePipe} from '../../bonding_shared/pipes/user-name.pipe';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';

@Component({
  selector: 'task-search',
  templateUrl: 'task-search.component.html',
})
export class TaskSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(TaskListComponent, {static: true})
  taskListCmp: TaskListComponent;

  @ViewChild(FormDialogComponent, {static: true}) massAssignDialog: FormDialogComponent;
  @ViewChild('massClosure', {static: true}) massClosureDialog: ConfirmDialogComponent;

  hiddenModulesIds = new Set<number>([ApplicationModule.SALES]);
  taskSearchExecuting = false;
  users: UserIdDto[];
  groups: UserGroupBaseDto[];
  groupsForBu: UserGroupBaseDto[];
  usersForBu: UserIdDto[];
  groupsForMassAssign: UserGroupBaseDto[];
  usersForMassAssign: UserIdDto[];
  moduleId: number;
  selectedModuleId: number;
  private subscription: Subscription;
  visibilityFilters: ComboItem[] = [];
  dueDateFilters: ComboItem[] = [];
  categoryFilters: ComboItem[] = [];
  vipFilters: ComboItem[] = [];
  importantFilters: ComboItem[] = [];

  businessObjectsFromSearch: BusinessObjectDto[] = [];
  businessObject: BusinessObjectDto;
  showVersions: boolean;

  relatedToRegExp: RegExp;
  self = this;
  showErrors = false;
  massAssignUser: UserSimpleDto;
  massAssignGroup: UserGroupBaseDto;

  performedSearchCriteria: TaskCriteriaDto;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  form: UntypedFormGroup;

  get criteria(): TaskCriteriaDto {
    return this.taskSearchService.searchDataProvider.searchCriteria.criteria;
  }

  set criteria(c: TaskCriteriaDto) {
    this.taskSearchService.searchDataProvider.searchCriteria.criteria = c;
  }

  get dataProvider(): SearchDataProvider<TaskCriteriaDto, ActivitySimpleDto> {
    return this.taskSearchService.searchDataProvider;
  }

  constructor(
    public _router: RouterService,
    public _route: ActivatedRoute,
    public taskSearchService: TaskGuiService,
    public taskService: TaskService,
    private _loggedUserService: LoggedUserService,
    settingsService: SettingsService,
    private userService: UserService,
    private translateService: TranslateService,
    public appConfig: AppConfigService,
    private growlService: GrowlService,
    private businessObjectService: BusinessObjectService,
    featureService: FeatureService
  ) {
    if (!this.criteria) {
      taskSearchService.resetCriteria();
    }
    if (settingsService.getActiveModuleId()) {
      this.moduleId = settingsService.getActiveModuleId();
      this.selectedModuleId = settingsService.getActiveModuleId();
      this.taskSearchService.searchDataProvider.searchCriteria.criteria.applicationModule = <DictionaryBaseDto>{
        id: this.moduleId,
      };
    }
    this.subscription = settingsService.viewChange.subscribe((moduleId) => {
      this.moduleId = moduleId;
      this.selectedModuleId = moduleId;
      this.taskSearchService.searchDataProvider.searchCriteria.criteria.applicationModule = <DictionaryBaseDto>{
        id: this.moduleId,
      };
    });
    if (appConfig.kuke) {
      this.relatedToRegExp = BusinessObjectUtils.getKukeBoRegexp();
    }
    this.buildFilters();
    this.showVersions = featureService.get(Feature.TASK_SEARCH_ALLOW_VERSIONS);
  }

  private buildFilters() {
    this.dueDateFilters.push(
      new ComboItem('BEFORE_TIME', this.translateService.instant('task.search.dueDateFilter.before'))
    );
    this.dueDateFilters.push(
      new ComboItem('ON_DAY', this.translateService.instant('task.search.dueDateFilter.onTime'))
    );
    this.dueDateFilters.push(
      new ComboItem('OVERDUE', this.translateService.instant('task.search.dueDateFilter.overdue'))
    );
    this.categoryFilters.push(
      new ComboItem('AUTOMATIC', this.translateService.instant('task.search.categoryFilter.automatic'))
    );
    this.categoryFilters.push(
      new ComboItem('MANUAL', this.translateService.instant('task.search.categoryFilter.manual'))
    );

    this.importantFilters.push(
      new ComboItem('IMPORTANT', this.translateService.instant('task.search.importantFilter.important'))
    );
    this.importantFilters.push(
      new ComboItem('NORMAL', this.translateService.instant('task.search.importantFilter.normal'))
    );
    this.vipFilters.push(new ComboItem('VIP', this.translateService.instant('task.search.vipFilter.vip')));
    this.vipFilters.push(new ComboItem('NORMAL', this.translateService.instant('task.search.vipFilter.normal')));

    if (this._loggedUserService.hasRight(ElementaryRight.TASK_LIMIT_ONLY_MINE)) {
      this.visibilityFilters.push(
        new ComboItem('MY_TASKS', this.translateService.instant('task.predefinedFilter.myTasks'))
      );
    }
    if (this._loggedUserService.hasRight(ElementaryRight.TASK_LIMIT_ONLY_MY_GROUP)) {
      this.visibilityFilters.push(
        new ComboItem('MY_GROUP_TASKS', this.translateService.instant('task.predefinedFilter.myGroupTasks'))
      );
    }
    if (this._loggedUserService.hasRight(ElementaryRight.TASK_VIEW_OTHER_GROUPS)) {
      this.visibilityFilters.push(
        new ComboItem(
          'TASKS_IN_OTHER_GROUPS_IN_MODULE',
          this.translateService.instant('task.predefinedFilter.otherGroupsInModule')
        )
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.loadUsersAndGroups();
    this.taskListCmp.taskTable.dataProviderSearchFinished.subscribe((result) => this.searchFinished(result));
    this.form = this.ngForm.form;
  }

  ngAfterViewInit(): void {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: any) {
    if (params['boTypeId'] && params['boId']) {
      const boTypeId = +params['boTypeId'];
      const boId = +params['boId'];
      this.businessObjectService.getDto(boTypeId, boId).subscribe((dto) => {
        this.taskSearchService.resetCriteria();
        if (boTypeId === BusinessObjectType.COMPANY && this.appConfig.kuke) {
          this.criteria.companyId = boId;
        } else {
          this.criteria.businessObject = dto;
          if (this.allowBOVersions()) {
            this.criteria.includeBoVersions = true; // defalut value
          }
        }
        this._route.queryParams.subscribe((queryParams) => {
          if (queryParams['assignedToMe']) {
            this.criteria.assignedToMe = JSON.parse(queryParams['assignedToMe']);
          }
          this.autoRefreshTable();
        });
      });
    } else {
      this.autoRefreshTable();
    }
  }

  private loadUsersAndGroups() {
    observableForkJoin([this.userService.getIntranetUsers(), this.userService.getUserGroups()]).subscribe((res) => {
      this.users = <UserIdDto[]>res[0];
      this.groups = <UserGroupBaseDto[]>res[1];
      this.setGroupsAndUsersForBu();
    });
  }

  private loadUsersAndGroupsForMassAssign() {
    observableForkJoin([this.userService.getIntranetUsers(), this.userService.getUserGroups()]).subscribe((res) => {
      this.usersForMassAssign = <UserIdDto[]>res[0];
      this.groupsForMassAssign = <UserGroupBaseDto[]>res[1];
    });
  }

  userFullName = (u: UserIdDto): string => {
    return new UserNamePipe().transform(u);
  };

  private setGroupsAndUsersForBu() {
    if (this.criteria.businessUnit) {
      this.groupsForBu = this.groups.filter((g) => g.businessUnit.id === this.criteria.businessUnit.id);
      this.usersForBu = this.users.filter(
        (g) => g.businessUnit.id === this.criteria.businessUnit.id && g.userType.id === UserType.INTRANET
      );
    } else {
      this.groupsForBu = this.groups;
      this.usersForBu = this.users.filter((u) => u.userType.id === UserType.INTRANET);
    }
  }

  buChanged(bu: BusinessUnitIdDto) {
    this.criteria.user = undefined;
    this.criteria.userGroup = undefined;
    this.setGroupsAndUsersForBu();
  }

  massAssignPopupOpen() {
    if (
      this.taskListCmp.taskTable.items.find(
        (t) => t.status.id === TaskStatus.CANCELLED || t.status.id === TaskStatus.CLOSED
      )
    ) {
      this.growlService.warning('task.search.massAssignOnlyOpen');
      return;
    }
    this.loadUsersAndGroupsForMassAssign();
    const confirmationPromise: Promise<boolean> = this.massAssignDialog.open(
      this.translateService.instant('task.search.massAssignTitle')
    );
    confirmationPromise.then((result) => this.massAssign(result));
  }

  massAssign(assign: boolean) {
    if (assign) {
      const req: TaskMassAssignDto = {
        criteriaDto: this.taskSearchService.searchDataProvider.searchCriteria.criteria,
        userGroup: this.massAssignGroup,
        user: this.massAssignUser,
      };
      this.taskListCmp.dataProvider.inProgress = true;
      this.taskService.massAssignTasks(req).subscribe({
        next: (c) => {
          this.massAssignUser = undefined;
          this.massAssignGroup = undefined;
          this.taskListCmp.taskTable.search();
          this.growlService.notice('task.search.massAssignSuccess', null, {taskCount: c});
          this.taskListCmp.dataProvider.inProgress = true;
        },
        error: (error) => {
          this.taskListCmp.dataProvider.inProgress = false;
        },
        complete: () => {
          this.taskListCmp.dataProvider.inProgress = false;
        },
      });
    }
  }

  generateReport() {
    const taskIds: number[] = [];
    this.taskListCmp.taskTable.items.forEach((a) => taskIds.push(a.id));
    this.taskService.generateReport(this.criteria).subscribe({
      next: () => {
        this.growlService.notice('common.reportGeneration');
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  businessObjectChanged(dto: BusinessObjectDto) {
    if (this.showVersions && dto && dto.hasVersions) {
      this.criteria.includeBoVersions = true; // default value
    } else {
      this.criteria.includeBoVersions = undefined;
    }
    if (this.businessObjectsFromSearch.find((bo) => BusinessObjectUtils.equals(bo, dto))) {
      this.businessObject = dto;
    } else {
      this.businessObject = undefined;
    }
  }

  businessObjectFromSearchChanged(dto: BusinessObjectDto) {
    this.criteria.businessObject = dto;
  }

  private searchFinished(result: SearchResult<ActivitySimpleDto>) {
    this.performedSearchCriteria = this.criteria;
    this.businessObjectsFromSearch = _.uniqWith(
      result.result.filter((t) => t.businessObject && t.businessObject.relatedToId).map((t) => t.businessObject),
      BusinessObjectUtils.equals
    );
    this.taskSearchExecuting = false;
  }

  clearBusinessObject() {
    this.businessObject = undefined;
    this.criteria.businessObject = undefined;
  }

  massClosurePopupOpen() {
    const msgParams = {};
    msgParams['count'] = this.taskListCmp.taskTable.items.length;
    this.massClosureDialog.openAndExecuteOnConfirm(
      'task.search.massClosure.popup.title',
      'task.search.massClosure.popup.msg',
      this.doMassClosure.bind(this),
      null,
      msgParams
    );
  }

  doMassClosure() {
    const req: TaskMassClosureDto = {ids: []};
    this.taskListCmp.taskTable.items.forEach((a) => req.ids.push(a.id));
    this.taskService.massClosureTasks(req).subscribe((c) => {
      this.taskListCmp.taskTable.search();
      this.growlService.notice('task.search.massClosure.success', null, {count: c});
    });
  }

  onlyMineChanged(onlyMineChecked: boolean) {
    if (onlyMineChecked) {
      this.criteria.businessUnit = undefined;
      this.criteria.user = undefined;
      this.criteria.userGroup = undefined;
    }
  }

  searchStarted() {
    this.taskSearchExecuting = true;
  }

  getUserGroupNameForTranslation() {
    if (this.appConfig.mehib) {
      return 'task.search.userGroup.';
    } else {
      return '';
    }
  }

  allowBOVersions(): boolean {
    return this.showVersions && this.criteria.businessObject && this.criteria.businessObject.hasVersions;
  }

  private autoRefreshTable() {
    if (!this.appConfig.kuke || this.portal) {
      this.taskListCmp.taskTable.search();
    }
  }

  get portal(): boolean {
    return this._loggedUserService.portal;
  }
}
