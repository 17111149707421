import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondVersionConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'responsibility-declaration-consent',
  template: `
    <div class="row flex">
      <!-- 22! -->
      <checkbox-entity
        class="start"
        name="responsibilityDeclaration"
        [(ngModel)]="consents.responsibilityDeclaration"
        [readonly]="readonly"
        [requiredTrue]="validate && true"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>
          Oświadczamy, że ponosimy wszelką odpowiedzialność za dane zawarte w niniejszym wniosku oraz dokumentach do
          niego załączonych.
        </p>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class ResponsibilityDeclarationConsentComponent extends AbstractConsentComponent<BondVersionConsentsDto> {}
