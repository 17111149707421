<details-view [title]="'company.details.title' | translate" [objectName]="'company.details.objectName' | translate"
              [versionListHidden]="true" [fixedBlockHidden]="false" [parentComponent]="self" modelObjectName="company">
  <div class="details-content">
    <div class="bon-header" style="padding-top:20px;">{{'company.details.details' | translate}}</div>
    <form role="form" #ngForm="ngForm">
      <div class="bon-card-group">
        <div class="bon-card-inner bon-label-190">
            <checkbox-row labelKey="company.details.physicalPerson" [(ngModel)]="company.physicalPersonIndicator"
                          [ngModelOptions]="{standalone: true}" (changeState)="onChoosePhysicalPerson($event)"
                          *ngIf="credendo" [presentationMode]="readOnly"></checkbox-row>
          <dict-row *ngIf="company.address" labelKey="company.details.country" [(ngModel)]="company.address.country"
                    dictionary="Country" name="country"
                    (changeItem)="onSelectedCountry($event)" [showErrors]="showErrors"
                    [presentationMode]="readOnly" [disabled]="isFarmer && company.address.country"></dict-row>
          <dict-row labelKey="company.details.type" [(ngModel)]="company.companyType" dictionary="CompanyType" (changeItem)="onSelectedType($event)"
                    name="companyType" [disabled]="!canEdit() || isKukeSpecialCompany()" [presentationMode]="readOnly"></dict-row>

          <div *ngIf="kuke || !company.physicalPersonIndicator">
            <input-row labelKey="company.details.regName" [(ngModel)]="company.registrationName" [presentationMode]="readOnly"
                       name="registrationName" placeholderKey="company.details.registrationNamePlaceHolder" required [showErrors]="showErrors"></input-row>
            <input-row labelKey="company.details.shortName" [(ngModel)]="company.shortName"
                       name="shortName" [presentationMode]="readOnly"></input-row>
            <input-row [labelKey]="kuke ? 'company.details.tradeName' : 'company.details.tradeName1'"
                       [presentationMode]="readOnly"
                       [(ngModel)]="company.tradeName" name="tradeName"></input-row>
            <input-row *ngIf="credendo" labelKey="company.details.tradeName2"
                       [(ngModel)]="company.tradeName2" name="tradeName2" [presentationMode]="readOnly"></input-row>
            <input-row *ngIf="credendo" labelKey="company.details.tradeName3"
                       [(ngModel)]="company.tradeName3" name="tradeName3" [presentationMode]="readOnly"></input-row>
          </div>

          <div *ngIf="company.physicalPerson && (company.physicalPersonIndicator || isLegalFormFarm)">
            <input-row labelKey="company.details.firstName"
                       [(ngModel)]="company.physicalPerson.firstName" name="firstName"
                       [presentationMode]="readOnly" [showErrors]="showErrors"></input-row>
            <input-row labelKey="company.details.lastName"
                       [(ngModel)]="company.physicalPerson.lastName" name="lastName"
                       [presentationMode]="readOnly" [showErrors]="showErrors"></input-row>
            <date-row *ngIf="!kuke" labelKey="company.details.dateOfBirth" [presentationMode]="readOnly"
                      [(ngModel)]="company.physicalPerson.dateOfBirth" [showErrors]="showErrors"
                      name="dateOfBirth" dateNotInFuture></date-row>
            <input-row *ngIf="kuke" labelKey="company.details.pesel" [(ngModel)]="company.physicalPerson.personalIdentity"
                       name="pesel" [maxlength]="11" [minlength]="11" [presentationMode]="readOnly || !isLegalFormFarm"
                       [showErrors]="showErrors"></input-row>
            <div *ngIf="kuke">
              <input-row labelKey="company.details.seriesIdNumber" [(ngModel)]="company.physicalPerson.seriesIdNumber"
                         name="seriesIdNumber" [presentationMode]="readOnly"
                         [showErrors]="showErrors"></input-row>
            </div>
          </div>

          <checkbox-row *ngIf="mehib" [(ngModel)]="company.sovereign" labelKey="company.details.sovereign"
                        name="sovereign" [presentationMode]="readOnly"></checkbox-row>
          <checkbox-row *ngIf="mehib" [(ngModel)]="company.subsovereign" labelKey="company.details.subsovereign"
                        name="subsovereign" [presentationMode]="readOnly"></checkbox-row>
          <checkbox-row *ngIf="kuke && !isFarmer" [(ngModel)]="company.publicEntity" labelKey="company.details.publicEntity"
                        name="publicEntity" [presentationMode]="readOnly"></checkbox-row>
          <checkbox-row *ngIf="kuke && !isFarmer" [(ngModel)]="company.listedCompany" labelKey="company.details.listedCompany"
                        name="listedCompany" [presentationMode]="readOnly"></checkbox-row>
          <div *ngIf="companyInKukeDetailsEditingMode">
            <checkbox-row [(ngModel)]="company.dataProfiling" labelKey="company.details.dataProfiling"
                          name="dataProfiling" [presentationMode]="readOnly"
                          ></checkbox-row>
            <checkbox-row [(ngModel)]="company.exclusionFromMailing" labelKey="company.details.exclusionFromMailing"
                          name="exclusionFromMailing" [presentationMode]="readOnly"
                          ></checkbox-row>
          </div>
          <checkbox-row *ngIf="kuke" labelKey="company.details.fsObliged" [presentationMode]="readOnly"
                        [ngModel]="(isFsObligedLegalForm() | async) || company.financialStandingObliged"
                        (changeState)="company.financialStandingObliged = $event"
                        name="financialStandingObliged" [disabled]="isFsObligedLegalForm() | async"></checkbox-row>
          <checkbox-row *ngIf="companyDetailsEditingMode" [(ngModel)]="company.vip"
                        labelKey="company.details.vip" name="vip" [presentationMode]="readOnly"
                        ></checkbox-row>
          <checkbox-row *ngIf="companyDetailsEditingMode && !kuke" [(ngModel)]="company.groupRelevant"
                        labelKey="company.details.groupRelevant" name="groupRelevant" [presentationMode]="readOnly"
                        ></checkbox-row>
          <checkbox-row *ngIf="showCompanyHideInPortal" [(ngModel)]="company.hidden"
                        labelKey="company.details.isHidden" name="isHidden" [presentationMode]="readOnly"
                        ></checkbox-row>
          <checkbox-row *ngIf="credendo" labelKey="company.search.businessPartner" [(ngModel)]="company.businessPartner"
                       [disabled]="true" name="businessPartner"></checkbox-row>
        </div>

        <div class="bon-card-inner bon-label-190">
          <input-row [label]="taxNumberLabel" [presentationMode]="readOnly"
                     [(ngModel)]="company.vatNumber" name="vatNumber"
                     [showErrors]="showErrors" (paste)="trimTaxNumberSpaces($event)" [keyDownFunc]="preventSpaces">
          </input-row>
          <input-row *ngIf="mehib" labelKey="company.common.groupTaxId" [presentationMode]="readOnly"
                     [(ngModel)]="company.groupTaxId" name="groupTaxId"></input-row>
          <input-row *ngIf="!isLegalFormFarm && company.companyType?.id !== CompanyType.BRANCH"
                     [label]="nationalIdLabel" [presentationMode]="readOnly" [(ngModel)]="company.nationalId"
                     name="nationalId"
                     [showErrors]="showErrors" (paste)="trimNationalIdSpaces($event)" [keyDownFunc]="preventSpaces">
          </input-row>
          <text-row *ngIf="company?.companyType?.id === CompanyType.BRANCH" [presentationMode]="true" [label]="nationalIdLabel"
                    [value]="company?.parentOfBranch?.nationalId" nullValue="from parent company"></text-row>
          <input-row *ngIf="!mehib" [presentationMode]="readOnly" [label]="registryNumberLabel"
                     [(ngModel)]="company.statNumber" name="statNumber"
                     [showErrors]="showErrors" [disabled]="registryNumberDisabled">
          </input-row>
          <form-row *ngIf="ecg" labelKey="company.details.salesforceId">
            <standalone-company-identifier class="bon-input-size" [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.SALESFORCE"
            ></standalone-company-identifier>
          </form-row>
          <form-row *ngIf="credendo && showFieldForCountry(Country.DE)" labelKey="company.details.schufaId">
            <standalone-company-identifier class="bon-input-size" [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.SCHUFA_ID"
            ></standalone-company-identifier>
          </form-row>
          <form-row *ngIf="credendo" labelKey="company.details.credendoId">
            <standalone-company-identifier class="bon-input-size" [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.MDG_NUMBER"
                                           ></standalone-company-identifier>
          </form-row>
          <form-row *ngIf="credendo" labelKey="company.details.gildaId">
            <standalone-company-identifier class="bon-input-size" [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.GILDA_ID"
            ></standalone-company-identifier>
          </form-row>

          <input-row *ngIf="credendo || mehib" labelKey="company.details.sapNo" [presentationMode]="sapNoEditability()"
                     [(ngModel)]="company.partnerNumber" name="partnerNumber"></input-row>
          <form-row *ngIf="credendo" labelKey="company.details.gspId">
            <standalone-company-identifier [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.GSP_ID"
                                           ></standalone-company-identifier>
          </form-row>
          <form-row *ngIf="mehib" labelKey="company.details.gspId">
            <standalone-company-identifier [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.LEGACY_ID"
                                           ></standalone-company-identifier>
          </form-row>
          <form-row labelKey="company.details.bic" *ngIf="isBank()">
            <standalone-company-identifier [items]="company.identifiers"
                                           [readOnlyMode]="readOnly" [identifierTypeId]="CompanyIdentifierType.BIC"
                                           ></standalone-company-identifier>
          </form-row>
          <dict-row *ngIf="!mehib" labelKey="company.common.industry" [(ngModel)]="company.industry" dictionary="Industry"
                    [disabled]="kuke" name="industry" [presentationMode]="readOnly"></dict-row>
          <dict-row *ngIf="(kuke || ecg)" labelKey="company.common.tradeIndustry" [(ngModel)]="company.tradeIndustry" dictionary="TradeIndustry"
                    [disabled]="!ecg" name="tradeIndustry" [presentationMode]="readOnly"></dict-row>
          <dict-row labelKey="company.details.mainNaceCode" [ngModel]="mainNaceCode" dictionary="NaceCode" disabled
                    name="mainNaceCode" *ngIf="companyInKukeDetailsEditingMode" [presentationMode]="readOnly"></dict-row>
          <dict-row labelKey="company.details.legalForm" [(ngModel)]="company.legalForm" [entries]="legalForms" [presentationMode]="readOnly"
                    name="legalForm" (ngModelChange)="onLegalFormChange()"></dict-row>
          <dict-row *ngIf="credendo" labelKey="company.details.companyStatus" [(ngModel)]="company.status" [presentationMode]="readOnly" disabled
                    name="companyStatus" dictionary="CompanyStatus"></dict-row>
          <dict-row *ngIf="credendo" labelKey="company.details.businessPartnerType" [(ngModel)]="company.businessPartnerType" [presentationMode]="readOnly"
                    name="businessPartnerType" dictionary="BusinessPartnerType"></dict-row>
          <input-row *ngIf="kuke" labelKey="company.details.regionalCourt" [(ngModel)]="company.regionalCourt" [presentationMode]="readOnly"
                     name="regionalCourt" ></input-row>
          <dict-row *ngIf="!credendo" labelKey="company.common.sector" [(ngModel)]="company.sector" dictionary="Sector" name="sector"
                    [disabled]="kuke" [presentationMode]="readOnly"></dict-row>
          <dict-row *ngIf="!mehib" labelKey="company.details.legalStatus" [(ngModel)]="company.legalStatus" dictionary="LegalStatus"
                    name="legalStatus" [presentationMode]="readOnly || companyInKukeRegistrationMode"></dict-row>
          <search-row labelKey="company.details.parentCompany" [(ngModel)]="company.parentOfBranch"
                      displayedProperty="registrationName" [required]="company.companyType?.id === CompanyType.BRANCH"
                      [presentationMode]="readOnly || this.company.groupHead"
                      [openSelectorEmitter]="openSelectorEmitters['parentCompany']" [addressInTitle]="true"
                      name="parentOfBranch" #parentOfBranchModel="ngModel" [control]="parentOfBranchModel.control" [showErrors]="showErrors">
          </search-row>
          <search-row *ngIf="company.companyGroupVersions && (companyInKukeDetailsEditingMode || credendo || mehib)"
                      labelKey="company.details.group"
                      [(ngModel)]="company.companyGroupVersions[company.companyGroupVersions.length - 1]"
                      [ngModelOptions]="{standalone: true}"  [presentationMode]="readOnly"
                      [disabled]="true"
                      [showLink]="!readOnly"
                      [displayedTextFunction]="getGroupName"
                      (valueClicked)="routerService.toCompanyGroupPreview(company.companyGroupVersions[company.companyGroupVersions.length - 1].companyGroup.id)">
          </search-row>
          <ng-container *ngIf="companyInKukeDetailsEditingMode">
            <dict-row labelKey="company.details.legalEvent" [(ngModel)]="company.legalEvent.type"
                      dictionary="LegalEventType" name="legalEventType"
                      #legalEventTypeModel="ngModel"
                      nullLabel=" " [presentationMode]="readOnly"
                      (changeItem)="legalEventChange($event)"></dict-row>
            <div class="bon-row" *ngIf="company.legalEvent.type">
              <label class="bon-label"></label>
              <date-picker class="bon-input-size" id="legalEventDate" [(ngModel)]="company.legalEvent.date"
                           name="legalEventDate"  [presentationMode]="readOnly"
                           #legalEventDateModel="ngModel" [required]="legalEventTypeModel.value"></date-picker>
              <error-message [control]="legalEventDateModel.control" [show]="showErrors"></error-message>
            </div>
          </ng-container>
        </div>

        <div class="bon-card-inner bon-label-190">
          <date-row labelKey="company.details.startOfBusiness" [(ngModel)]="company.startOfBusiness"
                    name="startOfBusiness" [presentationMode]="readOnly"></date-row>
          <date-row labelKey="company.details.endOfBusiness" [(ngModel)]="company.endOfBusiness" [presentationMode]="readOnly"
                    name="endOfBusiness" *ngIf="companyInKukeDetailsEditingMode"></date-row>
          <div class="bon-row">
            <label class="bon-label">{{'company.details.endFiscalYear' | translate}}</label>
            <month-selector class="bon-input-size" [(ngModel)]="company.endFiscalYear"
                            returnval="month" [presentationMode]="readOnly"
                            name="endFiscalYear"></month-selector>
          </div>
          <div *ngIf="companyInKukeDetailsEditingMode">
            <input-row labelKey="company.common.id" [ngModel]="company.id" [presentationMode]="readOnly"
                       name="idKuke" [disabled]="true"></input-row>
            <checkbox-row labelKey="company.details.unidentifiedCompany" [(ngModel)]="company.unidentifiedCompany"
                          name="unidentifiedCompany"  [presentationMode]="readOnly"></checkbox-row>
          </div>
          <form-row *ngFor="let biIdentifier of biIdentifiers" [label]="biIdentifier.label">
            <standalone-company-identifier [items]="company.identifiers"
                                           [readOnlyMode]="readOnly"
                                           [identifierTypeId]="biIdentifier.type?.id"
                                           [biSourceId]="biIdentifier.biSource?.id"></standalone-company-identifier>
          </form-row>
          <input-row *ngIf="kuke" labelKey="company.details.partnerNumber" [ngModel]="company.partnerNumber"
                     name="partnerNumber" [disabled]="true" [presentationMode]="readOnly"></input-row>
          <dict-row *ngIf="!kuke" labelKey="company.details.language" [(ngModel)]="company.language" dictionary="Language"
                    name="language"  [presentationMode]="readOnly"></dict-row>
          <input-row *ngIf="credendo" labelKey="company.common.employeesNumber" [presentationMode]="readOnly" type="number"
                     [(ngModel)]="company.employeesNumber" name="employeesNumber" [minValue]="0"  [maxValue]="999999999" [showErrors]="showErrors" ></input-row>
          <dict-row *ngIf="mehib" labelKey="company.common.companySize" [(ngModel)]="company.companySize" dictionary="CompanySize"
                    name="companySize"  [presentationMode]="readOnly"></dict-row>
          <dict-row *ngIf="mehib" labelKey="company.common.ownershipType1" [(ngModel)]="company.ownershipType1" dictionary="OwnershipType1"
                    name="ownershipType1"  [presentationMode]="readOnly"></dict-row>
          <dict-row *ngIf="mehib" labelKey="company.common.ownershipType2" [(ngModel)]="company.ownershipType2" dictionary="OwnershipType2"
                    name="ownershipType2"  [presentationMode]="readOnly"></dict-row>
          <dict-row *ngIf="credendo" labelKey="company.details.dataOwner" [(ngModel)]="company.dataOwner" dictionary="DataOwner"
                    nullLabel=" " name="dataOwner" [presentationMode]="readOnly" [disabled]="!hasLoggedUserSuperUserRole()"></dict-row>
          <checkbox-row *ngIf="credendo" labelKey="company.details.excludeFromSendingMDG" [(ngModel)]="company.excludeFromSendingMDG"
                        [presentationMode]="readOnly" [disabled]="!hasLoggedUserSuperUserRole()" name="excludeFromSendingMDG"></checkbox-row>
          <checkbox-row *ngIf="credendo" labelKey="company.details.archivedInMDG" [(ngModel)]="company.archivedInMDG"
                        [presentationMode]="readOnly" [disabled]="!hasLoggedUserSuperUserRole()" name="archivedInMDG"></checkbox-row>
          <textarea-row *ngIf="!mehib" labelKey="company.details.holder" [(ngModel)]="company.holder" [presentationMode]="readOnly"
                        [ngModelOptions]="{standalone: true}" rows="3" name="holder"></textarea-row>
        </div>
      </div>

      <div class="bon-card-group">
        <div class="bon-card-inner">
          <company-selector-simple *ngIf="credendo || mehib"
                                   (selectItem)="onSelectParentCompany($event)"
                                   [open]="openSelectorEmitters['parentCompany']"
                                   labelKey="company.details.searchForParentCompany"
                                   [textCriteria]="parentCompanySearchCriteria">
          </company-selector-simple>
          <company-selector-extended *ngIf="kuke || ecg"
                                     (selectItem)="onSelectParentCompanyEvent($event)"
                                     [open]="openSelectorEmitters['parentCompany']"
                                     [fixedFiltersVisibility]="true"
                                     [companyRawFormEnabled]="loggedUserService.portal"
                                     [externalSearch]="false"
                                     [companyCreationPossible]="false"
                                     companySearchInternalIdKey="company.common.id"
                                     titleKeys="thirdParty.details">
          </company-selector-extended>
        </div>
      </div>

      <div class="bon-card-main-group" [hidden]="!showDuplicateSection" style="padding-top: 0;">
        <company-section labelKey="company.details.duplicateOf" [(ngModel)]="company.duplicateOf"
                         [ngModelOptions]="{standalone: true}"
                         [openSelectorEmitter]="openSelectorEmitters['duplicateCompany']"
                         (clearObjectEmitter)="handleButtons()" [showLink]="true" [readOnly]="readOnlyMode">
        </company-section>
      </div>

      <div class="bon-card-group">
        <div class="bon-card-inner">
          <company-selector-simple *ngIf="credendo || mehib || ecg"
                                   (selectItem)="company.duplicateOf = $event"
                                   [open]="openSelectorEmitters['duplicateCompany']"
                                   title="{{'company.details.searchForDuplicateOrigin' | translate}}">
          </company-selector-simple>
          <company-selector-extended *ngIf="kuke && canEdit()"
                                     (selectItem)="company.duplicateOf = $event.company"
                                     [open]="openSelectorEmitters['duplicateCompany']"
                                     [fixedFiltersVisibility]="true"
                                     [companyRawFormEnabled]="loggedUserService.portal"
                                     [companyCreationPossible]="false"
                                     [externalSearch]="false"
                                     companySearchInternalIdKey="company.common.id"
                                     title="{{'company.details.searchForDuplicateOrigin' | translate}}"
                                     titleKeys="thirdParty.details">
          </company-selector-extended>
        </div>
      </div>

      <div class="bon-header">{{'company.common.registrationAddress' | translate }}</div>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <address-table #registrationAddr [form]="form" controlName="regAddress" [oneTypeMode]="true"
                         [addresses]="[company.address]" [showErrors]="showErrors" [zipCodeRequired]="true"
                         [editable]="!readOnly" (countryChange)="onSelectedCountry($event)" [isCountryEditable]="!(kuke && isFarmer)"
                         [poBoxHidden]="kuke || mehib" [inProgress]="inProgress"></address-table>
        </div>
      </div>

      <div class="bon-header">{{'company.details.addresses' | translate}}</div>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <address-table [form]="form" controlName="addresses" [hiddenAddressTypes]="hiddenAddressTypeIds"
                         [addresses]="company.addresses" [showErrors]="showErrors" [zipCodeRequired]="true"
                         [editable]="!readOnly"  [isCountryEditable]="!(kuke && isFarmer)" (addAddress)="onAddAddress($event)" [poBoxHidden]="kuke || mehib"></address-table>
        </div>
      </div>

      <ng-container *ngIf="kuke">
        <div class="bon-header">{{'company.details.localBranches' | translate}}</div>
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <a-table [items]="company.localBranches" [editable]="!readOnly"
                     [formModel]="form" groupName="localBranchesTable" [showAllErrors]="showErrors" (addItem)="onAddLocalBranch($event)">
              <column labelKey="common.name" property="name" [required]="!kuke"></column>
              <column labelKey="common.address.address" maxlength="160" property="address.street" required cellWidth="19%"></column>
              <column labelKey="common.address.streetNumber" maxlength="40" property="address.streetNumber" cellWidth="8%"></column>
              <column labelKey="common.address.postCode" maxlength="20" property="address.zipCode" cellWidth="8%" [required]="!kuke"></column>
              <column labelKey="common.address.town" property="address.town" maxlength="60" cellWidth="19%" [required]="kuke"></column>
              <column labelKey="common.country" property="address.country" dictionary="Country" nullLabel="" [required]="!(kuke && isFarmer)" [editable]="!(kuke && isFarmer)"
                      cellWidth="19%"></column>
              <column labelKey="common.phone" property="phone" cellWidth="8%"></column>
            </a-table>
          </div>
        </div>
      </ng-container>

      <div class="bon-header">{{'company.details.contacts' | translate}}</div>
      <div class="bon-card-group">
        <div class="bon-card-inner">

          <a-table [items]="company.contacts" [editable]="!readOnly"
                   [formModel]="form" groupName="contactTable" [showAllErrors]="showErrors">
            <column labelKey="common.type" property="type" nullLabel="" dictionary="ContactType" cellWidth="4%" groupRadios="true"
                    required></column>
            <column labelKey="company.details.valueForType" property="value" cellWidth="41%" required></column>
            <column labelKey="common.contactFor" property="contactFor" cellWidth="41%"></column>
            <column labelKey="common.main" property="main" type="radio" cellWidth="4%"></column>
          </a-table>

        </div>
      </div>

      <div class="bon-header">{{'company.details.contactPersons' | translate}}</div>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <contact-person-list [contactPersons]="company.contactPersons" [showErrors]="showErrors" [parentForm]="form"
                               [businessObjectTypeId]="BusinessObjectType.COMPANY" [editable]="!readOnly"
                               [emailRequired]="kuke" [lastNameRequired]="!kuke" [hideMainContact]="true" [showSalesforceId]="ecg"
          ></contact-person-list>
        </div>
      </div>

      <bon-section labelKey="company.details.naceCodes">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <a-table [items]="company.naceCodes" [formModel]="form" [editable]="!readOnly"
                     groupName="naceTable" [showAllErrors]="showErrors">
              <column property="naceCode.code" labelKey="common.code" cellWidth="8%" [editable]="false" required unique
                      initialSort="up"></column>
              <column property="naceCode" labelKey="common.name" cellWidth="82%" type="autocomplete"
                      dictionary="NaceCode"></column>
              <column property="main" labelKey="common.main" type="radio" cellWidth="4%"></column>
            </a-table>
          </div>
        </div>
      </bon-section>

      <bon-section *ngIf="!(mehib || kuke)" labelKey="company.details.bankAccounts">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <a-table #bankAccountsList [items]="company.bankAccounts" [editable]="!readOnly" autoUpdateValidators="reset"
                     [formModel]="form" groupName="bankAccounts" [showAllErrors]="showErrors">
              <column labelKey="common.type" property="type" required cellWidth="11%" dictionary="BankAccountType"
                      unique></column>
              <column labelKey="common.iban" property="iban" required cellWidth="16%" updateOn="blur"
                      [customAsyncValidator]="companyService.validateIban.bind(companyService)"></column>
              <column labelKey="common.bic" property="bic" cellWidth="16%"></column>
              <column labelKey="common.bankName" property="bankName" [editable]="false" cellWidth="20%"></column>
              <column labelKey="common.description" property="description" cellWidth="30%"></column>
            </a-table>
          </div>
        </div>
      </bon-section>

      <div *ngIf="company.legalForm && credendo">
        <div class="bon-header">{{'company.details.shareholders' | translate}}</div>
        <div class="bon-card-group">
          <company-selector-simple (selectItem)="addShareholder($event)"
                                   labelKey="company.details.searchForShareholderToAdd"
                                   [open]="openSelectorEmitters['Shareholder']" [showDuplicates]="false"
                                   [searchModeSwitchable]="true"></company-selector-simple>
        </div>
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <a-table #shareholderList [items]="company.shareholders" [editable]="!readOnly" [pagination]="false"
                     [sortable]="true"
                     [formModel]="form"
                     [addButton]="!readOnly"
                     [addButtonEventEmitter]="openSelectorEmitters['Shareholder']"
                     [showAllErrors]="showErrors">
              <column labelKey="common.registrationName" property="shareholder.registrationName" [link]="true"
                      (linkClick)="routerService.toCompanyPreview($event.company.id)"
                      [editable]="false" cellWidth="20%"></column>
              <column labelKey="common.address.address" title="Address" [editable]="false" cellWidth="40%">
                <ng-template let-item="item">{{item.shareholder?.address.street}}
                  , {{item.shareholder?.address.streetNumber}},
                  {{item.shareholder?.address.town}}, {{item.shareholder?.address.country?.name}}</ng-template>
              </column>
              <column labelKey="common.nationalId" property="shareholder.nationalId" [editable]="false"
                      cellWidth="20%"></column>
              <column *ngIf="credendo && isLegalFormJointVenture" labelKey="common.leader" property="leader" type="radio" cellWidth="5%"></column>
              <column labelKey="common.valuePercentage" property="value" required type="number"
                      [numberPrecision]="2"></column>
            </a-table>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="information-header-center" *ngIf="company">
    <span *ngIf="company.duplicateOf" class="invalid font-larger">{{'company.details.duplicate' | translate}}</span>
  </div>

  <div class="information-header" *ngIf="company">
    <b class="font-larger a-link" (click)="routerService.toCompanyPreview(company.id)"
       style="display: inline-block; width: 250px;"> {{company.registrationName}}</b>
    <b style="display: inline-block; width: 100px;" *ngIf="company.id"> {{'company.common.id' | translate}}: {{company.id}}</b>
  </div>
  <div class="additional-buttons">
    <div *ngIf="company && company.id && !readOnly" class="btn-group" dropdown>
      <button class="bon-btn-info dropdown-toggle" type="button" dropdownToggle
              [disabled]="companyRegistrationMode || !canEdit()">
        {{'company.details.updateCompanyType' | translate}}
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let type of filteredTypes"><a class="dropdown-item"
                                                                  (click)="onUpdateCompanyType(type)">{{type.name}}</a>
        </li>
      </ul>
    </div>
    <ng-container *hasRight="CompanyElementaryRight.COMPANY_MANAGERS_ACCESS">
      <button class="bon-btn-warning" *ngIf="companyInKukeDetailsEditingMode" type="button"
              (click)="routerService.toCompanyManagers(company.id)">
        {{'company.details.companyManagers' | translate}}
      </button>
    </ng-container>
    <ng-container *hasRight="CompanyElementaryRight.COMPANY_LEGALLY_DECLARED_INSOLVENCY_ACCESS">
      <button class="bon-btn-warning" *ngIf="companyInKukeDetailsEditingMode || companyInEcgDetailsEditingMode" type="button" btnIcon="fa-unlink"
              (click)="toLegalInsolvency()" translate> company.insolvency.title </button>
    </ng-container>
    <ng-container *hasRight="ServiceContactElementaryRight.SERVICE_CONTACT_READ_ALL + ',' + ServiceContactElementaryRight.SERVICE_CONTACT_READ_COMPANIES">
      <button class="bon-btn-warning" *ngIf="companyInKukeDetailsEditingMode" type="button"
              (click)="toServiceContacts()">
        {{'common.serviceContacts' | translate}}
      </button>
    </ng-container>
    <button class="bon-btn-info" *ngIf="company?.id && !kuke" (click)="routerService.toTaskSearchWithBo(BusinessObjectType.COMPANY, this.company.id)" translate>common.tasks</button>
    <button class="bon-btn-warning" *ngIf="mehib && company?.id" (click)="routerService.toCompanyExternalProviders(company.id)" translate> externalProvider.button</button>
  </div>
  <confirm-dialog #updateCompanyTypeConfirm></confirm-dialog>
</details-view>
