import {Component} from '@angular/core';
import {AbstractConsentComponent} from '../abstract-consent.component';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {ControlContainer, NgModelGroup} from '@angular/forms';

@Component({
  selector: 'general-contract-declarations-consent',
  template: `
    <div class="row flex">
      <!-- 11 (~20 GENERALNEJ/INDYWIDUALNEJ) -->
      <checkbox-entity
        class="start"
        name="generalContractDeclarations"
        [(ngModel)]="consents.generalContractDeclarations"
        [readonly]="readonly"
        [requiredTrue]="validate && true"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>
          Informujemy, iż w przypadku zawarcia UMOWY GENERALNEJ O UDZIELANIE GWARANCJI EKSPORTOWYCH, KUKE S.A. jest
          zobowiązana do uzyskania od Eksportera następujących oświadczeń dotyczących kontraktów eksportowych, do
          których będą udzielane gwarancje:
        </p>
        <ul>
          <li>oświadczenia w sprawie przekupstwa,</li>
          <li>
            w zakresie i na zasadach wskazanych w ustawie z dnia 7 lipca 1994 r. o gwarantowanych przez Skarb Państwa
            ubezpieczeniach eksportowych, oświadczenia w sprawie krajowego pochodzenia produktu lub usługi
            eksportowanych w ramach kontraktów eksportowych,
          </li>
          <li>
            oświadczenie, że kwota wnioskowanych w ramach umowy generalnej gwarancji nie przekroczy 80% zobowiązania
            eksportera / kwoty zobowiązania banku lub zakładu ubezpieczeń.
          </li>
        </ul>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class GeneralContractDeclarationsConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
