import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {BondVersionSearchComponent} from './bond-version-search.component';
import {BondDetailsComponent} from './bond-details.component';
import {BondGuiService} from './services/bond-gui.service';
import {DocumentModule} from '../document/document.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ClaimSharedModule} from 'src/client/app/bonding/claim/components/shared/claim-shared.module';
import {BondLinkedClaimsComponent} from './bond-linked-claims.component';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {BondSubtypeSelectorComponent} from './components/bond-subtype-selector.component';
import {RepositoryDocumentListModule} from '../../bonding_shared/components/repository-document-list/repository-document-list.module';
import {BondSharedModule} from './components/shared/bond-shared.module';
import {BondFormComponent} from './components/bond-form.component';
import {KukeBondFormComponent} from './components/kuke-bond-form.component';
import {DifferentPriceComponent} from './components/shared/different-price.component';
import {BondLetterOfCreditValidatorDirective} from './validators/bond-letter-of-credit-validator.directive';
import {BondValueListComponent} from './components/bond-value-list.component';
import {BondingContractCommonModule} from '../bonding-contract/common/bonding-contract-common.module';
import {BondPaymentListComponent} from './components/bond-payment-list.component';
import {BondDetailsGuard, BondSearchGuard} from './_guards';
import {BondingConsentsModule} from '../bonding-consents/bonding-consents.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    ContractSharedModule,
    RepositoryDocumentListModule,
    BondingConsentsModule,
    BondSharedModule,
    BondingContractCommonModule,
    ClaimSharedModule,
  ],
  declarations: [
    BondVersionSearchComponent,
    BondDetailsComponent,
    BondSubtypeSelectorComponent,
    BondFormComponent,
    KukeBondFormComponent,
    BondLinkedClaimsComponent,
    DifferentPriceComponent,
    BondPaymentListComponent,
    BondValueListComponent,
    BondLetterOfCreditValidatorDirective,
  ],
  providers: [BondGuiService, BondDetailsGuard, BondSearchGuard],
})
export class BondModule {}
