import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {BondDto, BondVersionCriteriaDto, BusinessUnitIdDto, ErrorReason} from '../../bonding_shared/model/dtos';
import {BondGuiService} from './services/bond-gui.service';
import {ComboItem} from '../../bonding_shared/components/combos/string-combo.component';
import {
  AppConfigService,
  BondVersionService,
  FeatureService,
  GrowlService,
  LoggedUserService,
} from '../../bonding_shared/services';
import {BondVersionSimpleDto} from '../../bonding_shared/model';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {BondingElementaryRight, Feature} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {UserResponsibleService} from '../../bonding_shared/services/user-responsible.service';

@Component({
  selector: 'bond-version-search',
  templateUrl: './bond-version-search.component.pug',
})
export class BondVersionSearchComponent extends SearchView implements OnInit {
  readonly BondingElementaryRight = BondingElementaryRight;

  dataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  versionPhases: ComboItem[] = [
    new ComboItem('ACTIVE', this.translateService.instant('bond.search.criteria.current')),
    new ComboItem('LAST', this.translateService.instant('bond.search.criteria.last')),
  ];
  public showBondSubType: boolean; // Kuke does not use bond subtype

  @ViewChild(SearchViewComponent) searchView: SearchViewComponent<BondVersionCriteriaDto, BondDto>;

  readonly Feature = Feature;

  constructor(
    public router: RouterService,
    private service: BondGuiService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private bondVersionService: BondVersionService,
    private featureService: FeatureService,
    private growlService: GrowlService,
    private userResponsibleService: UserResponsibleService,
    protected translateService: TranslateService
  ) {
    super();
    this.showBondSubType = featureService.get(Feature.BOND_SHOW_SUBTYPE);
    this.dataProvider = service.searchDataProvider;
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.service.initializeCriteria();
      this.dataProvider = this.service.searchDataProvider;
    }
  }

  buChanged(bu: BusinessUnitIdDto) {
    this.dataProvider.searchCriteria.criteria.type = undefined;
    this.dataProvider.searchCriteria.criteria.subtype = undefined;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  export() {
    this.dataProvider.inProgress = true;
    this.bondVersionService.export(
      this.dataProvider.searchCriteria.criteria,
      'bonds.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => {
        this.searchView.serverErrors = null;
        this.dataProvider.inProgress = false;
        this.exportCompleteCallback();
      }
    );
  }

  exportAsync() {
    this.searchView.serverErrors = undefined;
    this.bondVersionService.executeExportAsync(this.dataProvider.searchCriteria.criteria).subscribe(
      () => this.growlService.notice('common.reportGeneration'),
      (error) => (this.searchView.serverErrors = error)
    );
  }

  private downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Bond export error', error);
    this.searchView.serverErrors = error;
    this.dataProvider.inProgress = false;
  }

  private exportCompleteCallback(): void {
    console.log('exportCompleteCallback');
  }
}
