import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  BackendError,
  BusinessPropertiesDto,
  BusinessUnitDto,
  CompanyCriteriaDto,
  DictionaryBaseDto,
  LimitRequestDto,
  MasterPolicyContractBaseDto,
  PolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  PolicyInquiryVersionCriteriaDto,
  ProductLimitListForLimitDto,
} from '../../../../bonding_shared/model';
import {
  AppConfigService,
  BusinessPropertiesService,
  DictionaryService,
  LoggedUserService,
  RouterService,
  SettableObjectType,
  View,
} from '../../../../bonding_shared/services';
import {
  Country,
  LegalForm,
  LimitCategory,
  LimitRequestType,
  PolicyInquiryStatus,
} from '../../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {SectionSelectorsComponent} from '../../../../bonding_shared/components/details-view/section-selectors.component';
import {ConfirmDialogComponent} from '../../../../bonding_shared/components/confirm-dialog';
import {BusinessUnit} from '../../../../bonding_shared/model/business-unit';
import POLICY_INQUIRY_ACCEPTED = PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED;
import POLICY_INQUIRY_REGISTERED = PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED;

@Component({
  selector: 'limit-selectors',
  templateUrl: 'limit-selectors.component.pug',
})
export class LimitSelectorsComponent {
  @ViewChild('selectors', {static: true}) selectors: SectionSelectorsComponent;
  @ViewChild('farmerLimitClauseConfirm', {static: true}) farmerLimitClauseConfirmDialog: ConfirmDialogComponent;

  @Input() readOnly = false;
  @Input() fixedMasterPolicyId: number;

  @Output() allSelectorsSet = new EventEmitter<LimitRequestDto>();
  @Output() backendError = new EventEmitter<BackendError>();
  @Output() frontendError = new EventEmitter<string>();
  @Output() clearErrors = new EventEmitter();

  limitRequest: LimitRequestDto;
  self = this;
  clientId: number;
  businessProperties: BusinessPropertiesDto;

  readonly LegalForm = LegalForm;
  readonly Country = Country;
  readonly LimitSelector = LimitSelector;
  readonly View = View;

  buyerCriteria = <CompanyCriteriaDto>{address: {}};
  factorerCriteria = <CompanyCriteriaDto>{address: {country: {id: Country.PL}}};
  policySearchCriteria = <PolicyContractVersionCriteriaDto>{
    policyContract: <PolicyContractBaseDto>{masterPolicyContract: <MasterPolicyContractBaseDto>{}},
    activated: true,
    presentNow: true,
    versionPhase: 'ACTIVE',
  };
  inquirySearchCriteria = <PolicyInquiryVersionCriteriaDto>{
    last: true,
    statuses: [POLICY_INQUIRY_ACCEPTED, POLICY_INQUIRY_REGISTERED],
    activated: true,
    presentNow: true,
  };

  @Input() set request(req: LimitRequestDto) {
    // avoid reloading the same objects
    if (this.limitRequest && this.limitRequest.limit.id && this.limitRequest.limit.id === req.limit.id) {
      return;
    }
    this.limitRequest = req;
    this.updateCriteria();
  }

  constructor(
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private dictionaryService: DictionaryService,
    private businessPropertiesService: BusinessPropertiesService
  ) {
    this.businessPropertiesService.getProperties().subscribe((p) => {
      this.businessProperties = p;
      this.inquirySearchCriteria.productTypeIds = p.policyInquiryProductsForLimit;
      this.updateCriteria();
    });
  }

  updateCriteria() {
    if (!this.limitRequest || !this.businessProperties) {
      return;
    }
    this.clientId = this.portal
      ? this.callerCompany.id
      : this.limitRequest.limit.insured
      ? this.limitRequest.limit.insured.id
      : undefined;

    this.policySearchCriteria.contractTypeIds = this.allowedPolicyProductIds;
    this.policySearchCriteria.linkedCompanyId = this.clientId;
    this.policySearchCriteria.clauseTypeIds = this.clauseTypeIds;
    this.inquirySearchCriteria.client = <CompanyCriteriaDto>{id: this.clientId};
    const address = this.fixedCountry ? {country: {id: Country.PL}} : {};
    this.buyerCriteria = <CompanyCriteriaDto>{address: address};
    this.factorerCriteria = <CompanyCriteriaDto>{address: {country: {id: Country.PL}}};
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  policyListChanged(pll: ProductLimitListForLimitDto) {
    this.limitRequest.limit.insured = pll.masterPolicyContract
      ? pll.masterPolicyContract.client
      : pll.policyInquiry.client;
    // this.verifyLimitTypeForPolicyList();
    this.updateCriteria();
    this.selectors.refreshSelectors();
  }

  insuredChanged() {
    this.limitRequest.limit.policyLimitList = undefined;
    this.updateCriteria();
  }

  get allSet(): boolean {
    return this.selectors.allSet() && this.limitRequest.type && !!this.limitRequest.type.id;
  }

  isRequestTypeAllowed(typeId: number) {
    return this.masterPolicy
      ? this.masterPolicy.allowedLimitRequestTypes.find((t) => t.id === typeId)
      : typeId === LimitRequestType.STANDARD ||
          (typeId === LimitRequestType.FALCON && this.policyInquiry.activatedOrLastVersion.falconLimitAllowed);
  }

  // TODO: probably it will be removed as validation is done on backend, temporarily it stays here until we are sure Kuke is ok with it
  // TODO: remember to remove messages too
  // verifyLimitTypeForPolicyList() {
  //   this.clearErrors.emit();
  //   if (this.limitRequest.type.id === LimitRequestType.FARMER) {
  //     const allowedTypeIds = [LimitRequestType.FARMER].filter((id) => this.isRequestTypeAllowed(id));
  //     switch (allowedTypeIds.length) {
  //       case 0:
  //         this.frontendError.emit('limitDetails.errorMessage.farmerNotAllowedForPolicy');
  //         return;
  //       case 1:
  //         this.limitRequest.type = <DictionaryBaseDto>{id: allowedTypeIds[0]};
  //         break;
  //       default:
  //         console.log('Too many types');
  //     }
  //   } else if (this.limitRequest.type.id === LimitRequestType.FALCON) {
  //     if (!this.isRequestTypeAllowed(LimitRequestType.FALCON)) {
  //       this.frontendError.emit('limitDetails.errorMessage.falconNotAllowedForPolicy');
  //       return;
  //     }
  //   }
  // }

  isFalcon() {
    return this.limitRequest && this.limitRequest.type && this.limitRequest.type.id === LimitRequestType.FALCON;
  }

  isFarmer() {
    return BusinessUtils.isFarmerLimit(this.limitRequest);
  }

  isBondingLimit() {
    return (
      this.limitRequest &&
      this.limitRequest.limit &&
      this.limitRequest.limit.category &&
      (this.limitRequest.limit.category.id === LimitCategory.BONDING_CONTRACT_INQUIRY ||
        this.limitRequest.limit.category.id === LimitCategory.BONDING_CONTRACT)
    );
  }

  isGSP() {
    return this.businessUnit && this.businessUnit.id === BusinessUnit.KUKE_GSP;
  }

  get businessUnit(): BusinessUnitDto {
    if (!this.limitRequest || !this.limitRequest.limit || !this.limitRequest.limit.policyLimitList) {
      return undefined;
    }
    return this.limitRequest.limit.policyLimitList.bondingContract
      ? this.limitRequest.limit.policyLimitList.bondingContract.businessUnit
      : this.limitRequest.limit.policyLimitList.bondingContractInquiry
      ? this.limitRequest.limit.policyLimitList.bondingContractInquiry.businessUnit
      : undefined;
  }

  createNewBuyer() {
    this.router.toCompanyDetailsNew(
      View.LIMIT_DETAILS.id,
      SettableObjectType.LIMIT_BUYER,
      undefined,
      this.isFarmer() ? LegalForm.FP010 : undefined
    );
  }

  createNewFactorer() {
    this.router.toCompanyDetailsNew(View.LIMIT_DETAILS.id, SettableObjectType.LIMIT_FACTORER);
  }

  get callerCompany() {
    return this.loggedUserService.getLoggedUserData().company;
  }

  get masterPolicy() {
    return this.policyList ? this.policyList.masterPolicyContract : undefined;
  }

  get bondingContractInquiry() {
    return this.policyList ? this.policyList.bondingContractInquiry : undefined;
  }

  get policyInquiry() {
    return this.policyList ? this.policyList.policyInquiry : undefined;
  }

  get policyList() {
    return this.limitRequest.limit.policyLimitList;
  }

  get allowedPolicyProductIds(): number[] {
    return this.limitRequest && this.limitRequest.type.id === LimitRequestType.FARMER
      ? this.businessProperties.nonStandardPolicyProductsForLimit
      : this.businessProperties.standardPolicyProductsForLimit;
  }

  get clauseTypeIds(): number[] {
    if (!this.limitRequest) {
      return undefined;
    }
    if (this.limitRequest.type.id === LimitRequestType.FARMER) {
      return BusinessUtils.LIMIT_FARMER_CLAUSES;
    }
    if (this.limitRequest.type.id === LimitRequestType.FALCON) {
      return BusinessUtils.LIMIT_FALCON_CLAUSES;
    }
    return undefined;
  }

  getBuyer() {
    return BusinessUtils.getThirdPartyCompany(this.limitRequest.limit.buyer);
  }

  get buyerCountry(): DictionaryBaseDto {
    return (this.getBuyer() && this.getBuyer().company && this.getBuyer().company.address.country) || undefined;
  }

  selectorChooser = (allSelectors: SectionSelector[]): SectionSelector[] => {
    const selectors = [];
    if (!this.portal) {
      if (this.showInsured()) {
        selectors.push(LimitSelector.INSURED);
      }
      selectors.push(LimitSelector.POLICY_LIST);
      if (this.showFactorer()) {
        selectors.push(LimitSelector.FACTORER);
      }
      selectors.push(LimitSelector.BUYER);
    } else {
      selectors.push(LimitSelector.BUYER);
      selectors.push(LimitSelector.POLICY_LIST);
      if (this.showFactorer()) {
        selectors.push(LimitSelector.FACTORER);
      }
    }
    return selectors.map((prop) => allSelectors.find((x) => x.property === prop));
  };

  showFactorer(): boolean {
    const required =
      (this.policyInquiry && this.policyInquiry.activatedOrLastVersion.divisionIntoFactorers) ||
      (this.masterPolicy &&
        this.masterPolicy.limitMultiListAllowed &&
        BusinessUtils.isFactoringPolicyType(this.masterPolicy.productType.id));
    return (
      (!this.limitRequest.limit.id && required) ||
      (this.limitRequest.limit.policyLimitList && !!this.limitRequest.limit.policyLimitList.factorer)
    );
  }

  showInsured(): boolean {
    return !this.isBondingLimit();
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  emitAllSelectorsSet() {
    if (this.allSet) {
      this.allSelectorsSet.emit();
    }
  }

  openNextUnset() {
    this.selectors.openNextUnset();
  }

  get fixedCountry() {
    return this.isFalcon() || this.isFarmer();
  }
}

export enum LimitSelector {
  INSURED = 'insured',
  POLICY_LIST = 'policyLimitList',
  BUYER = 'buyer',
  FACTORER = 'policyLimitList.factorer',
}
