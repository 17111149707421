import {Component, Input} from '@angular/core';
import {
  CollectionPaymentDto,
  CollectionPaymentsService,
  CollectionVersionDto,
  DictionaryBaseDto,
} from '../../../bonding_shared';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'collection-recovery-bonding-payments-summary',
  templateUrl: 'collection-recovery-bonding-payments-summary.pug',
})
export class CollectionRecoveryBondingPaymentsSummaryComponent {
  recoveryBondingPaymentSummary: RecoveryBondingPaymentSummary[] = [];
  paymentSummaryTable = 'Brak płatności';

  @Input()
  set collectionVersion(collectionVersion: CollectionVersionDto) {
    const claimIndemnificationVersion = collectionVersion?.parent?.claimIndemnificationVersion;
    if (!claimIndemnificationVersion) {
      this.recoveryBondingPaymentSummary = [];
      return;
    }
    const mainDebtPayments = collectionVersion.parent?.payments?.mainDebt;
    const payments = mainDebtPayments ? mainDebtPayments : [];
    const totalPayments = payments.map((payment) => payment.amountGross).reduce(NumberUtils.sum, 0);
    let lastPayment: CollectionPaymentDto;
    if (payments.length > 0) {
      lastPayment = payments.reduce((a, b) => (a.dateOfPayment > b.dateOfPayment ? a : b));
      this.paymentSummaryTable =
        '<table>' + '<tr>' + '<th>Data wpłaty</th>' + '<th>Kwota wpłaty</th>' + '<th>Waluta</th>' + '</tr>';
      for (const p of payments) {
        this.paymentSummaryTable += '<tr>';
        this.paymentSummaryTable += '<td>' + DateUtils.format(p.dateOfPayment) + '</td>';
        this.paymentSummaryTable += '<td>' + p.amountGross + '</td>';
        this.paymentSummaryTable += '<td>' + p.currency.code + '</td>';
        this.paymentSummaryTable += '</tr>';
      }
      this.paymentSummaryTable += '</table>';
    }
    this.recoveryBondingPaymentSummary = [
      {
        indemnificationNoteNumber: claimIndemnificationVersion.parent.number,
        indemnificationPaymentDate: claimIndemnificationVersion.paymentDate,
        indemnificationPaymentAmount: claimIndemnificationVersion.paymentAmount,
        indemnificationPaymentCurrency: claimIndemnificationVersion.parent.calculation.currency,
        totalPayments: totalPayments,
        balance: claimIndemnificationVersion.paymentAmount - totalPayments,
        lastPaymentDate: lastPayment?.dateOfPayment,
        lastPaymentAmount: lastPayment?.amountGross,
      },
    ];
  }

  constructor(private paymentsService: CollectionPaymentsService) {}
}

export interface RecoveryBondingPaymentSummary {
  indemnificationNoteNumber: string;
  indemnificationPaymentDate: Date;
  indemnificationPaymentAmount: number;
  indemnificationPaymentCurrency: DictionaryBaseDto;
  totalPayments?: number;
  balance?: number;
  lastPaymentDate?: Date;
  lastPaymentAmount?: number;
}
