import {NgModule} from '@angular/core';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ContributionsClearanceDeclarationConsentComponent,
  DataComplianceConsentComponent,
  DataSecurityConsentComponent,
  GeneralContractDeclarationsConsentComponent,
  IndividualContractDeclarationsConsentComponent,
  InsuranceInfoDisclosureConsentComponent,
  MarketingEmailConsentComponent,
  PossessedReferencesConsentComponent,
  PreliminaryAgreementConsentComponent,
  TaxOutstandingClearanceDeclarationConsentComponent,
} from './components/common';
import {DataSecurityNoticeComponent, PersonalDataProcessingNoticeComponent} from './components/notice';
import {
  AdditionalDocumentsRequestConsentComponent,
  BankReferencesConsentComponent,
  ResponsibilityDeclarationConsentComponent,
} from './components/bond';
import {BondingConsentsComponent} from './bonding-consents.component';
import {CommonModule} from '@angular/common';
import {AdditionalDocumentsRequestNoticeComponent} from './components/notice/additional-documents-request-notice.component';

@NgModule({
  imports: [SharedComponentsModule, CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    PossessedReferencesConsentComponent,
    ContributionsClearanceDeclarationConsentComponent,
    TaxOutstandingClearanceDeclarationConsentComponent,
    MarketingEmailConsentComponent,
    DataComplianceConsentComponent,
    InsuranceInfoDisclosureConsentComponent,
    PersonalDataProcessingNoticeComponent,
    GeneralContractDeclarationsConsentComponent,
    IndividualContractDeclarationsConsentComponent,
    PreliminaryAgreementConsentComponent,
    ResponsibilityDeclarationConsentComponent,
    BankReferencesConsentComponent,
    AdditionalDocumentsRequestConsentComponent,
    DataSecurityNoticeComponent,
    BondingConsentsComponent,
    AdditionalDocumentsRequestNoticeComponent,
    DataSecurityConsentComponent,
  ],
  exports: [BondingConsentsComponent],
  providers: [],
})
export class BondingConsentsModule {}
