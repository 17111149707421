import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';

@Component({
  selector: 'additional-documents-request-notice',
  template: `
    <div class="row">
      <!-- 30! -->
      <p>
        KUKE S.A. zastrzega sobie prawo zażądania dodatkowych dokumentów i informacji potwierdzających dane zawarte we
        wniosku i złożonych załącznikach lub których konieczność złożenia wyniknie w drodze analizy wniosku.
      </p>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class AdditionalDocumentsRequestNoticeComponent {}
