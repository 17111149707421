import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {BondVersionCriteriaDto} from '../../../../bonding_shared';
import {RouterService} from '../../../../bonding_shared';

@Component({
  selector: 'bond-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <selectable-object-section
        [labelKey]="sectionLabel"
        [clearable]="false"
        [searchable]="!disabled"
        [openSelectorEmitter]="openEmitter"
      >
        <div class="bon-row" *ngIf="object[property]" content="body">
          <b class="a-link col-md-8 font-larger" (click)="router.toBondDetailsByBondId(object[property].id)">{{
            object[property].number ?? object[property].requestNumber
          }}</b>
        </div>
      </selectable-object-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <bond-version-selector-simple
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        (selectItem)="onChange($event.bond)"
        [criteria]="criteria"
      ></bond-version-selector-simple>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: BondSectionSelectorComponent}],
})
export class BondSectionSelectorComponent extends SectionSelector {
  @Input() criteria: BondVersionCriteriaDto;

  constructor(public router: RouterService) {
    super();
  }
}
