import {share, switchMap, takeWhile} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AddressAutocompleteService,
  AppConfigService,
  BinaryDownloaderService,
  BlockchainClientAuthDto,
  BondVersionDto,
  BondVersionService,
  BusinessUnitSimpleDto,
  CompanySimpleDto,
  ContractBondDto,
  ContractCompanyDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionService,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  DocumentDescriptionDto,
  DocumentService,
  FormatService,
  GrowlService,
  LoggedUserService,
  RelatedObject,
  SearchDataProvider,
  TemplateSimpleDto,
  UserDto,
} from '../../bonding_shared';
import {ClientBondGuiService} from './services';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BondingContractDocumentType,
  BondStatus,
  BondSubType,
  BusinessObjectType,
  ContractType,
} from '../../bonding_shared/model/dictionary-ids';
import {PortalRouterService} from '../services';
import {Observable, Observer, Subject, Subscription} from 'rxjs';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {ClientBondFormComponent} from './components/clientbond-form.component';
import {ClientBondSubtypeSelectorComponent} from './components/clientbond-subtype-selector.component';
import {ClientBondFlowService} from './domain/clientbond-flow.domain';
import {BlockchainService} from '../blockchain/services';
import {BusinessUnit} from '../../bonding_shared/model/business-unit';

declare var TMiningWallet: any;

// todo change field name executionAddressComment to contractSubject
@Component({
  selector: 'clientbond-details',
  templateUrl: './clientbond-details.component.pug',
  providers: [ClientBondFlowService, AddressAutocompleteService],
})
export class ClientBondDetailsComponent extends DetailsView implements OnInit, AfterViewInit, OnDestroy {
  readonly TAX_ID_TYPE: string = 'TAX_ID';
  readonly NATIONAL_ID_TYPE: string = 'NATIONAL_ID';
  readonly FINAL_PREVIEW_ENDPOINT = 'portal/bondVersion/genFinalPreview/';
  readonly PREVIEW_ENDPOINT = 'portal/bondVersion/preview/';

  readonly BOND_TYPE_WARENLIEFERINGEN: number = 1000019;
  readonly BOND_TYPE_BIETUNG: number = 1000020;
  readonly BOND_TYPE_MIETBURGSHAFT: number = 1000021;
  readonly BOND_SUBTYPE_MIETBURGSHAFT_MIT_KUNDIGUNGSKLAUSEL: number = 34000064;
  readonly BusinessObjectType = BusinessObjectType;
  readonly BondingContractDocumentType = BondingContractDocumentType;

  langCodeRegexp: RegExp = /^(?:it|es|de|fr|en|pl|nl)$/;

  bond: BondVersionDto;

  validToDisabled = true;

  @ViewChild('clientbondForm')
  set clientbondForm(cmp: ClientBondFormComponent) {
    if (cmp) {
      this._clientbondForm = cmp;
    }
  }

  get clientbondForm(): ClientBondFormComponent {
    return this._clientbondForm;
  }

  _clientbondForm: ClientBondFormComponent;

  @ViewChild('clientbondSubtype')
  set clientbondSubtypeSelector(cmp: ClientBondSubtypeSelectorComponent) {
    if (cmp) {
      this._clientbondSubtypeSelector = cmp;
    }
  }

  get clientbondSubtypeSelector(): ClientBondSubtypeSelectorComponent {
    return this._clientbondSubtypeSelector;
  }

  _clientbondSubtypeSelector: ClientBondSubtypeSelectorComponent;

  @ViewChild('previewData', {static: true}) previewDataInput: ElementRef;

  showDetails = false;
  newBond = false;

  selectedCompany: CompanySimpleDto;
  selectableClients: CompanySimpleDto[];
  selectedContractBond: ContractBondDto;
  selectedContractVersion: ContractVersionDto;
  subtype: DictionaryDto;
  hiddenSubtypes: Set<number> = new Set<number>().add(BondSubType.WARENLIEFERUNGEN_60_TAGE);

  autoSelectContractId: number;
  private autoSelectContractBondId: number = null;

  contractCriteria: ContractVersionCriteriaDto;
  contractSearchDataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>;

  urlPrefix: string;

  templates: TemplateSimpleDto[];

  private paramsSubscription: Subscription;

  private confirmationDialogSubject: Subject<boolean> = null;
  private formChangedAfterPreview = false;
  protected bondTypes: DictionaryDto[] = [];

  replacedBondVersionId: number;
  protected isSpecial = false;
  initialDuration: number;
  walletError = false;
  documentListExpanded = false;

  constructor(
    private route: ActivatedRoute,
    public guiService: ClientBondGuiService,
    private router: PortalRouterService,
    private loggedUserService: LoggedUserService,
    private apiService: AppConfigService,
    private dictService: DictionaryService,
    private documentService: DocumentService,
    protected growlService: GrowlService,
    private portalContractService: ContractVersionService,
    private binaryDownloader: BinaryDownloaderService,
    private cd: ChangeDetectorRef,
    public bondFlow: ClientBondFlowService,
    private blockchainService: BlockchainService,
    private formatService: FormatService,
    private bondService: BondVersionService
  ) {
    super(growlService);
    this.urlPrefix = this.apiService.backendUrl;

    this.selectorNameList = [
      RelatedObject.CONTRACT,
      RelatedObject.CLIENT,
      RelatedObject.BENEFICIARY,
      RelatedObject.BOND_TYPE,
    ];

    this.initializeSelectorEmitters(true);
    this.initializeContractSearchCriteria();
  }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params) => this.initializeView(params));
    this.route.queryParams.subscribe((queryParams) => (this.isSpecial = queryParams['special'] === 'true'));
    this.hideButtons(true);
    this.cancelButton.hidden = false;
    this.initCompanyFromUser();
    this.dictService.getDictionary('BondType').subscribe((entries) => {
      this.bondTypes = entries;
    });
  }

  ngAfterViewInit() {
    this.openContractSelector();
  }

  private openContractSelector() {
    if (this.newBond) {
      this.openSelectorEmitters['Contract'].next(true);
    }
  }

  isBondEmpty() {
    return !this.bond || !this.bond.id;
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  initializeView(params: Params, force?: boolean) {
    const id = +params['id'];
    const contractVersionId = +params['contractVersionId'];
    const bondTypeId = +params['bondTypeId'];
    const replacedBondVersionId = +params['replacedBondVersionId'];
    this.initializeViewBond(id, contractVersionId, bondTypeId, replacedBondVersionId);
  }

  private initializeViewBond(id: number, contractVersionId: number, bondTypeId: number, replacedBondVersionId: number) {
    if (this.bond && this.bond.id === id) {
      return;
    }
    this.replacedBondVersionId = replacedBondVersionId;
    this.objectNotFound = false;
    this.guiService.clearCacheExceptBondVersion();
    this.initializeBond(id);
    this.updateControls();

    if (id === 0 && !replacedBondVersionId) {
      this.setAutoselected(contractVersionId, bondTypeId);
    }
  }

  private setAutoselected(contractVersionId: number, bondTypeId: number) {
    if (contractVersionId) {
      this.autoSelectContractId = contractVersionId;
      this.contractSearchDataProvider.searchCriteria.criteria.id = contractVersionId;
    }
    if (bondTypeId) {
      this.autoSelectContractBondId = bondTypeId;
    }
  }

  initializeBond(id: number /*, clientId: number, contractId: number*/) {
    this.serverErrors = undefined;
    if (id > 0) {
      this.newBond = false;
      this.getBondVersion(id);
    } else if (id === 0) {
      this.selectedContractBond = null;
      this.showDetails = false;
      this.newBond = true;
    } else {
      this.objectNotFound = true;
    }
  }

  initializeContractSearchCriteria() {
    this.contractCriteria = this.portalContractService.getClientContractsCriteria(false);
    this.contractSearchDataProvider = new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>(
      this.portalContractService
    );
    this.contractSearchDataProvider.textSearch = false;
    this.contractSearchDataProvider.textSearchCriteria = new TextSearchCriteria();
    this.contractSearchDataProvider.searchCriteria.criteria = this.contractCriteria;
    this.contractSearchDataProvider.additionalQueryParams = [{key: 'single', val: 'true'}];
  }

  setBond(bond: BondVersionDto) {
    this.bond = bond;
    if (this.bond && this.selectedContractVersion && this.selectedContractVersion.contract) {
      this.bond.contractBusinessUnit = this.selectedContractVersion.contract.businessUnit;
    }
    this.setupBond();
    this.bondFlow.setupBond(bond);
    if (bond.bondValidTo && bond.validFrom) {
      this.initialDuration = bond.bondValidTo.getTime() - bond.validFrom.getTime();
    } else {
      this.initialDuration = undefined;
    }
    this.updateErrors(this.bond.warnings);
  }

  setupBond() {
    if (this.bond.clientId) {
      this.initCompany(this.bond.clientId);
    }
    if (this.bond.type) {
      this.initContractVersion(this.bond.contractVersionId, this.bond.type.id);
    }

    this.serverErrors = this.bond.warnings;
    this.handleButtons();
    this.guiService.setBondValuesVisible(this.bond);
    this.refreshBondVersionList();

    this.updateControls();
    // turn off last 10 beneficiaries dropdown

    this.dictService.getDictionary('BondSubtype').subscribe((result) => {
      this.subtype = result.find((i) => i.id === this.bond.subtype.id);
      this.showDetails = true;
    });
    this.handleValidToEditability();
  }

  initContractVersion(contractVersionId: number, bondTypeId: number) {
    if (contractVersionId && contractVersionId > 0) {
      this.guiService.getContractVersion(contractVersionId).subscribe((c) => {
        this.afterContractVersionReceived(c, bondTypeId);
        this.loadTemplates();
      });
    }
  }

  afterContractVersionReceived(c: ContractVersionDto, bondTypeId: number) {
    const bondTypeSelected = bondTypeId && bondTypeId > 1;
    this.setContractVersion(c);
    if (c) {
      this.selectableClients = this.getClients(this.selectedContractVersion);
      if (c.contract) {
        this.bond.contractBusinessUnit = c.contract.businessUnit;
      }
    }

    if (c && bondTypeSelected) {
      this.selectedContractBond = this.guiService.getContractBondTypeById(c.bondTypes, bondTypeId);
    }
  }

  setContractVersion(c: ContractVersionDto) {
    this.selectedContractVersion = c;
    this.bondFlow.contractVersion = c;
  }

  initCompany(clientId: number) {
    if (clientId > 0) {
      this.guiService.getCompany(clientId).subscribe((c) => {
        this.selectedCompany = c;
        this.contractCriteria.clientId = clientId;
      });
    } else {
      this.selectedCompany = undefined;
    }
  }

  public saveBondNoConfirm() {
    if (!this.syncBondFormControllersAndBondDto(this.formChangedAfterPreview)) {
      return null;
    }
    this.inProgress = true;
    this.saveAndGetObservable().subscribe({
      next: (bond) => (this.bond = bond),
      error: () => (this.inProgress = false),
      complete: () => (this.inProgress = false),
    });
  }

  private saveBond(): Observable<BondVersionDto> {
    if (!this.syncBondFormControllersAndBondDto(this.formChangedAfterPreview)) {
      return null;
    }

    return this.askForConfirmation(this.saveAndGetObservable.bind(this));
  }

  private askForConfirmation(confirmationSuccess: () => Observable<BondVersionDto>): Observable<BondVersionDto> {
    const confirmationObservable: Observable<boolean> = this.openSummaryDialog();
    return confirmationObservable.pipe(
      takeWhile((result) => result),
      switchMap(() => confirmationSuccess())
    );
  }

  private saveAndGetObservable(): Observable<BondVersionDto> {
    let observer: Observer<BondVersionDto>;

    this.guiService.saveBondVersion(this.bond).subscribe({
      next: (bond) => {
        this.afterBondSaved(bond);
        this.inProgress = false;
        observer.next(bond);
        observer.complete();
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
        observer.error(error);
      },
    });

    return new Observable<BondVersionDto>(function (observerParam: Observer<BondVersionDto>) {
      observer = observerParam;
    }).pipe(share());
  }

  afterBondSaved(bond: BondVersionDto) {
    this.showSavedMsg();
    this.newBond = false;
    this.setBond(bond);
  }

  onSubtypeSelect(subtype: DictionaryDto) {
    if (!this.showDetails) {
      if (subtype) {
        this.bondInit(subtype);
      }
    } else {
      this.bond.subtype = subtype;

      // this call is used because i need DictionaryDto and not DictionaryBaseDto (dict-base set base object instead full)
      // TODO: define if dict-base needs refactor to use also DictionaryDto. In case remove below service calling.
      this.dictService.getDictionary('BondSubtype').subscribe((result) => {
        this.subtype = result.find((i) => i.id === this.bond.subtype.id);
      });
      this.updateBondOutput();
      // this.clientbondForm.setValidityPeriodCombo();
    }
    if (this.bond) {
      this.guiService.setBondValuesVisible(this.bond);
    }

    if (this.showDetails && this.clientbondForm.bondCustomFieldsComponent) {
      this.clientbondForm.bondCustomFieldsComponent.resetCustomFields();
    }

    this.handleValidToEditability();
  }

  private bondInit(subtype: DictionaryDto) {
    this.guiService
      .initialVersion(
        this.selectedCompany.id,
        subtype.id,
        this.selectedContractVersion.contract.businessUnit.id,
        this.replacedBondVersionId,
        this.selectedContractVersion.id,
        this.bondType.id
      )
      .subscribe({
        next: (bond) => {
          this.clearErrors();
          this.afterBondInit(<BondVersionDto>bond, subtype);
        },
        error: (err) => {
          this.handleServerError(err, false);
        },
      });
  }

  updateBondOutput() {
    this.inProgress = true;
    this.bondService
      .getBondOutput(this.bondType?.id, this.bond?.subtype?.id, this.selectedContractVersion?.id)
      .subscribe({
        next: (o) => {
          this.bond.output = o;
        },
        error: (error) => this.handleServerError(error),
        complete: () => (this.inProgress = false),
      });
  }

  canChangeRelatedObject() {
    return this.guiService.isNotActiveBond(this.bond) && !this.guiService.isAnnex(this.bond) && !this.canFullIssue();
  }

  canChangeRelatedBondType(): boolean {
    return (
      this.guiService.isNotActiveBond(this.bond) &&
      !this.guiService.isAnnex(this.bond) &&
      !this.canFullIssue() &&
      !!this.selectedContractVersion &&
      ((!!this.bond && !!this.bond.id) || !this.selectedContractVersion.suspended)
    );
  }

  onSelectBondVersion(version: BondVersionDto) {
    this.getBondVersion(version.id);
  }

  setLastBondVersion(bondId: number, infoAfterSuccess: string) {
    this.guiService.getLastBondVersionForBond(bondId).subscribe({
      next: (bv) => this.afterLastBondVersionFound(<BondVersionDto>bv, infoAfterSuccess),
      error: () => this.growlService.error('Error getting previous bond version'),
    });
  }

  afterLastBondVersionFound(bv: BondVersionDto, infoAfterSuccess: string) {
    if (infoAfterSuccess) {
      this.growlService.notice(infoAfterSuccess);
    }
    if (!bv) {
      // the only bond version is deleted, go to bond-creation view
      this.router.toBondDetails(0);
    } else {
      this.setBond(bv);
    }
  }

  goToContract(id: number) {
    this.router.toClientContractPreview(id);
  }

  get bondNumber(): string {
    if (this.bond && this.bond.id && this.bond.id > 0) {
      if (!this.guiService.isBondRequest(this.bond, false)) {
        return this.bond.bond.number + '/' + this.bond.versionNumber;
      } else {
        return this.bond.bond.requestNumber + '/' + this.bond.versionNumber;
      }
    }
    return undefined;
  }

  onCreateNewVersion() {
    this.guiService.newBondVersionForBond(this.bond.bond.id).subscribe((bond) => {
      this.setBond(<BondVersionDto>bond);
    });
  }

  onSelectContract(contractVersion: ContractVersionDto) {
    this.guiService.getContractVersion(contractVersion.id).subscribe((cv) => {
      this.setContractVersion(cv);
      this.frontendErrors = [];
      if (this.selectedContractVersion.suspended) {
        this.frontendErrors.push({msgKey: 'clientbond.details.errorMessage.bondCreationImpossible', warning: false});
        this.growlService.error('clientbond.details.errorMessage.bondCreationImpossible');
        return;
      }
      this.selectableClients = this.getClients(this.selectedContractVersion);
      const autoSelectedType: ContractBondDto = this.getAutoSelectedType();
      if (autoSelectedType !== null) {
        this.selectBondType(autoSelectedType, false);
      } else {
        this.openSelectorEmitters['BondType'].next(true);
        this.selectedContractBond = null;
      }
    });
  }

  isContractAutoSelect(): boolean {
    return this.getAutoSelectedType() !== null;
  }

  getClients(cv: ContractVersionDto): CompanySimpleDto[] {
    if (cv && cv.contractCompanies) {
      const companies: ContractCompanyDto[] = cv.contractCompanies;
      const simples: CompanySimpleDto[] = new Array(companies.length);

      for (let i = 0; i < companies.length; i++) {
        simples[i] = companies[i].company;
      }

      return simples;
    }
    return new Array<CompanySimpleDto>();
  }

  onSelectClient(client: CompanySimpleDto) {
    this.selectClient(client, true);
  }

  selectClient(client: CompanySimpleDto, emit: boolean) {
    this.selectedCompany = client;
    if (emit && !this.selectedContractVersion) {
      // selected contract version is autoelected,this should happen only once
      this.openSelectorEmitters['Client'].next(false);
    }
  }

  onSelectBondType(bondType: ContractBondDto) {
    this.selectBondType(bondType, true);
  }

  selectBondType(bondType: ContractBondDto, emit: boolean) {
    this.selectedContractBond = bondType;
    if (this.bond && this.bond.specialClauses) {
      this.bond.specialClauses.length = 0;
    }
    if (emit) {
      this.openSelectorEmitters['BondType'].next(false);
    }
  }

  handleButtons() {
    this.newVersionButton.hidden = true;
    // Button new version always hidden. Uncomment to use the old control (BON-1333)
    // this.newVersionButton.hidden = this.newVersionDisabled();
  }

  onChoosePhysicalPerson(physicalPerson: boolean) {
    if (physicalPerson) {
      this.bond.beneficiaryRaw.physicalPerson = {
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        personalIdentity: null,
        seriesIdNumber: null,
      };
    } else {
      this.bond.beneficiaryRaw.physicalPerson = null;
    }
    this.updateControls();
  }

  // onSelectedCountry(country: Dictionary) {
  //   this.numberFormatService.getNumberFormat(country.id, this.NATIONAL_ID_TYPE)
  //     .subscribe(
  //       numberFormat => this.nationalIdNumberFormat = numberFormat,
  //       () => console.log('Error on getNumberFormat,'),
  //       () => this.updateNationalIdValidator()
  //     );
  //   this.numberFormatService.getNumberFormat(country.id, this.TAX_ID_TYPE)
  //     .subscribe(
  //       numberFormat => this.taxNumberFormat = numberFormat,
  //       () => console.log('Error on getNumberFormat,'),
  //       () => this.updateTaxValidator()
  //     );
  // }

  // updateNationalIdValidator() {
  //   if (this.nationalIdNumberFormat) {
  //     let nationalIdControles = (<FormGroup>this.bondForm.controls['beneficiary']).controls['nationalId'];
  //     if (this.nationalIdNumberFormat.regExp && this.nationalIdNumberFormat.regExp.length > 0) {
  //       nationalIdControles.validator =
  //         Validators.compose([CustomValidators.numberFormatPattern(this.nationalIdNumberFormat.regExp, true)]);
  //     } else {
  //       nationalIdControles.validator = null;
  //     }
  //     nationalIdControles.updateValueAndValidity();
  //     if (!this.nationalIdNumberFormat.numberName) {
  //       this.nationalIdNumberFormat.numberName = this.DEFAULT_NATIONAL_ID_NAME;
  //     }
  //   }
  // }

  // updateTaxValidator() {
  //   if (this.taxNumberFormat) {
  //     let vatNumberControles = (<FormGroup>this.bondForm.controls['beneficiary']).controls['vatNumber'];
  //     if (this.taxNumberFormat.regExp && this.taxNumberFormat.regExp.length > 0) {
  //       vatNumberControles.validator =
  //         Validators.compose([CustomValidators.numberFormatPattern(this.taxNumberFormat.regExp, true)]);
  //     } else {
  //       vatNumberControles.validator = null;
  //     }
  //     vatNumberControles.updateValueAndValidity();
  //     if (!this.taxNumberFormat.numberName) {
  //       this.taxNumberFormat.numberName = this.DEFAULT_TAX_NAME;
  //     }
  //   }
  // }

  onPreview() {
    this.serverErrors = null;
    if (!this.syncBondFormControllersAndBondDto(false)) {
      return;
    }

    const fileName = this.formatService.formatDate(new Date()) + '-draft.pdf';
    this.updateCustomTemplate();
    if (
      this.clientbondForm.validityPeriod.id === this.clientbondForm.VALIDITY_PERIOD_UNLIMITED &&
      !this.isBUIt(this.selectedContractVersion.contract.businessUnit)
    ) {
      this.bond.bondValidTo = undefined;
    }
    this.bond.validTo = this.bond.bondValidTo;
    this.binaryDownloader.download(
      'POST',
      this.urlPrefix + this.PREVIEW_ENDPOINT,
      'application/json',
      'application/octet-stream',
      fileName,
      this.bond,
      (errMsg) => this.downloadErrorCallback(errMsg),
      () => (this.inProgress = false)
    );

    this.formChangedAfterPreview = false;
  }

  isBUIt(bu: BusinessUnitSimpleDto) {
    return !!(bu && bu.name === 'IT');
  }

  onIssue() {
    console.log('templates', this.templates);
    this.serverErrors = null;
    this.bond.status = <DictionaryBaseDto>{id: BondStatus.BOND};
    this.updateCustomTemplate();
    const saveBondSubscription = this.saveBond();
    if (saveBondSubscription) {
      saveBondSubscription.subscribe(
        (bond) => {
          const fileName = bond.bond.number + '.pdf';
          this.binaryDownloader.download(
            'GET',
            this.urlPrefix + this.FINAL_PREVIEW_ENDPOINT + bond.id,
            'application/pdf',
            'application/octet-stream',
            fileName
          );
          this.router.toBondDetails(bond.id);
        },
        (error) => {
          this.serverErrors = error;
        },
        () => {
          this.inProgress = false;
        }
      );
    }
  }

  private updateCustomTemplate() {
    this.clientbondSubtypeSelector.updateCustomTemplate(this.bond);
  }

  updateControls() {
    this.handleButtons();
  }

  canIssue(): boolean {
    return this.bond && !this.bond.id;
  }

  disableBondSubtypeSelection(): boolean {
    return this.bond && (this.guiService.isAnnex(this.bond) || !this.canFullIssue());
  }

  canFullIssue(): boolean {
    return this.canIssue() && (!this.bond.versionNumber || this.bond.versionNumber === 1);
  }

  private addAuthTokenToUrl(loggedUserService: LoggedUserService) {
    const token = loggedUserService.accessToken;
    const login = loggedUserService.getLoggedUserData().login;
    const authString = login + ':' + token + '@';
    const httpsPrefix = 'https://';
    this.urlPrefix = this.urlPrefix.replace(httpsPrefix, httpsPrefix + authString);
  }

  private initCompanyFromUser() {
    const user: UserDto = <UserDto>this.loggedUserService.getLoggedUserData();
    this.selectedCompany = user.company;
  }

  private afterBondInit(bond: BondVersionDto, subtype: DictionaryDto) {
    bond.subtype = subtype;
    this.setBond(bond);
    this.bond.contractVersionId = this.selectedContractVersion.id;
    this.bond.clientId = this.selectedCompany.id;

    if (this.canFullIssue()) {
      // this.clientbondForm.initLast10Beneficiaries();
    }
    if (this.bond.blockchainReference) {
      this.inProgress = true;
      this.blockchainService.getJWT().subscribe((token) =>
        TMiningWallet.API.getAddressAndKeyForReference(token, this.bond.blockchainReference).then(
          (resp) => {
            this.bond.blockchainClientAuth = <BlockchainClientAuthDto>{
              owner: resp.result.address,
              clientPublicKey: resp.result.pubKey,
            };
            this.inProgress = false;
          },
          (error) => {
            this.walletError = true;
          }
        )
      );
    }
  }

  private getAutoSelectedType(): ContractBondDto {
    const contractVersion = this.selectedContractVersion;
    if (contractVersion && contractVersion.bondTypes) {
      if (this.autoSelectContractBondId) {
        const autoSelectContractBond = contractVersion.bondTypes.find(
          (bt) => bt.bondType.id === this.autoSelectContractBondId
        );
        if (autoSelectContractBond) {
          return autoSelectContractBond;
        }
      }
      // autoselect one
      if (contractVersion.bondTypes.length === 1) {
        return contractVersion.bondTypes[0];
      }
    }
    return null;
  }

  private newVersionDisabled() {
    return (
      this.isBondEmpty() || !this.bond.last || this.bond.status.code !== 'BON' || this.guiService.anyNotRejectedRequests
    );
  }

  private refreshBondVersionList() {}

  private getBondVersion(versionId: number) {
    this.guiService.getBondVersion(versionId).subscribe(
      (bond) => {
        this.newBond = false;
        this.setBond(<BondVersionDto>bond);
      },
      (error) => {
        this.objectNotFound = true;
      }
    );
  }

  onGetSelected(template: TemplateSimpleDto) {
    const fileName = template.name + '.pdf';
    this.documentService.generatePdf(template.id, this.bond.id, fileName);
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.bondType);
    dictionarySelectors.push(this.bond.subtype);
    this.guiService
      .getClientBondTemplates(this.selectedContractVersion.contract.businessUnit.id, dictionarySelectors)
      .subscribe((generalTemplates) => {
        const templates = [...generalTemplates];
        // todo: choose one of the versions after feedback from business
        // version 1: all custom templates visible in new document
        // this.findCustomTemplates().subscribe(customTemplates => {
        //   templates = templates.concat(customTemplates);
        //   if (templates.length > 0) this.templates = templates;
        // });
        // version 2: only one custom template visible in new document
        if (this.bond.customTemplate) {
          templates.push(this.bond.customTemplate);
        }
        if (templates.length > 0) {
          this.templates = templates;
        } else {
          console.log('No template found');
        }
      });
  }

  private findCustomTemplates(): Observable<TemplateSimpleDto[]> {
    const clientId = this.loggedUserService.getLoggedUserData().company.id;
    return this.guiService.getClientBondCustomTemplates(
      <DictionaryBaseDto[]>[],
      clientId,
      this.selectedContractVersion.contract.id
    );
  }

  onCancel() {
    // sometimes this only preview view and then cancel has to lead to close this site
    this.router.toClientBondVersionSearch(this.selectedContractVersion.id);
    // super.onCancel(this.route);
  }

  confirmationStep() {
    return this.confirmationDialogSubject != null;
  }

  private openSummaryDialog(): Observable<boolean> {
    this.bond.type = this.selectedContractBond.bondType; // todoac balagan w inicjalizacji bond.type
    return (this.confirmationDialogSubject = new Subject<boolean>());
  }

  get bondType(): DictionaryBaseDto {
    return this.selectedContractBond ? this.selectedContractBond.bondType : this.bond.type;
  }

  onSummaryClosed(userConfirmed = false) {
    const confirmDialog = this.confirmationDialogSubject;
    if (confirmDialog) {
      confirmDialog.next(userConfirmed);
      confirmDialog.complete();
    }
    this.confirmationDialogSubject = null;

    this.cd.detectChanges();
  }

  formChanged(event: boolean) {
    this.formChangedAfterPreview = true;
  }

  private syncBondFormControllersAndBondDto(pressPreview: boolean) {
    this.inProgress = true;
    const b: boolean = this.clientbondForm.syncBondFormControllersAndBondDto(this.formChangedAfterPreview);
    if (!b) {
      this.inProgress = false;
      return false;
    }

    this.guiService.setRelatedObjectIdsInBondVersion(
      this.bond,
      this.selectedCompany,
      this.selectedContractVersion,
      this.selectedContractBond
    );

    this.serverErrors = undefined;

    return true;
  }

  onSpecialTextUploadSelect(doc: DocumentDescriptionDto) {
    const cs = this.clientbondSubtypeSelector;
    if (!cs) {
      return;
    }
    cs.onSpecialTextUploadSelect(doc);
  }

  onIssueRequest() {
    this.bond.status = <DictionaryBaseDto>{id: BondStatus.BOND_REQUEST};
    this.serverErrors = null;
    this.updateCustomTemplate();

    if (!this.syncBondFormControllersAndBondDto(this.formChangedAfterPreview)) {
      return;
    }
    const saveBondSubscription = this.saveAndGetObservable();
    if (saveBondSubscription) {
      saveBondSubscription.subscribe(
        (bond) => {
          this.router.toBondDetails(bond.id);
        },
        (error) => {
          this.serverErrors = error;
        }
      );
    }
  }

  isSelectableClients() {
    const sc = this.selectableClients;
    return sc && sc.length > 1;
  }

  canSelectContract() {
    return this.canFullIssue() && !this.selectedContractVersion /*!this.isContractAutoSelect()*/;
  }

  replacementRequestVisible() {
    if (this.bond && this.bond.id && this.bond.issueDate && !this.bond.replacedBondVersion) {
      if (this.bond.status.id === BondStatus.BOND_CANCELLED || this.bond.status.id === BondStatus.BOND_RELEASED) {
        return false;
      }

      // BON-5939
      if (this.bond.contractType.id === ContractType.INDIVIDUAL) {
        return false;
      }

      const currentDate = new Date();
      const bondIssueDate = new Date(this.bond.issueDate);
      bondIssueDate.setDate(this.bond.issueDate.getDate() + 14);
      if (currentDate.getTime() > bondIssueDate.getTime()) {
        return false;
      }
      const bondValidToDate = new Date(this.bond.bondValidTo);
      return currentDate.getTime() <= bondValidToDate.getTime();
    }
    return false;
  }

  onReplacementRequest() {
    this.router.toBondReplacementProcedure(this.bond.id);
  }

  showSpecialTextUpload(): boolean {
    const cs = this.clientbondSubtypeSelector;
    return !!(cs && cs.showSpeciaTextUpload());
  }

  get isSpecialTextMode(): boolean {
    return this.clientbondSubtypeSelector && this.clientbondSubtypeSelector.isSpecialTextMode;
  }

  showDocumentSection(): boolean {
    return (
      this.showDetails &&
      this.bond.id &&
      this.selectedContractVersion &&
      this.selectedContractVersion.bondIssuanceInPortal
    );
  }

  onDocumentsLoaded(count: number) {
    this.documentListExpanded = count > 0;
  }

  handleValidToEditability() {
    this.validToDisabled = !(this.bond && this.bond.subtype && this.bond.subtype.id !== BondSubType.PROMESSE);
  }
}
