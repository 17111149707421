import {Directive, Input} from '@angular/core';
import {BondingConsentsDto} from '../../../bonding_shared';

@Directive()
export abstract class AbstractConsentComponent<T extends BondingConsentsDto> {
  @Input() showErrors = false;
  @Input() readonly = false;
  @Input() consents: T;
  @Input() validate = true;
}
