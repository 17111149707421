import {Component} from '@angular/core';

@Component({
  selector: 'personal-data-processing-notice',
  template: `
    <div class="row">
      <!-- 10 (21) -->
      <p>
        <a href="https://kuke.com.pl/przetwarzanie-danych-osobowych" target="_blank">
          https://kuke.com.pl/przetwarzanie-danych-osobowych
        </a>
      </p>
    </div>
  `,
})
export class PersonalDataProcessingNoticeComponent {}
