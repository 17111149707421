import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'preliminary-agreement-consent',
  template: `
    <div class="row flex">
      <!-- 19! -->
      <checkbox-entity
        class="start"
        name="preliminaryAgreement"
        [(ngModel)]="consents.preliminaryAgreement"
        [readonly]="readonly"
        [requiredTrue]="validate && true"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>
          Wniosek niniejszy jest naszą wstępną propozycją zawarcia umowy o udzielenie gwarantowanej przez Skarb Państwa
          gwarancji ubezpieczeniowej.
        </p>
        <p>
          Zobowiązujemy się w razie potrzeby, w terminie wskazanym przez KUKE S.A. uzupełnić zawarte w nim dane oraz
          dołączyć inne żądane dokumenty.
        </p>
        <p>
          Akceptujemy, że zgoda KUKE S.A. na udzielenie wnioskowanej gwarantowanej przez Skarb Państwa gwarancji
          ubezpieczeniowej, zostanie w sposób wiążący prawnie, oświadczona w dwustronnie podpisanej umowie na formularzu
          stosowanym przez KUKE S.A.
        </p>
        <p>
          Oświadczamy, że ponosimy wszelką odpowiedzialność za dane zawarte w niniejszym wniosku oraz dokumentach do
          niego załączonych.
        </p>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class PreliminaryAgreementConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
