import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  BondCustomFieldValueDto,
  BondVersionDto,
  BTableComponent,
  DictionaryBaseDto,
  DictionaryService,
  FormatService,
} from '../../../../bonding_shared';
import {UntypedFormGroup} from '@angular/forms';
import {DateUtils} from '../../../../bonding_shared/utils/date-utils';
import {BondCustomFieldType} from '../../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'bond-custom-fields',
  templateUrl: 'bond-custom-fields.component.html',
})
export class BondCustomFieldsComponent {
  @ViewChild(BTableComponent) table: BTableComponent<BondCustomFieldValueDto>;

  public _bond: BondVersionDto;
  public _originalSubtype: DictionaryBaseDto;
  customFieldsEntries: DictionaryBaseDto[];

  @Input() set originalSubtype(originalSubtype: DictionaryBaseDto) {
    this._originalSubtype = originalSubtype;
  }

  get originalSubtype() {
    return this._originalSubtype;
  }

  @Input() set bond(bond: BondVersionDto) {
    this._bond = bond;
    if (!this._bond.customFields || this._bond.customFields.length === 0) {
      this.setCustomFields();
    } else {
      this.recalculateDamageClause(false);
    }
  }

  get bond() {
    return this._bond;
  }

  @Input() bondForm: UntypedFormGroup;
  @Input() showErrors = false;

  @Output() damageClauseChange = new EventEmitter();
  @Output() timberPaymentDaysChange = new EventEmitter();

  readonly DURATA_ANNUALE: string = 'Annuale';
  readonly TIPOLOGIA_RIMBORSO_ANNUALE: string = 'Annuale';
  readonly TIPOLOGIA_RIMBORSO_INFRANNUALE: string = 'Infrannuale';
  readonly TIPOLOGIA_PROCEDURA_SEMPLIFICATA: string = 'Procedura semplificata';
  readonly TIPOLOGIA_PROCEDURA_ORDINARIA: string = 'Procedura ordinaria';
  readonly YEARLY: number = 12;
  readonly HALF_YEARLY: number = 6;
  readonly NOT_REQUESTED: string = 'Non richiesto';
  readonly NOT_REQUESTED_EN: string = 'Not required';
  readonly TEXTAREAMAXLENGTH = 540;
  readonly YES_IT = 'Si';
  readonly DATE_OF_AUCTION_MONTHS = 4;
  readonly RELEASE_TYPE_100 = '100%';
  readonly RELEASE_TYPE_50_50 = '50% / 50%';
  readonly RELEASE_TYPE_OTHER = this.translateService.instant('clientbond.details.customFieldValue.other');
  readonly DAMAGE_CLAUSE_20_PERCENT = {
    value: '20% of the cost of the timber lot – max 6.000,00 EUR',
    translationKey: '20percent',
  };
  readonly DAMAGE_CLAUSE_FREE = {value: 'Free value', translationKey: 'freeValue'};
  readonly NO_DAMAGE_CLAUSE = {value: 'No damage clause', translationKey: 'noDamageClause'};
  readonly DAMAGE_CLAUSE_DEFAULT_VALUE = 6000;

  italianBooleanItems = [this.toVL('Si'), this.toVL('No')];
  periodoDichiarazioneItems = [
    this.toVL('Annuale'),
    this.toVL('I trimestre'),
    this.toVL('II trimestre'),
    this.toVL('III trimestre'),
    this.toVL('IV trimestre'),
  ];
  tipologiaRimborsoItems = [this.toVL('Annuale'), this.toVL('Infrannuale')];
  tipologiaProceduraItems = [this.toVL('Procedura semplificata'), this.toVL('Procedura ordinaria')];
  periodoRimborsoInfrannuale = [
    this.toVL('I trimestre'),
    this.toVL('II trimestre'),
    this.toVL('III trimestre'),
    this.toVL('IV trimestre'),
  ];
  bePerformanceTypeItems = [
    this.toVL(this.translateService.instant('clientbond.details.customFieldValue.standard')),
    this.toVL(this.translateService.instant('clientbond.details.customFieldValue.firstDemand')),
  ];
  bePerformanceReleaseDateItems = [
    this.toVL(this.translateService.instant('clientbond.details.customFieldValue.approximated')),
    this.toVL(this.translateService.instant('clientbond.details.customFieldValue.fixed')),
  ];
  bePerformanceReleaseTypeItems = [
    this.toVL(this.RELEASE_TYPE_100),
    this.toVL(this.RELEASE_TYPE_50_50),
    this.toVL(this.RELEASE_TYPE_OTHER),
  ];
  bePublicBeneficiaryYN = [this.toVL('Yes'), this.toVL('No')];
  timberPaymentDays = [this.toVL('30'), this.toVL('45')];
  damageClauseTypes = [
    this.toVlTranslated(this.DAMAGE_CLAUSE_20_PERCENT),
    this.toVlTranslated(this.DAMAGE_CLAUSE_FREE),
    this.toVlTranslated(this.NO_DAMAGE_CLAUSE),
  ];
  damageClauseDisabled = false;

  constructor(
    private dictionaryService: DictionaryService,
    private formatService: FormatService,
    private translateService: TranslateService
  ) {}

  private toVL(v: any) {
    return {value: v, label: v};
  }

  private toVlTranslated(v: any) {
    const l = this.translateService.instant('bond.customFields.comboValues.damageClauseType.' + v.translationKey);
    return {value: v.value, label: l};
  }

  toDate(dateStr: string) {
    if (dateStr !== undefined && dateStr != null && dateStr !== '') {
      return DateUtils.toDate(dateStr);
    }
    return undefined;
  }

  setCustomFields() {
    this.dictionaryService.getDictionary('BondSubtype').subscribe((entries) => {
      this.bond.customFields = entries
        .find((entry) => entry.id === (this.originalSubtype ? this.originalSubtype.id : this.bond.subtype.id))
        .relatedDictionaries['BondCustomFieldType'].map((type) => <BondCustomFieldValueDto>{fieldType: type});
      this.customFieldsEntries = this.bond.customFields.map((ft) => ft.fieldType);
    });
  }

  resetCustomFields() {
    this.setCustomFields();
    this.table.resetControls();
  }

  isRequired(field: BondCustomFieldValueDto): string {
    return field.fieldType.code !== 'PERIODO_RIMBORSO_AL' ? '' : null;
  }

  fieldTemplate(field: BondCustomFieldValueDto): string {
    if (!field || !field.fieldType || !field.fieldType.code) {
      return '';
    }

    switch (field.fieldType.code) {
      case 'CAR_NUMBER':
        return FIELD_TEMPLATE.TEXT; // 150000001
      case 'TRAIN_NUMBER':
        return FIELD_TEMPLATE.TEXT; // 150000002
      case 'TASSO_DI_INTERESSE_LEGALE':
        return FIELD_TEMPLATE.NUMBER; // 150000005
      case 'GARA_DAPPALTO':
        return FIELD_TEMPLATE.TEXT; // 150000006
      case 'DATA_PRESENTAZIONE_OFFERTA':
        return FIELD_TEMPLATE.DATE; // 150000007
      case 'IMPEGNO_AL_RILASCIO':
        return FIELD_TEMPLATE.BOOLEAN_SI_NO; // 150000008
      case 'NUMERO_SPEDIZIONI':
        return FIELD_TEMPLATE.NUMBER; // 150000010
      case 'INDIRIZZO_PARTENZA':
        return FIELD_TEMPLATE.TEXT; // 150000011
      case 'INDIRIZZO_DESTINAZIONE':
        return FIELD_TEMPLATE.TEXT; // 150000012
      case 'NUMERO_BOLLETTINI':
        return FIELD_TEMPLATE.TEXT; // 150000013
      case 'NUMERO_BOLLETTINO_DAL':
        return FIELD_TEMPLATE.TEXT; // 150000014
      case 'NUMERO_BOLLETTINO_AL':
        return FIELD_TEMPLATE.TEXT; // 150000015
      case 'NUMERO_NOTIFICA':
        return FIELD_TEMPLATE.TEXT; // 150000016
      case 'TONNELLATE':
        return FIELD_TEMPLATE.NUMBER; // 150000017
      case 'TIPOLOGIA_RIFIUTI_CER':
        return FIELD_TEMPLATE.TEXT; // 150000018
      case 'NUMERO_VIAGGI':
        return FIELD_TEMPLATE.NUMBER; // 150000019
      case 'NUMERO_KM':
        return FIELD_TEMPLATE.NUMBER; // 150000020
      case 'NOTIFICATORE':
        return FIELD_TEMPLATE.TEXT; // 150000021
      case 'DATA_RICHIESTA_RIMBORSO':
        return FIELD_TEMPLATE.DATE; // 150000022
      case 'PERIODO_DICHIARAZIONE':
        return FIELD_TEMPLATE.PERIODO_DICHIARAZIONE; // 150000023
      case 'IMPORTO_RICHIEDIBILE_A_RIMBORSO':
        return FIELD_TEMPLATE.NUMBER; // 150000024
      case 'IMPORTO_RICHIESTO_A_RIMBORSO':
        return FIELD_TEMPLATE.NUMBER; // 150000025
      case 'PERIODO_RIMBORSO_DAL':
        return FIELD_TEMPLATE.DATE; // 150000026
      case 'PERIODO_RIMBORSO_AL':
        return FIELD_TEMPLATE.DATE; // 150000027
      case 'TIPOLOGIA_RIMBORSO':
        return FIELD_TEMPLATE.TIPOLOGIA_RIMBORSO; // 150000028
      case 'ANNO_DICHIARAZIONE':
        return FIELD_TEMPLATE.YEAR; // 150000029
      case 'DATA_PRESENTAZIONE_DICHIARAZIONE':
        return FIELD_TEMPLATE.DATE_PRESENTAZIONE_DICHIARAZIONE; // 150000030
      case 'TIPOLOGIA_PROCEDURA':
        return FIELD_TEMPLATE.TIPOLOGIA_PROCEDURA; // 150000031
      case 'ECCEDENZA_IMPOSTA_CHIESTA_A_RIMBORSO':
        return FIELD_TEMPLATE.NUMBER; // 150000032
      case 'INTERESSI':
        return FIELD_TEMPLATE.INTERESSI_SEMPLIFICATA; // 150000033
      case 'INTERESSI_DA_NOVANTESIMO_GIORNO':
        return FIELD_TEMPLATE.INTERESSI_ORDINARIA; // 150000034
      case 'INTERESSI_SU_ECCEDENZA_IMPOSTA':
        return FIELD_TEMPLATE.INTERESSI_ORDINARIA; // 150000035
      case 'PERIODO_RIMBORSO_INFRANNUALE':
        return FIELD_TEMPLATE.PERIODO_RIMBORSO_INFRANNUALE; // 150000036
      case 'ANNO_DICHIARAZIONE_INFRANNUALE':
        return FIELD_TEMPLATE.YEAR_INFRANNUALE; // 150000037
      case 'ECCEDENZA_IMPOSTA_CHIESTA_A_RIMBORSO_INFRANNUALE':
        return FIELD_TEMPLATE.NUMBER_INFRANNUALE; // 150000038
      case 'INTERESSI_DAL_TERMINE_DI_PAGAMENTO':
        return FIELD_TEMPLATE.NUMBER_INFRANNUALE; // 150000039
      case 'INTERESSI_SULL_ECCEDENZA_IMPOSTA_RIMBORSO_INFRANNUALE':
        return FIELD_TEMPLATE.NUMBER_INFRANNUALE; // 150000040
      case 'CATEGORIA':
        return FIELD_TEMPLATE.TEXT; // 150000041
      case 'CLASSE':
        return FIELD_TEMPLATE.TEXT; // 150000042
      case 'NUMERO_ISCRIZIONE_REN':
        return FIELD_TEMPLATE.TEXT_ISCRIZIONE_ALBO; // 150000043
      case 'NUMERO_ISCRIZIONE_ALBO_AUTOTRASPORTATORI':
        return FIELD_TEMPLATE.TEXT_ISCRIZIONE_ALBO; // 150000044
      case 'ISCRIZIONE_REGISTRO_IMPRESE_CITTA_N':
        return FIELD_TEMPLATE.TEXT_REGISTRO_IMPRESE; // 150000045
      case 'DESCRIZIONE_GARANZIA_PRESTATA':
        return FIELD_TEMPLATE.TEXTAREA; // 150000046
      case 'ISCRITTO_ALBO_AUTOTRASPORTATORI':
        return FIELD_TEMPLATE.ISCRITTO_ALBO_SI_NO; // 150000047
      case 'ISCRITTO_REGISTRO_IMPRESE':
        return FIELD_TEMPLATE.ISCRITTO_REGISTRO_IMPRESE_SI_NO; // 150000048
      case 'DATE_OF_AUCTION':
        return FIELD_TEMPLATE.DATE_OF_AUCTION; // 150000052
      case 'BE_PERFORMANCE_TYPE':
        return FIELD_TEMPLATE.BE_PERFORMANCE_TYPE; // 150000054
      case 'BE_PERFORMANCE_RELEASE_DATE':
        return FIELD_TEMPLATE.BE_PERFORMANCE_RELEASE_DATE; // 150000055
      case 'BE_PERFORMANCE_RELEASE_TYPE':
        return FIELD_TEMPLATE.BE_PERFORMANCE_RELEASE_TYPE; // 150000056
      case 'BE_PERFORMANCE_END_DATE_1':
        return FIELD_TEMPLATE.BE_PERFORMANCE_END_DATE_1; // 150000057
      case 'BE_PERFORMANCE_END_DATE_2':
        return FIELD_TEMPLATE.BE_PERFORMANCE_END_DATE_2; // 150000058
      case 'BE_PUBLIC_BENEFICIARY':
        return FIELD_TEMPLATE.BE_PUBLIC_BENEFICIARY; // 150000059
      case 'LOT_NO':
        return FIELD_TEMPLATE.TEXT; // 150000049
      case 'DAMAGE_CLAUSE':
        return FIELD_TEMPLATE.DAMAGE_CLAUSE; // 150000050
      case 'DAMAGE_CLAUSE_TYPE':
        return FIELD_TEMPLATE.DAMAGE_CLAUSE_TYPE; // 150000050
      case 'PAYMENT_DAYS':
        return FIELD_TEMPLATE.TIMBER_PAYMENT_DAYS; // 150000051
      case 'TIMBER_LOT_COST':
        return FIELD_TEMPLATE.TIMBER_LOT_COST; // 150000053
      case 'CONSTANTE_K':
        return FIELD_TEMPLATE.NUMBER; // 150000061
      case 'RECHNUNGSNUMMER':
        return FIELD_TEMPLATE.TEXT; // 150000062
      case 'RECHNUNGSDATUM':
        return FIELD_TEMPLATE.DATE; // 150000063
      case 'RECHNUNGSSUMME':
        return FIELD_TEMPLATE.NUMBER; // 150000064
      case 'ART_DER_ARBEITEN':
        return FIELD_TEMPLATE.TEXT; // 150000065
      case 'CONTRACT_AWARD_COST':
        return FIELD_TEMPLATE.NUMBER; // 150000066
      default:
        return '';
    }
  }

  tipologiaRimborsoChange(e: any) {
    const infrannualeFields: string[] = [
      'ECCEDENZA_IMPOSTA_CHIESTA_A_RIMBORSO_INFRANNUALE',
      'INTERESSI_DAL_TERMINE_DI_PAGAMENTO',
      'INTERESSI_SULL_ECCEDENZA_IMPOSTA_RIMBORSO_INFRANNUALE',
      'ANNO_DICHIARAZIONE_INFRANNUALE',
      'PERIODO_RIMBORSO_INFRANNUALE',
    ];

    const annualeDateFields: string[] = ['DATA_PRESENTAZIONE_DICHIARAZIONE'];

    this.bond.customFields.forEach((t) => {
      if (annualeDateFields.includes(t.fieldType.code)) {
        t.value =
          e.target.value === this.TIPOLOGIA_RIMBORSO_ANNUALE
            ? this.formatService.formatDate(new Date())
            : this.NOT_REQUESTED;
      }
      if (infrannualeFields.includes(t.fieldType.code)) {
        t.value = e.target.value === this.TIPOLOGIA_RIMBORSO_INFRANNUALE ? '' : this.NOT_REQUESTED;
      }
    });
    this.table.resetControls();
  }

  tipologiaProceduraChange(e: any) {
    const semplificataFields: string[] = ['INTERESSI'];

    const ordinariaFields: string[] = ['INTERESSI_DA_NOVANTESIMO_GIORNO', 'INTERESSI_SU_ECCEDENZA_IMPOSTA'];

    this.bond.customFields.forEach((t) => {
      if (semplificataFields.includes(t.fieldType.code)) {
        t.value = e.target.value === this.TIPOLOGIA_PROCEDURA_SEMPLIFICATA ? '' : this.NOT_REQUESTED;
      }

      if (ordinariaFields.includes(t.fieldType.code)) {
        t.value = e.target.value === this.TIPOLOGIA_PROCEDURA_ORDINARIA ? '' : this.NOT_REQUESTED;
      }
    });
    this.table.resetControls();
  }

  bePerformanceReleaseTypeChange(e: any) {
    const endDate1Field: string[] = ['BE_PERFORMANCE_END_DATE_1'];
    const endDate2Field: string[] = ['BE_PERFORMANCE_END_DATE_2'];

    this.bond.customFields.forEach((t) => {
      if (e.target.value === this.RELEASE_TYPE_100) {
        if (endDate1Field.includes(t.fieldType.code)) {
          t.value = this.formatService.formatDate(new Date());
        }
        if (endDate2Field.includes(t.fieldType.code)) {
          t.value = this.NOT_REQUESTED_EN;
        }
      }

      if (e.target.value === this.RELEASE_TYPE_50_50) {
        if (endDate1Field.includes(t.fieldType.code)) {
          t.value = this.formatService.formatDate(new Date());
        }
        if (endDate2Field.includes(t.fieldType.code)) {
          t.value = this.formatService.formatDate(new Date());
        }
      }

      if (e.target.value === this.RELEASE_TYPE_OTHER) {
        if (endDate1Field.includes(t.fieldType.code)) {
          t.value = this.NOT_REQUESTED_EN;
        }
        if (endDate2Field.includes(t.fieldType.code)) {
          t.value = this.NOT_REQUESTED_EN;
        }
      }
    });

    this.table.resetControls();
  }

  recalculateDamageClause(clear: boolean) {
    const damageClauseType = this.getDamageClauseTypeCustomField();
    if (damageClauseType) {
      if (damageClauseType.value === this.DAMAGE_CLAUSE_20_PERCENT.value) {
        this.getDamageClauseCustomField().value = this.calculateDamageClauseValue();
        this.damageClauseDisabled = true;
      }
      if (clear && damageClauseType.value === this.DAMAGE_CLAUSE_FREE.value) {
        this.getDamageClauseCustomField().value = undefined;
        this.damageClauseDisabled = false;
      }
      if (damageClauseType.value === this.NO_DAMAGE_CLAUSE.value) {
        this.getDamageClauseCustomField().value = '0';
        this.damageClauseDisabled = true;
      }
    }
    this.damageClauseChange.emit();
    if (this.table) {
      this.table.resetControls();
    }
  }

  calculateDamageClauseValue() {
    const timberLotCost = this.getTimberLotCostCustomFieldValue();
    return timberLotCost ? Math.min(timberLotCost * 0.2, this.DAMAGE_CLAUSE_DEFAULT_VALUE) + '' : undefined;
  }

  getDamageClauseTypeCustomField() {
    return this.bond.customFields.find((t) => t.fieldType.id === BondCustomFieldType.DAMAGE_CLAUSE_TYPE);
  }

  getDamageClauseCustomField() {
    return this.bond.customFields.find((t) => t.fieldType.id === BondCustomFieldType.DAMAGE_CLAUSE);
  }

  getTimberLotCostCustomFieldValue(): number {
    const timberLotCostCustomField = this.bond.customFields.find(
      (t) => t.fieldType.id === BondCustomFieldType.TIMBER_LOT_COST
    );
    return this.getNumberValue(timberLotCostCustomField);
  }

  getPaymentTermCustomFieldValue(): number {
    const paymentDaysCustomField = this.bond.customFields.find(
      (t) => t.fieldType.id === BondCustomFieldType.PAYMENT_DAYS
    );
    return this.getNumberValue(paymentDaysCustomField);
  }

  getNumberValue(customField: BondCustomFieldValueDto): number {
    let value = customField?.value;
    if (typeof value === 'string') {
      value = value.replace(',', '.');
      value = value.replace(' ', '');
    }
    return Number(value);
  }

  isBePerformanceEndDate1Blocked() {
    return this.checkSelectionValue('BE_PERFORMANCE_RELEASE_TYPE', this.RELEASE_TYPE_OTHER);
  }

  isBePerformanceEndDate2Blocked() {
    return (
      this.checkSelectionValue('BE_PERFORMANCE_RELEASE_TYPE', this.RELEASE_TYPE_OTHER) ||
      this.checkSelectionValue('BE_PERFORMANCE_RELEASE_TYPE', this.RELEASE_TYPE_100)
    );
  }

  isTipologiaAnnuale() {
    return this.checkSelectionValue('TIPOLOGIA_RIMBORSO', this.TIPOLOGIA_RIMBORSO_ANNUALE);
  }

  isProdecuraSemplificata() {
    return this.checkSelectionValue('TIPOLOGIA_PROCEDURA', this.TIPOLOGIA_PROCEDURA_SEMPLIFICATA);
  }

  checkSelectionValue(fieldTypeCode: string, valueToCheck: string) {
    return valueToCheck === this.bond.customFields.find((item) => item.fieldType.code === fieldTypeCode).value;
  }

  iscrizioneAlboChange(e: any) {
    const iscrizioneAlbo: string[] = ['NUMERO_ISCRIZIONE_ALBO_AUTOTRASPORTATORI', 'NUMERO_ISCRIZIONE_REN'];

    this.bond.customFields.forEach((t) => {
      if (iscrizioneAlbo.includes(t.fieldType.code)) {
        t.value = e.target.value === this.YES_IT ? '' : this.NOT_REQUESTED;
      }
    });
    this.table.resetControls();
  }

  isIscrittoAlbo() {
    return this.checkSelectionValue('ISCRITTO_ALBO_AUTOTRASPORTATORI', this.YES_IT);
  }

  iscrizioneRegistroChange(e: any) {
    const iscrizioneRegistro: string[] = ['ISCRIZIONE_REGISTRO_IMPRESE_CITTA_N'];

    this.bond.customFields.forEach((t) => {
      if (iscrizioneRegistro.includes(t.fieldType.code)) {
        t.value = e.target.value === this.YES_IT ? '' : this.NOT_REQUESTED;
      }
    });
    this.table.resetControls();
  }

  isIscrittoRegistro() {
    return this.checkSelectionValue('ISCRITTO_REGISTRO_IMPRESE', this.YES_IT);
  }

  dateOfAuctionValidityChange(e: any) {
    this.bond.bondValidTo = DateUtils.addMonth(e, this.DATE_OF_AUCTION_MONTHS);
  }

  onTimberPaymentDaysChange(item, event) {
    item.value = event;
    this.timberPaymentDaysChange.emit();
  }
}

enum FIELD_TEMPLATE {
  TEXT = 'TEXT_T',
  NUMBER = 'NUMBER_T',
  DATE = 'DATE_T',
  DISABLED = 'DISABLED_T',
  BOOLEAN_SI_NO = 'BOOLEAN_SI_NO_T',
  PERIODO_DICHIARAZIONE = 'PERIODO_DICHIARAZIONE_T',
  TIPOLOGIA_RIMBORSO = 'TIPOLOGIA_RIMBORSO_T',
  YEAR = 'YEAR_T',
  TIPOLOGIA_PROCEDURA = 'TIPOLOGIA_PROCEDURA_T',
  PERIODO_RIMBORSO_INFRANNUALE = 'PERIODO_RIMBORSO_INFRANNUALE_T',
  DATE_PRESENTAZIONE_DICHIARAZIONE = 'DATE_PRESENTAZIONE_DICHIARAZIONE_T',
  INTERESSI_SEMPLIFICATA = 'INTERESSI_SEMPLIFICATA_T',
  INTERESSI_ORDINARIA = 'INTERESSI_ORDINARIA_T',
  YEAR_INFRANNUALE = 'YEAR_INFRANNUALE_T',
  NUMBER_INFRANNUALE = 'NUMBER_INFRANNUALE_T',
  TEXTAREA = 'TEXTAREA_T',
  ISCRITTO_ALBO_SI_NO = 'ISCRITTO_ALBO_SI_NO_T',
  TEXT_ISCRIZIONE_ALBO = 'TEXT_ISCRIZIONE_ALBO_T',
  ISCRITTO_REGISTRO_IMPRESE_SI_NO = 'ISCRITTO_REGISTRO_IMPRESE_SI_NO_T',
  TEXT_REGISTRO_IMPRESE = 'TEXT_REGISTRO_IMPRESE_T',
  DATE_OF_AUCTION = 'DATE_OF_AUCTION_T',
  BE_PERFORMANCE_TYPE = 'BE_PERFORMANCE_TYPE_T',
  BE_PERFORMANCE_RELEASE_DATE = 'BE_PERFORMANCE_RELEASE_DATE_T',
  BE_PERFORMANCE_RELEASE_TYPE = 'BE_PERFORMANCE_RELEASE_TYPE_T',
  BE_PERFORMANCE_END_DATE_1 = 'BE_PERFORMANCE_END_DATE_1_T',
  BE_PERFORMANCE_END_DATE_2 = 'BE_PERFORMANCE_END_DATE_2_T',
  BE_PUBLIC_BENEFICIARY = 'BE_PUBLIC_BENEFICIARY_Y_N',
  TIMBER_LOT_COST = 'TIMBER_LOT_COST_T',
  TIMBER_PAYMENT_DAYS = 'TIMBER_PAYMENT_DAYS_T',
  DAMAGE_CLAUSE_TYPE = 'DAMAGE_CLAUSE_TYPE_T',
  DAMAGE_CLAUSE = 'DAMAGE_CLAUSE_T',
}

export enum ReleaseTypeCustomField {
  BE_PERFORMANCE_RELEASE_TYPE = 'BE_PERFORMANCE_RELEASE_TYPE',
  RELEASE_TYPE_OTHER = 'Other (explain mechanism in the comment box)',
}
