import {Component, Input, ViewChild} from '@angular/core';
import {
  ATableComponent,
  BondingContractInquiryCompanyDto,
  BondingContractInquiryVersionCoreDto,
  BondingContractInquiryVersionDto,
  BondingExternalContractDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  GrowlService,
  LoggedUserService,
  ProductSingleLimitDto,
  RouterService,
} from '../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  BondingExternalContractType,
  BondType,
  ContractCompanyRole,
  ContractType,
  DictionaryProfile,
} from '../../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../../bonding_shared/components/details-view/list-emitters';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {NumberUtils} from '../../../../bonding_shared/utils/number-utils';

@Component({
  selector: 'bonding-contract-inquiry-product',
  templateUrl: './bonding-contract-inquiry-product.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryProductComponent {
  @Input() showErrors = false;
  @Input() multiProduct = false;
  @Input() readonly = false;
  listEmitters: ListEmitters = new ListEmitters();
  _product: BondingContractInquiryVersionDto | BondingContractInquiryVersionCoreDto;
  bondCurrencyDictProfile = DictionaryProfile.KUKE_BOND_CURRENCY;
  ContractType = ContractType;

  @ViewChild('contractCompanies', {static: true})
  contractCompaniesTable: ATableComponent<BondingContractInquiryCompanyDto>;

  selectors: ListEmitters = new ListEmitters();
  hiddenCompanyRoles = new Set<number>([ContractCompanyRole.MAIN_CLIENT]);

  @Input() set product(value: BondingContractInquiryVersionDto | BondingContractInquiryVersionCoreDto) {
    this._product = value;
  }

  get product() {
    return this._product;
  }

  constructor(
    private loggedUserService: LoggedUserService,
    public router: RouterService,
    private growlService: GrowlService
  ) {
    this.selectors.initializeSelectorEmittersByNames(true, ['company']);
  }

  get productTypeId() {
    return this.multiProduct
      ? this.product.multiProductType.id
      : (<BondingContractInquiryVersionDto>this.product).inquiry.productType.id;
  }

  onBondTypeChange() {
    console.log('update bond type');
    this.updateExternalContractVisibility(
      [
        BondType.DELIVERY_PAYMENT,
        BondType.KUKE_DELIVERY_PRODUCTION_PAYMENT,
        BondType.DELIVERY_PAYMENT_ENERGETIC,
        BondType.KUKE_DELIVERY_PRODUCTION_PAYMENT_ENERGETIC,
      ],
      BondingExternalContractType.DELIVERY
    );
    this.updateExternalContractVisibility(
      [BondType.CREDIT_PAYMENT, BondType.CREDIT_PAYMENT_ENERGETIC],
      BondingExternalContractType.CREDIT
    );
    this.updateExternalContractVisibility(
      [
        BondType.FACTORING_PAYMENT,
        BondType.CREDIT_PAYMENT_FACTORING,
        BondType.FACTORING_PAYMENT_ENERGETIC,
        BondType.DELIVERY_CHAIN_PAYMENT_ENERGETIC,
      ],
      BondingExternalContractType.FACTORING
    );
  }

  updateExternalContractVisibility(bondTypIds: number[], externalContractTypeId: number) {
    if (this.product.bondTypes.find((t) => t.bondType && bondTypIds.includes(t.bondType.id))) {
      this.addExternalContractIfMissing(externalContractTypeId);
    } else {
      this.removeExternalContractIfExists(externalContractTypeId);
    }
  }

  removeExternalContractIfExists(externalContractTypeId: number) {
    const externalContract = this.getExternalContract(externalContractTypeId);
    if (externalContract) {
      NumberUtils.deleteItemFirstOccurrence(this.product.externalContracts, externalContract);
    }
  }

  addExternalContractIfMissing(externalContractTypeId: number) {
    if (!this.getExternalContract(externalContractTypeId)) {
      const externalContract = <BondingExternalContractDto>{};
      externalContract.type = <DictionaryBaseDto>{id: externalContractTypeId};
      this.product.externalContracts.push(externalContract);
    }
  }

  showValidTo() {
    return (
      this.productTypeId === ContractType.GSP_INDIVIDUAL_CONTRACT ||
      this.productTypeId === ContractType.GSP_CREDIT_PAYMENT ||
      this.productTypeId === ContractType.GSP_GENERAL_PAYMENT ||
      this.productTypeId === ContractType.GSP_INDIVIDUAL_PAYMENT
    );
  }

  showRequestTypeSection() {
    return (
      this.productTypeId === ContractType.GSP_GENERAL_PAYMENT ||
      this.productTypeId === ContractType.GSP_INDIVIDUAL_PAYMENT ||
      this.productTypeId === ContractType.GSP_INDIVIDUAL_CONTRACT
    );
  }

  get portal() {
    return this.loggedUserService.portal;
  }

  get singleLimit(): ProductSingleLimitDto {
    // only BondingContractInquiryVersionDto has inquiry
    if (this.product['inquiry']) {
      return this.product['inquiry'].singleLimit;
    }
    return undefined;
  }

  get decisionOutsideInsurer(): boolean {
    // only BondingContractInquiryVersionDto has decisionOutsideInsurer
    return this.portal && this.product['decisionOutsideInsurer'];
  }

  getExternalContract(externalContractTypeId: number): BondingExternalContractDto {
    return this.product.externalContracts.find((c) => c.type.id === externalContractTypeId);
  }

  get showConsortia() {
    return BusinessUtils.isBondConsortiaSectionVisible(this.productTypeId);
  }

  get showRelatedCompanies() {
    return (
      this.product &&
      [ContractType.GSP_INDIVIDUAL_CONTRACT, ContractType.GSP_INDIVIDUAL_PAYMENT].includes(this.productTypeId)
    );
  }

  get showBondTypes() {
    return this.product && BusinessUtils.isBondTypesSectionVisible(this.productTypeId);
  }

  onSelectClient(company: CompanySimpleDto) {
    if (company) {
      for (const c of this.product.contractCompanies) {
        if (c.company.id === company.id) {
          this.growlService.warning('Company already added!');
          return;
        }
      }
      this.contractCompaniesTable.onAddItem(this.createContractCompany(company));
    }
  }

  createContractCompany(c: CompanySimpleDto): BondingContractInquiryCompanyDto {
    const cc = <BondingContractInquiryCompanyDto>{};
    cc.company = c;
    cc.role = <DictionaryBaseDto>{id: ContractCompanyRole.CLIENT, name: 'Client'};
    return cc;
  }
}
