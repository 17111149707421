import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'marketing-email-consent',
  template: `
    <div class="row flex">
      <!-- 7* -->
      <checkbox-entity
        class="start"
        name="marketingEmailConsent"
        [(ngModel)]="consents.marketingEmailConsent"
        [readonly]="readonly"
      ></checkbox-entity>
      <p>
        Wyrażam zgodę na otrzymywanie od KUKE S.A. na podany we Wniosku o ubezpieczenie adres e-mail informacji
        handlowych i marketingowych oraz materiałów merytorycznych (np. informacji, publikacji, analiz) drogą
        elektroniczną zgodnie z ustawą z dnia 18.07.2002 r. o świadczeniu usług drogą elektroniczną.
      </p>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class MarketingEmailConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
