import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  BondVersionCriteriaDto,
  ClaimIndemnificationVersionCriteriaDto,
  ClaimVersionCriteriaDto,
  ClientCompanyBaseDto,
  CollectionSimpleDto,
  CompanyCriteriaDto,
  DictionaryBaseDto,
  LocalDateRange,
  MasterPolicyContractBaseDto,
  PolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  ProductLimitListBaseDto,
  ProductLimitListVersionCriteriaDto,
} from '../../../bonding_shared/model';
import {SectionSelector} from '../../../bonding_shared/components/details-view/section-selector';
import {
  ClaimIndemnificationStatus,
  ClaimRiskType,
  CollectionType,
  POLICY_CONTRACT_ACTIVATED_STATUSES,
  ProductLimitListVersionStatus,
} from '../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  CacheService,
  LoggedUserService,
  PolicyContractService,
  RouterService,
  SettableObjectType,
  View,
} from '../../../bonding_shared/services';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {SectionSelectorsComponent} from '../../../bonding_shared/components/details-view/section-selectors.component';

@Component({
  selector: 'collection-selectors',
  templateUrl: 'collection-selectors.component.pug',
})
export class CollectionSelectorsComponent {
  @ViewChild('selectors', {static: true}) selectors: SectionSelectorsComponent;
  _parent: CollectionSimpleDto;
  @Input() set parent(parent: CollectionSimpleDto) {
    this._parent = parent;
    if (this._parent) {
      if (this._parent.policy) {
        this.setPhysicalPersonFlags(this._parent.policy.id);
      }
      if (this._parent.creditor) {
        this.policyCriteria = this.getPolicyCriteria();
      }
    }
  }
  get parent() {
    return this._parent;
  }
  @Output() allSelectorsSet = new EventEmitter<CollectionSimpleDto>();

  claimCriteria = <ClaimVersionCriteriaDto>{};
  bondCriteria = <BondVersionCriteriaDto>{bond: {}};
  indemnificationCriteria = <ClaimIndemnificationVersionCriteriaDto>{};
  policyCriteria = <PolicyContractVersionCriteriaDto>{
    oneVersionForMaster: true,
    versionValidFrom: <LocalDateRange>{dateTo: new Date()},
    statusIds: POLICY_CONTRACT_ACTIVATED_STATUSES,
  };
  creditorCriteria = <CompanyCriteriaDto>{clientCompany: <ClientCompanyBaseDto>{}};
  debtorCriteria = <CompanyCriteriaDto>{clientCompany: <ClientCompanyBaseDto>{}};
  thirdPartyCriteria = <CompanyCriteriaDto>{};
  policyLimitSearchCriteria = <ProductLimitListVersionCriteriaDto>{
    last: true,
    limitList: <ProductLimitListBaseDto>{masterPolicyContract: <MasterPolicyContractBaseDto>{}},
    status: {id: ProductLimitListVersionStatus.ACTIVATED},
  };
  showPhysicalPersonCriteria = false;

  readonly CollectionType = CollectionType;
  readonly View = View;

  constructor(
    private loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    private policyContractService: PolicyContractService,
    public router: RouterService,
    private cacheService: CacheService
  ) {}

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  selectorChooser = (allSelectors: SectionSelector[]): SectionSelector[] => {
    const selectors: (keyof CollectionSimpleDto)[] = ['type'];
    if (this.parent.type) {
      switch (this.parent.type.id) {
        case CollectionType.RECOVERY_CREDIT_INSURANCE:
          if (this.isInitialized()) {
            selectors[1] = 'creditor';
            selectors[2] = 'claim';
            selectors[3] = 'claimIndemnificationVersion';
            selectors[4] = 'claimCollection';
            selectors[5] = 'debtor';
          } else {
            selectors[1] = 'claim';
            selectors[2] = 'claimIndemnificationVersion';
          }
          break;
        case CollectionType.RECOVERY_BONDING:
          if (this.isInitialized()) {
            selectors[1] = 'creditor';
            selectors[2] = 'debtor';
            selectors[3] = 'claim';
            selectors[4] = 'claimIndemnificationVersion';
            selectors[5] = 'claimContractVersion';
            selectors[6] = 'claimBond';
          } else {
            selectors[1] = 'claim';
            selectors[2] = 'claimIndemnificationVersion';
          }
          break;
        case CollectionType.DUE_PREMIUMS:
          selectors[1] = 'businessUnit';
          selectors[2] = 'debtor';
          break;
        case CollectionType.CREDIT_INSURANCE_BUYER_WITH_LIMIT:
        case CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER_PLUS_LIMIT:
          if (this.isInitialized() && this.portal && this.parent.id > 0) {
            selectors[1] = 'creditor';
            selectors[2] = 'policy';
            selectors[3] = 'debtor';
          } else {
            selectors[1] = 'creditor';
            selectors[2] = 'policy';
            selectors[3] = 'policyLimit';
            selectors[4] = 'debtor';
          }
          break;
        case CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER:
          if (this.isInitialized() && this.portal && this.parent.id > 0) {
            selectors[1] = 'creditor';
            selectors[2] = 'policy';
            if (this.parent.debtor && this.parent.debtor.id > 0) {
              selectors[3] = 'debtor';
            } else {
              selectors[3] = 'thirdParty';
            }
          } else {
            selectors[1] = 'creditor';
            selectors[2] = 'policy';
            selectors[3] = 'policyLimit';
            if (this.parent.debtor && this.parent.debtor.id > 0) {
              selectors[4] = 'debtor';
            } else {
              selectors[4] = 'thirdParty';
            }
          }
          break;
      }
    }
    return selectors.map((prop) => allSelectors.find((x) => x.property === prop));
  };

  getPolicyCriteria(): PolicyContractVersionCriteriaDto {
    return <PolicyContractVersionCriteriaDto>{
      oneVersionForMaster: true,
      linkedCompanyId: this.parent.creditor && this.parent.creditor.id,
      hasNnLimit: this.getNnLimitCriteria(),
      versionValidFrom: <LocalDateRange>{dateTo: new Date()},
      statusIds: POLICY_CONTRACT_ACTIVATED_STATUSES,
    };
  }

  getNnLimitCriteria() {
    if (this.parent.type && CollectionType.NN_TYPES.includes(this.parent.type.id)) {
      return true;
    }
    // false and undefined have different meaning
    return undefined;
  }

  getClaimCriteria(): ClaimVersionCriteriaDto {
    if (!this.parent.type) {
      return <ClaimVersionCriteriaDto>{};
    }
    const riskTypeId =
      this.parent.type.id === CollectionType.RECOVERY_CREDIT_INSURANCE
        ? ClaimRiskType.CREDIT_INSURANCE
        : ClaimRiskType.SURETY;
    return <ClaimVersionCriteriaDto>{
      last: true,
      claim: {riskType: {id: riskTypeId}},
    };
  }

  getIndemnificationCriteria(): ClaimIndemnificationVersionCriteriaDto {
    if (!this.parent.claim) {
      return <ClaimIndemnificationVersionCriteriaDto>{};
    }
    return <ClaimIndemnificationVersionCriteriaDto>{
      indemnificationNotUsedInCollection: true,
      last: true,
      status: <DictionaryBaseDto>{id: ClaimIndemnificationStatus.PAID},
      claimId: this.parent.claim.id,
    };
  }

  isInitialized(): boolean {
    return this.isDuePremiums() ? !!this.parent.creditor : !!this.parent.businessUnit;
  }

  getDebtorCriteria(): CompanyCriteriaDto {
    const crit = this.getThirdPartyCriteria();
    if (!this.isDuePremiums()) {
      crit.isLimitBuyerForMasterPolicyId = !!this.parent.policy ? this.parent.policy.masterPolicyContract.id : null;
      crit.limitListId = !!this.parent.policyLimit ? this.parent.policyLimit.id : (crit.limitListId = null);
    }
    return crit;
  }

  onTypeChange(type: DictionaryBaseDto) {
    this.router.toCollectionDetailsNew({typeId: type.id});
  }

  onPolicySelected(policy: PolicyContractBaseDto) {
    this.policyLimitSearchCriteria.limitList.masterPolicyContract.id = policy.masterPolicyContract.id;
    if (this.parent.type && this.parent.type.id === CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER) {
      this.policyLimitSearchCriteria.limitList.main = true;
    }
    if (this.appConfigService.kuke) {
      this.setPhysicalPersonFlags(policy.id);
    }
    this.parent.policyLimit = null;
  }

  setPhysicalPersonFlags(policyId: number) {
    this.policyContractService.hasAnyClauseType(policyId, BusinessUtils.LIMIT_FARMER_CLAUSES).subscribe((result) => {
      this.showPhysicalPersonCriteria = result;
    });
  }

  getThirdPartyCriteria(): CompanyCriteriaDto {
    const crit = <CompanyCriteriaDto>{address: {}, physicalPerson: {}};
    if (this.parent.creditor) {
      crit.clientCompany = <ClientCompanyBaseDto>{client: {id: this.parent.creditor.id}};
    }
    return crit;
  }

  isDuePremiums(): boolean {
    return this.parent.type && this.parent.type.id === CollectionType.DUE_PREMIUMS;
  }

  get isRecovery(): boolean {
    return this.parent?.type && CollectionType.RECOVERY_TYPES.includes(this.parent?.type?.id);
  }

  setPolicyCriteriaAndCloseSelectors(): void {
    this.policyCriteria = this.getPolicyCriteria();
    this.selectors.closeSelector('policy');
    this.selectors.closeSelector('policyLimit');
    this.selectors.closeSelector('debtor');
    this.selectors.closeSelector('thirdParty');
  }

  setupCriteria(): void {
    this.thirdPartyCriteria = this.getThirdPartyCriteria();
    this.debtorCriteria = this.getDebtorCriteria();
    this.policyCriteria = this.getPolicyCriteria();
    this.claimCriteria = this.getClaimCriteria();
    this.indemnificationCriteria = this.getIndemnificationCriteria();

    this.cacheService.collection = this.parent;
  }

  internalNumberClicked() {
    this.cacheService.collection = this.parent;
  }

  createNewCompany() {
    this.cacheService.collection = this.parent;
    this.router.toCompanyDetailsNew(View.COLLECTION_DETAILS_NEW.id, SettableObjectType.COLLECTION_DEBTOR);
  }

  get creditorSectionLabel() {
    return CollectionType.RECOVERY_TYPES.includes(this.parent?.type?.id) ? 'collection.insurer' : 'collection.creditor';
  }

  get debtorSectionLabel() {
    return this.parent?.type?.id === CollectionType.RECOVERY_BONDING ? 'collection.bondingDebtor' : 'collection.debtor';
  }

  get kukePortal(): boolean {
    return this.kuke && this.portal;
  }

  get kuke(): boolean {
    return this.appConfigService.kuke;
  }

  onCompanySelected() {
    this.parent.policy = null;
    this.parent.policyLimit = null;
    this.setupCriteria();
  }
}
