import {Injectable} from '@angular/core';
import {AbstractService} from '../../../bonding_shared/services/abstract.service';
import {AppConfigService} from '../../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {Observable} from 'rxjs';
import {
  ContractBondExtendedDto,
  SearchResult,
  SumAssuredAfterDisbursementsDto,
} from '../../../bonding_shared/model/dtos';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class ContractBondPortalService extends AbstractService {
  protected url = this.urlPrefix + 'portal/contractBond';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getContractBonds(contractVersionId: number): Observable<SearchResult<ContractBondExtendedDto>> {
    return this.get<SearchResult<ContractBondExtendedDto>>(this.url + '?contractVersionId=' + contractVersionId);
  }

  @Cacheable({maxCacheCount: 1, maxAge: 60000}) // login param used only by cache. DO NOT REMOVE!
  public getSumAssuredAfterDisbursements(login: string): Observable<SumAssuredAfterDisbursementsDto[]> {
    return this.get<SumAssuredAfterDisbursementsDto[]>(this.url + '/sumAssuredAfterDisbursements');
  }
}
