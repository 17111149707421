import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'data-security-notice',
  template: `
    <div class="row">
      <!-- 30! -->
      <p>
        Zgodnie z postanowieniami art. 35 Ustawy z dnia 11 września 2015 r. o działalności ubezpieczeniowej i
        reasekuracyjnej (Dz. U. 2015 poz. 1844) powyższe dane i informacje objęte są ochroną w zakresie wynikającym z
        wymienionego przepisu.
      </p>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class DataSecurityNoticeComponent {}
