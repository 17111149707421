import {Component} from '@angular/core';
import {AbstractConsentComponent} from '../abstract-consent.component';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {ControlContainer, NgModelGroup} from '@angular/forms';

@Component({
  selector: 'individual-contract-declarations-consent',
  template: `
    <div class="row flex">
      <!-- 20 (~11 GENERALNEJ/INDYWIDUALNEJ)-->
      <checkbox-entity
        class="start"
        name="individualContractDeclarations"
        [(ngModel)]="consents.individualContractDeclarations"
        [readonly]="readonly"
        [requiredTrue]="validate && true"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>
          Informujemy, iż w przypadku zawarcia UMOWY INDYWIDUALNEJ O UDZIELANIE GWARANCJI, KUKE S.A. jest zobowiązana do
          uzyskania od Wnioskodawcy następujących oświadczeń dotyczących kontraktu eksportowego, do którego będą
          udzielane gwarancje:
        </p>
        <ul>
          <li>oświadczenia w sprawie przekupstwa,</li>
          <li>
            w zakresie i na zasadach wskazanych w ustawie – oświadczenia w sprawie krajowego pochodzenia produktu lub
            usługi eksportowanych w ramach kontraktu eksportowego.
          </li>
        </ul>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class IndividualContractDeclarationsConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
