import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {BondingExternalContractDto, BondVersionDto, DictionaryBaseDto} from '../../../bonding_shared/model';
import {LoggedUserService, RouterService} from '../../../bonding_shared/services';
import {BondingExternalContractType, BondType, ContractType} from '../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';

@Component({
  selector: 'bonding-external-contract',
  templateUrl: './bonding-external-contract.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingExternalContractComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() readOnly = false;
  @Input() bondVersion: BondVersionDto;
  @Input() bondType: DictionaryBaseDto; //  when initial bond is created bondVersion does not have type so it is injected separately, to fix that refactoring is needed

  _contract: BondingExternalContractDto;

  @Input() productTypeId: number;

  selectors: ListEmitters = new ListEmitters();
  ContractType = ContractType;
  BondingExternalContractType = BondingExternalContractType;

  constructor(public router: RouterService, private loggedUserService: LoggedUserService) {}

  consortiumClient = false;
  consortiumBeneficiary = false;

  @Input() set contract(value: BondingExternalContractDto) {
    this._contract = value;
  }

  get contract() {
    return this._contract;
  }

  ngOnInit(): void {}

  isGSPGeneralPaymentContract() {
    return this.productTypeId === ContractType.GSP_GENERAL_PAYMENT;
  }

  get subjectOfContractRequired() {
    return this.bondType && this.bondType.id === BondType.WADIUM_ON_LINE;
  }

  showGSPIndividualContractFields() {
    return this.productTypeId === ContractType.GSP_INDIVIDUAL_CONTRACT;
  }

  get portal() {
    return this.loggedUserService.portal;
  }

  label(key: string) {
    return 'bondingExternalContract.' + this.typeName + '.' + key;
  }

  get typeName() {
    switch (this.contract.type.id) {
      case BondingExternalContractType.GENERAL:
        return 'general';
      case BondingExternalContractType.DELIVERY:
        return 'delivery';
      case BondingExternalContractType.CREDIT:
        return 'credit';
      case BondingExternalContractType.FACTORING:
        return 'factoring';
      default:
        return '';
    }
  }

  get general() {
    return BondingExternalContractType.GENERAL === this.contract.type.id;
  }
  get delivery() {
    return BondingExternalContractType.DELIVERY === this.contract.type.id;
  }
  get credit() {
    return BondingExternalContractType.CREDIT === this.contract.type.id;
  }
  get factoring() {
    return BondingExternalContractType.FACTORING === this.contract.type.id;
  }
}
