import {Component, Input} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'data-compliance-consent',
  template: `
    <div class="row flex">
      <!-- 8* -->
      <checkbox-entity
        class="start"
        name="dataCompliance"
        [(ngModel)]="consents.dataCompliance"
        [readonly]="readonly"
        [requiredTrue]="validate && required"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>Niniejszym oświadczamy, że powyższe dane są zgodne ze stanem faktycznym.</p>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class DataComplianceConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {
  @Input() required: boolean;
}
