import {Component} from '@angular/core';
import {ControlContainer, FormControl, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'contributions-clearance-declaration-consent',
  template: `
    <div class="row flex">
      <!-- 3* -->
      <checkbox-entity
        #checkboxModel="ngModel"
        class="start"
        name="contributionsClearanceDeclaration"
        [(ngModel)]="consents.contributionsClearanceDeclaration"
        [requiredTrue]="validate && !consents.contributionsClearanceDeclarationDate"
        [presentationMode]="readonly"
        (ngModelChange)="updateValidity(datePickerModel.control)"
      ></checkbox-entity>
      <div>
        <p>
          Aktualne zaświadczenie z ZUS o braku zaległości w opłatach składek wystawione nie wcześniej niż 3 miesiące od
          momentu złożenia wniosku.
        </p>
        <error-message-simple
          [show]="showErrors"
          [invalid]="checkboxModel.invalid || datePickerModel.invalid"
          key="Dołącz zaświadczenie i zaznacz checkbox lub wypełnij poniższe oświadczenie."
        ></error-message-simple>
      </div>
      <hover-info
        color="blue"
        message="Dołącz zaświadczenie i zaznacz checkbox lub wypełnij poniższe oświadczenie."
      ></hover-info>
    </div>
    <div class="row">
      <!-- 4* -->
      <p>
        <span>„Niniejszym oświadczam/y, że na dzień</span>
        <date-picker
          #datePickerModel="ngModel"
          class="inner"
          name="contributionsClearanceDeclarationDate"
          [(ngModel)]="consents.contributionsClearanceDeclarationDate"
          [required]="validate && !consents.contributionsClearanceDeclaration"
          [presentationMode]="readonly"
          (ngModelChange)="updateValidity(checkboxModel.control)"
        ></date-picker>
        <span>
          nie posiadam/y zaległości wobec instytucji publicznoprawnych (ZUS). Będąc świadomym/mi odpowiedzialności
          karnej i cywilnej oświadczam/y, że wszystkie podane wyżej informacje są zgodne z aktualnym stanem faktycznym.”
        </span>
      </p>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class ContributionsClearanceDeclarationConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {
  updateValidity(control: FormControl) {
    setTimeout(() => control.updateValueAndValidity());
  }
}
