import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {HttpClient} from '@angular/common/http';
import {LoggedUserService} from './logged-user.service';
import {DatePipe} from '@angular/common';
import {DateUtils} from '../utils/date-utils';
import * as moment from 'moment';

@Injectable()
export class FormatService extends AbstractService {
  public readonly dateFormat;
  public readonly dateTimeFormat;

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    if (appConfigService.ecg) {
      this.dateFormat = 'dd/MM/yyyy';
      this.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
    } else {
      this.dateFormat = 'yyyy-MM-dd';
      this.dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
    }
  }

  public formatDate(date: Date): string {
    if (!DateUtils.isDateValid(date)) {
      return '';
    }
    return new DatePipe('en').transform(date, this.dateFormat);
  }

  public formatDateTime(date: Date): string {
    if (!date) {
      return '';
    }
    return new DatePipe('en').transform(date, this.dateTimeFormat);
  }

  public cutTimeZone(date: Date): Date {
    if (!date) {
      return undefined;
    }
    const dateStr = new DatePipe('en').transform(date, this.dateFormat);
    return moment.utc(dateStr).toDate();
  }
}
