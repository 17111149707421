import {Component, OnInit} from '@angular/core';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  ContractService,
  ContractTechnicalDetailsAttendantsDto,
  ContractTechnicalDetailsDto,
  GrowlService,
  LoggedUserService,
  RouterService,
  UserIdDto,
  UserService,
} from '../../../bonding_shared';
import {
  BondingElementaryRight,
  ElementaryRight,
  SalesRepElementaryRight,
} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'contract-technical-details',
  templateUrl: 'contract-technical-details.component.pug',
})
export class ContractTechnicalDetailsComponent extends DetailsView implements OnInit {
  public contractId: number;
  public technicalDetails: ContractTechnicalDetailsDto;
  public underwriters: UserIdDto[];
  public managers: UserIdDto[];

  readonly BondingElementaryRight = BondingElementaryRight;
  readonly SalesRepElementaryRight = SalesRepElementaryRight;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected growlService: GrowlService,
    private contractService: ContractService,
    private userService: UserService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.contractId = +params['contractId'];
    this.contractService.getTechnicalDetails(this.contractId).subscribe((data) => {
      this.technicalDetails = data;
      if (!this.technicalDetails.attendants) {
        this.technicalDetails.attendants = <ContractTechnicalDetailsAttendantsDto>{};
      }
      this.loadUsers(data.contract.businessUnit.id);
    });
  }

  loadUsers(businessUnitId: number) {
    this.userService.getBondingSupport(businessUnitId).subscribe((res) => {
      this.underwriters = res;
      this.managers = res;
    });
  }

  onSave() {
    this.contractService.updateTechnicalDetails(this.technicalDetails).subscribe({
      next: (data) => {
        this.technicalDetails = data;
        window.history.back();
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  onCancel() {
    super.onCancel(this.route);
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  public userHasAddRelationRight(): boolean {
    return (
      this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE) &&
      (this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_INDIVIDUAL_CONTRACT) ||
        this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_GENERAL_CONTRACT))
    );
  }
}
