import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'insurance-info-disclosure-consent',
  templateUrl: 'insurance-info-disclosure-consent.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class InsuranceInfoDisclosureConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
