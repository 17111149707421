import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContractSharedModule} from '../contract/components/shared/contract-shared.module';
import {CompanySharedModule} from '../../company/components/shared/company-shared.module';
import {BondingContractInquirySearchComponent} from './bonding-contract-inquiry-search.component';
import {BondingContractInquiryDetailsComponent} from './bonding-contract-inquiry-details.component';
import {BondingContractInquiryGuiService} from './services/bonding-contract-inquiry-gui.service';
import {CompanyExtendedSelectorModule} from '../../company/components/shared/extended-selectors/company-extended-selector.module';
import {BondingContractInquiryProductComponent} from './components/bonding-contract-inquiry-product';
import {BondingContractInquiryMultiProductComponent} from './components/bonding-contract-inquiry-multi-product';
import {TabViewModule} from 'primeng/tabview';
import {BondingContractInquiryTabClientInformationComponent} from './components/bonding-contract-inquiry-tab-client-information';
import {BondingContractInquiryTabSurveyComponent} from './components/bonding-contract-inquiry-tab-survey';
import {BondingContractInquirySectionClientInformationComponent} from './components/bonding-contract-inquiry-section-client-information';
import {BondingContractInquiryTabCollateralComponent} from './components/bonding-contract-inquiry-tab-collateral';
import {CustomFormModule} from '../../../common/custom-form/custom-form.module';
import {BondingContractInquiryTechnicalDetailsComponent} from './components/bonding-contract-inquiry-technical-details.component';
import {BusinessObjectInfoModule} from '../../business-object-info/business-object-info.module';
import {BrokerContractSharedModule} from '../../broker-contract/components/shared/broker-contract-shared.module';
import {SalesRepSharedModule} from '../../sales-rep/components/shared/sales-rep-shared.module';
import {BondingContractInquiryTabOfferComponent} from './components/bonding-contract-inquiry-tab-offer';
import {BondingContractOfferModule} from '../offer/bonding-contract-offer.module';
import {BondingContractCommonModule} from '../common/bonding-contract-common.module';
import {BondingContractInquiryTabMainKukeComponent} from './components/bonding-contract-inquiry-tab-main-kuke';
import {BondingContractInquiryTabMainCredendoComponent} from './components/bonding-contract-inquiry-tab-main-credendo';
import {BondingContractInquirySharedModule} from './components/shared/bonding-contract-inquiry-shared.module';
import {BondingContractInquiryService} from '../../../bonding_shared/services/bonding-contract-inquiry.service';
import {BondingContractInquiryDetailsGuard} from './_guards/bonding-contract-inquiry-details.guard';
import {BondingContractInquirySearchGuard} from './_guards/bonding-contract-inquiry-search.guard';
import {SurveyAnswersValidatorDirective} from './validators/survey-answers-validator.directive';
import {BondingConsentsModule} from '../../bonding-consents/bonding-consents.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BondingConsentsModule,
    ContractSharedModule,
    CompanyExtendedSelectorModule,
    TabViewModule,
    CustomFormModule,
    BusinessObjectInfoModule,
    BrokerContractSharedModule,
    SalesRepSharedModule,
    BondingContractOfferModule,
    BondingContractCommonModule,
    BondingContractInquirySharedModule,
  ],
  declarations: [
    BondingContractInquirySearchComponent,
    BondingContractInquiryDetailsComponent,
    BondingContractInquiryProductComponent,
    BondingContractInquiryMultiProductComponent,
    BondingContractInquiryTabClientInformationComponent,
    BondingContractInquiryTabMainKukeComponent,
    BondingContractInquiryTabMainCredendoComponent,
    BondingContractInquirySectionClientInformationComponent,
    BondingContractInquiryTabSurveyComponent,
    BondingContractInquiryTabCollateralComponent,
    BondingContractInquiryTabOfferComponent,
    BondingContractInquiryTechnicalDetailsComponent,
    SurveyAnswersValidatorDirective,
  ],
  exports: [],
  providers: [
    BondingContractInquiryGuiService,
    BondingContractInquiryService,
    BondingContractInquiryDetailsGuard,
    BondingContractInquirySearchGuard,
  ],
})
export class BondingContractInquiryModule {}
