import {Injectable} from '@angular/core';
import {
  AbstractService,
  AppConfigService,
  CompanyRatingVersionDto,
  LoggedUserService,
  ProposedCommissionMatrixDto,
} from '../../../../bonding_shared';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class BondingContractOfferProposedCommissionService extends AbstractService {
  private matrix: ProposedCommissionMatrixDto = null;
  private periodsSortedInt: number[];
  private ratingVersion: CompanyRatingVersionDto = null;
  private readonly notInitSubject = new Subject<void>();

  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + 'bondingContractOfferProposedCommission';

    this.getMatrix().subscribe({
      next: (m) => this.setMatrix(m),
      error: (error) => {
        console.warn('Proposed commission grid init error', error);
        this.setMatrix(undefined);
      },
      complete: () => void 0,
    });
  }

  @Cacheable({maxCacheCount: 2, maxAge: 60000})
  private getMatrix(): Observable<ProposedCommissionMatrixDto> {
    return this.get<ProposedCommissionMatrixDto>(this.url + '');
  }

  setRatingVersion(ratingVersion: CompanyRatingVersionDto) {
    this.ratingVersion = ratingVersion;
    this.runNotInitSubject();
  }

  private setMatrix(m) {
    if (m === null) {
      // Null from backend means error. On front undefined means error.
      m = undefined;
    }

    this.matrix = m;

    if (this.matrix) {
      this.periodsSortedInt = Object.keys(this.matrix.periodToMonthsIndex)
        .map((v) => +v)
        .sort((a, b) => a - b);
    }
    this.runNotInitSubject();
  }

  private runNotInitSubject() {
    const rv = this.ratingVersion;
    const matrix = this.matrix;

    if (rv && matrix) {
      this.notInitSubject.next();
    }
  }

  getProposedCommission(maxBondDuration: number): Observable<number> {
    const rv = this.ratingVersion;
    const matrix = this.matrix;

    if (rv === undefined || matrix === undefined || !maxBondDuration) {
      // error or param improper
      return EMPTY;
    }

    if (!rv || !matrix) {
      return new Observable((observer) => {
        this.notInitSubject.subscribe((_) =>
          this.getProposedCommission(maxBondDuration).subscribe((vv) => {
            observer.next(vv);
            observer.complete();
          })
        );
      });
    }

    const ratingIndex = matrix.ratingIndex[rv.rating.name];
    const durationIndex = this.getDurationIndex(maxBondDuration);
    if (durationIndex === null || typeof ratingIndex === 'undefined') {
      return EMPTY;
    }

    const durationPrices = matrix.values[ratingIndex];
    const val = durationPrices[durationIndex];
    return of(val * 100);
  }

  private getDurationIndex(maxBondDuration: number) {
    const foundIndex = this.periodsSortedInt.findIndex((e) => e >= maxBondDuration);
    if (foundIndex === -1) {
      return null;
    }
    const peridoBound = this.periodsSortedInt[foundIndex];
    return this.matrix.periodToMonthsIndex[peridoBound];
  }
}
