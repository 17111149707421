import {Component, Input} from '@angular/core';
import {BondingConsentsDto, DictionaryBaseDto} from '../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {BusinessObjectType, ContractType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonding-consents',
  templateUrl: './bonding-consents.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingConsentsComponent {
  @Input() boType:
    | BusinessObjectType.CONTRACT_VERSION
    | BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION
    | BusinessObjectType.BOND_VERSION;

  @Input() contractTypes: DictionaryBaseDto[];
  @Input() showErrors = false;
  @Input() consents: BondingConsentsDto;
  @Input() readonly = false;
  @Input() validate = true;

  constructor() {}

  public get isContract() {
    return this.boType === BusinessObjectType.CONTRACT_VERSION;
  }

  public get isContractInquiry() {
    return this.boType === BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION;
  }

  public get isBond() {
    return this.boType === BusinessObjectType.BOND_VERSION;
  }

  public get kom() {
    return !!this.contractTypes?.find((type) =>
      [ContractType.KOM_GENERAL_CONTRACT, ContractType.KOM_INDIVIDUAL_CONTRACT].includes(type.id)
    );
  }

  public get gsp() {
    return !!this.contractTypes?.find((type) =>
      [
        ContractType.GSP_GENERAL_CONTRACT,
        ContractType.GSP_INDIVIDUAL_CONTRACT,
        ContractType.GSP_GENERAL_PAYMENT,
        ContractType.GSP_INDIVIDUAL_PAYMENT,
      ].includes(type.id)
    );
  }

  public get general() {
    return !!this.contractTypes?.find((type) =>
      [
        ContractType.KOM_GENERAL_CONTRACT,
        ContractType.GSP_GENERAL_CONTRACT,
        ContractType.GSP_GENERAL_PAYMENT,
        ContractType.KOM_INDIVIDUAL_CONTRACT, // Weird as it may look, KOM_INDIVIDUAL_CONTRACT is actually general
      ].includes(type.id)
    );
  }

  public get individual() {
    return !!this.contractTypes?.find((type) =>
      [ContractType.GSP_INDIVIDUAL_CONTRACT, ContractType.GSP_INDIVIDUAL_PAYMENT].includes(type.id)
    );
  }
}
