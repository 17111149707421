import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondVersionConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'bank-references-consent',
  template: `
    <div class="row flex">
      <!-- 28! -->
      <checkbox-entity
        class="start"
        name="bankReferences"
        [(ngModel)]="consents.bankReferences"
        [readonly]="readonly"
      ></checkbox-entity>
      <p>
        Opinie banków prowadzących główny rachunek bankowy oraz rachunki banków prowadzących rachunki kredytowe
        Wnioskodawcy wystawione nie wcześniej niż 3 miesiące od momentu złożenia wniosku.
      </p>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class BankReferencesConsentComponent extends AbstractConsentComponent<BondVersionConsentsDto> {}
