import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {
  BondingContractInquiryVersionDto,
  MasterPolicyContractForLimitDto,
  LimitListVersionInDeclarationDto,
  ProductLimitListForLimitDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionDto,
  SearchCriteria,
  SearchResult,
  StateTransitionDto,
} from '../model';
import {Observable} from 'rxjs';
import {FormatService} from './format.service';

@Injectable()
export class PolicyLimitListService extends AbstractService {
  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private formatService: FormatService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'productLimitListVersion';
  }

  public initialVersion(masterPolicyId: number): Observable<ProductLimitListVersionDto> {
    return this.get<ProductLimitListVersionDto>(this.url + '/initialVersion/' + masterPolicyId);
  }

  public unlimitedSearchByCriteria(
    criteria: SearchCriteria<ProductLimitListVersionCriteriaDto>
  ): Observable<SearchResult<ProductLimitListVersionDto>> {
    return this.post1<SearchCriteria<ProductLimitListVersionCriteriaDto>, SearchResult<ProductLimitListVersionDto>>(
      criteria,
      this.url + '/unlimitedSearch'
    );
  }

  getMasterPolicy(masterPolicyId: number): Observable<MasterPolicyContractForLimitDto> {
    return this.get<MasterPolicyContractForLimitDto>(this.url + '/masterPolicy/' + masterPolicyId);
  }

  getListForLimit(listId: number): Observable<ProductLimitListForLimitDto> {
    return this.get<ProductLimitListForLimitDto>(this.url + '/listForLimit/' + listId);
  }

  getTransitions(bv: BondingContractInquiryVersionDto): Observable<Array<StateTransitionDto>> {
    if (bv && bv.status) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + bv.status.id);
    }
    return Observable.create([]);
  }

  listsForDeclarations(mpcNumber: string, activeOnRp: number): Observable<LimitListVersionInDeclarationDto[]> {
    return this.get<LimitListVersionInDeclarationDto[]>(
      this.url + '/listsForDeclarations?mpcNumber=' + mpcNumber + '&activeOnRp=' + activeOnRp
    );
  }

  listsForAmendments(mpcNumber: string, activeOnRp: number): Observable<LimitListVersionInDeclarationDto[]> {
    return this.get<LimitListVersionInDeclarationDto[]>(
      this.url + '/listsForAmendments?mpcNumber=' + mpcNumber + '&activeOnRp=' + activeOnRp
    );
  }

  listsForDeclarationsHistory(
    policyContractId: number,
    activeOnRp: number
  ): Observable<LimitListVersionInDeclarationDto[]> {
    return this.get<LimitListVersionInDeclarationDto[]>(
      this.url + '/listsForDeclarationsHistory?policyContractId=' + policyContractId + '&activeOnRp=' + activeOnRp
    );
  }
}
