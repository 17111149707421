import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'data-security-consent',
  template: `
    <div class="row flex">
      <!-- 22! -->
      <checkbox-entity
        class="start"
        name="dataSecurityDeclaration"
        [(ngModel)]="consents.dataSecurityConsent"
        [readonly]="readonly"
        [requiredTrue]="validate && true"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>
          Zgodnie z postanowieniami art. 35 Ustawy z dnia 11 września 2015 r. o działalności ubezpieczeniowej i
          reasekuracyjnej powyższe dane i informacje objęte są ochroną w zakresie wynikającym z wymienionego przepisu.
        </p>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class DataSecurityConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
