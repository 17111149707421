import {FeeUnit} from './dtos';

export enum ApplicationModule {
  BONDING = 43000001,
  POLICY = 43000002,
  LIMIT = 43000003,
  CLAIM = 43000004,
  ADMINISTRATION = 43000005,
  SALES = 43000006,
  FINANCE = 43000007,
  COLLECTION = 43000008,
  HR = 43000009,
}

export enum LimitDecisionCode {
  INS_9_09_FALCON_LIMIT_IMPOSSIBLE = 48000083,
}

export enum AppProperty {
  INVOICE_BROKER_NOTE_ASSIGN_TO_BU_ID = 'invoice.broker.note.assignToBuId',
  INVOICE_BROKER_NOTE_CORRECTION = 'invoice.broker.note.correction',
  INVOICE_BROKER_NOTE_PAYMENTS = 'invoice.broker.note.payments',
  INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS = 'invoice.broker.newBrokerItemRestrictions',
  INVOICE_PAYMENT_TYPE = 'invoice.paymentType',
  INVOICE_CORRECTION_REASON = 'invoice.correctionReason',
  INVOICE_COMMENT = 'invoice.comment',
  INVOICE_POLICY_MANUAL_MALUS_REQUEST = 'invoice.policy.manualMalusRequest',
  INVOICE_CONTRACT_SEND_ALL_INVOICES_AUTOMATICALLY = 'invoice.contract.sendAllInvoicesAutomatically',
  SYSTEM_CURRENCY = 'system.currency',
  SYSTEM_LANGUAGE = 'system.language',
  SYSTEM_SINGLE_CURRENCY_TABLE = 'system.singleCurrencyTable',
  SYSTEM_MULTI_CURRENCY_TABLE = 'system.multiCurrencyTable',
  BOTYPE_FOR_POLICIES = 'repository.boTypeForPolicies',
}

export enum Feature {
  BOND_SHOW_SUBTYPE = 'bond.showBondSubType',
  BROKER_CONTRACT_MANUAL_NUMBERING = 'brokerContract.manualNumbering',
  BROKER_CONTRACT_CONTACT_PERSON_MANDATORY = 'brokerContract.contactPersonMandatory',
  FILTER_TASK_BY_MODULE = 'filterTaskByModule',
  FILTER_CONTACT_REASON_BY_BUSINESS_OBJECT_TYPE = 'filterContactReasonByBusinessObjectType',
  SHOW_FLAG_IN_USER_LANGUAGE_SELECTOR = 'showFlagInUserLanguageSelector',
  SHOW_BOND_LINKED_CLAIMS = 'bond.showBondLinkedClaims',
  SHOW_BOND_PRODUCT_CODE = 'bond.showBondProductCode',
  COMPANY_SHOW_SHORT_NAME_IF_EXISTS = 'company.showShortNameIfExists',
  COMPANY_SHOW_STATUS_VALIDATION = 'company.showCompanyStatusValidation',
  MDG_CHANGE_IDENTIFIERS_ALLOWED = 'mDG.changeCompanyIdentifiersAllowed',
  COMPANY_USE_COMPANY_ROLES = 'company.useCompanyRoles',
  COMPANY_IMPORT_CONTACT = 'company.importContact',
  TASK_SEARCH_ALLOW_VERSIONS = 'taskSearchAllowBoVersions',
  FILTER_PORTAL_DOCS_BY_TYPE = 'filterPortalDocsByType',
  REPOSITORY_HEALTH_CHECK = 'repositoryHealthCheck',
  TURNOVER_ON_REGION_LEVEL = 'turnoverOnRegionLevel',
  USER_RESPONSIBLE_SEARCH_CRITERIA_VISIBLE = 'userResponsibleSearchCriteria.visible',
  USER_RESPONSIBLE_SEARCH_CRITERIA_INITIATED = 'userResponsibleSearchCriteria.initiated',
  COMPANY_HIDE_IN_PORTAL = 'company.hideInPortal',
  INTRANET_BOND_DETAILS_IN_PORTAL = 'intranetBondDetailsInPortal',
  DECLARATION_EXPORT_LIMITS_IN_PORTAL = 'declaration.exportLimitsInPortal',
  DECLARATION_HAS_OUTSTANDINGS_POLICIES = 'declaration.hasOutstandingsPolicies',
  DECLARATION_CAN_PORTAL_USER_DECLARE_AMENDMENT = 'declaration.canPortalUserDeclareAmendment',
  SHOW_CONTRACT_REPORT_BUTTON = 'contract.showContractReportButton',
  SHOW_LIMIT_HISTORY_BUTTON = 'declaration.showLimitHistoryButton',
  SHOW_BONDING_DECISIONS_SECTION = 'showBondingDecisionsSection',
}

export enum ProductLine {
  BONDING = 277000001,
  INSURANCE = 277000002,
  BROKER = 277000003,
}

export enum AmountUnit {
  ONE = 88000001,
  THOUSAND = 88000002,
  MILION = 88000003,
}

export enum BusinessPartnerType {
  DUMMY = 475000013,
}

export enum ClientStatus {
  CLIENT = 80000001,
  PROSPECT = 80000002,
  POTENTIAL = 80000003,
}

export enum CountryRatingSettingType {
  OECD = 462000001,
  INSURER = 462000002,
  ALL = 462000003,
}

export enum CustomField {
  // LimitDecisionCondition
  LDC_AMOUNT = 164000006,

  // PolicyClause - Falcon
  PC_FALCON_COUNTRY = 164000104,
  PC_FALCON_LIMIT_AMOUNT = 164000105,
  PC_FALCON_CURRENCY = 164000106,
  PC_FALCON_LIMIT_VALIDITY = 164000107,
  PC_FALCON_LOAN_PERIOD = 164000108,

  // PolicyClause - FLEXI
  PC_MULTILIST_FARMER_MAX_LIMIT_COUNT = 164000128,
  DEDUCTIBLE_AMOUNT = 164101013,
  LDC_INSURANCE_COVER = 164101009,
  LDC_DEDUCTIBLE_TYPE = 164101012,
}

export enum DataOwner {
  // Credendo
  MANUAL = 281000001,
  IMPORT = 281000002,
  AUTOMATIC = 281000003,
  // Kuke
  KUKE = 281000100,
}

export enum DictionaryProperty {
  BOND_VALUE_EDITABLE = 41000001,
  VAT_RATE_VALUE = 41000006,
  QUOTATION_MAX_TOTAL_LIABILITY = 41000007,
  QUOTATION_MAX_BOND_LIABILITY = 41000008,
  CLAIM_DOCUMENT_LETTER_ANSWER_ROW_NUMBER = 41000081,
  CLAIM_DOCUMENT_LETTER_ANSWER_ORDINAL_NUMBER = 41000082,
  CLAIM_DOCUMENT_LETTER_ANSWER_REFERENCE = 41000083,
}

export enum DictionaryProfile {
  BROKER = 134000001,
  DE_SPECIAL_BROKER = 134000002,
  LIMIT_DECISION_PERIOD = 134000003,
  LIMIT_MONITORING_PERIOD = 134000004,
  BOND_INVOICING_PERIOD = 134000005,
  COMPANY_FINANCIAL_DATA = 134000006,
  BE_SPECIAL_BROKER = 134000007,
  KUKE_USER = 134000008,
  PREMIUM_RETURN = 134000009,
  PREMIUM_RETURN_REQUEST = 134000010,
  BONDING = 134000011,
  POLICY = 134000012,
  LIMITS = 134000013,
  CLAIMS = 134000014,
  ADMINISTRATION = 134000015,
  SALES = 134000016,
  COLLECTION = 134000017,
  FINANCE = 134000018,
  DE_SPECIAL_BROKER_HBG = 134000019,
  INVOICE_FREQUENCY = 134000020,
  LEGAL_BUSINESS_OBJECT = 134000021,
  MEHIB_MAIN_CURRENCY = 134000022,
  MEHIB_INSURED_AMOUNT_CURRENCY = 134000033,
  CREDENDO_CLAIM_INDEMNIFICATION_CURRENCY = 134000023,
  KUKE_LEGAL_FORM = 134000024,
  HR_USER_ROLE = 134000025,
  POLISH_COMPANY_RATING_TYPE = 134000026,
  FOREIGN_COMPANY_RATING_TYPE = 134000027,
  KUKE_LIMIT_CURRENCY = 134000028,
  CLIENTDOCUMENT = 134000029,
  PORTAL_COUNTRY = 134000030,
  MEHIB_BASIC_DRAWDOWN_TYPE = 134000032,
  PORTAL_BONDING_CONTRACT_OFFER_REJECT_REASON = 134000034,
  PORTAL_BONDING_CONTRACT_INQUIRY_REJECTION_REASON = 134000035,
  PORTAL_BOND_REQUEST_REJECT_REASON = 134000036,
  INTRANET_BOND_REQUEST_REJECT_REASON = 134000037,
  ACTIVATED_BOND_REJECT_REASON = 134000038,
  BOND_PROMISE_REJECT_REASON = 134000039,
  SHOW_IN_PORTAL = 134000040,
  BROKER_TAX_TYPE = 134000041,
  KUKE_BOND_CURRENCY = 134000042,
  KUKE_CONTRACT_STATUS_SEARCH = 134000043,
  KUKE_CONTRACT_TYPE_SEARCH = 134000044,
}

export enum ProfileCategory {
  OTHER = 147000001,
  CREDENDO_BONDING = 147000002,

  CREDENDO_POLICIES = 147000003,
  CREDENDO_LIMIT = 147000004,
  CREDENDO_SALES = 147000009,
  CREDENDO_UNDERWRITER = 147000011,
  KUKE_LIMITS = 147000100,
  KUKE_DUW = 147000105,
  KUKE_BONDING = 147000106,
  UNDERWRITER = 147000011,
  KUKE_SALES = 147000101,
  KUKE_EXT_POLICIES = 147000112,
  KUKE_EXT_BONDING = 147000113,
  KUKE_EXT_ADMIN = 147000114,
  KUKE_COLLECTIONS = 147000108,
  KUKE_CLAIMS = 147000107,
  CREDENDO_COLLECTION = 147000013,
  ECG_POLICIES = 147000311,
  ECG_LIMITS = 147000309,
  ECG_EXT_BROKER = 147000315,
  ECG_EXT_CLIENT = 147000316,
  MEHIB_LIMITS = 147000202,
}

export enum ImportType {
  PAYMENTS = 116000001,
  COMPANIES_MGA = 116000002,
  CONTRACTS_BONDS_MGA = 116000003,
  COMPANY_LINK_MGA = 116000004,
  INVOICES_MGA = 116000005,
  UNDERWRITERS = 116000006,
  POLICY_LIMIT_REQUESTS = 116000007,
  LIMIT_REQUESTS_FOR_MASS_DECISION = 116000008,
  LIMIT_REQUEST_FALCON = 116000033,
  LIMIT_REQUESTS_STANDARD_WITH_FACTORER = 116000017,
  LIMIT_REQUESTS_FALCON_WITH_FACTORER = 116000044,
  LIMIT_TAKEOVERS = 116000009,
  CLAIMS = 116000010,
  IMPORT_ACCOUNTING_BOOKING = 116000011,
  COUNTRY_RATING = 116000012,
  POLICY_INQUIRY_LIMIT_REQUESTS = 116000007,
  LIMIT_REQUESTS_AUTO_DECISION = 116000018,
  CESSION_BUYERS = 116000024,
  TURNOVER_KUKE_INTRANET_DECLARATION = 116000022,
  TURNOVER_KUKE_INTRANET_AMENDMENT = 116000023,
  OUTSTANDINGS_KUKE_INTRANET_DECLARATION = 116000026,
  OUTSTANDINGS_KUKE_INTRANET_AMENDMENT = 116000027,
  TURNOVER_KUKE_PORTAL_DECLARATION = 116000051,
  TURNOVER_KUKE_PORTAL_AMENDMENT = 116000052,
  OUTSTANDINGS_KUKE_PORTAL_DECLARATION = 116000053,
  TURNOVER_KUKE_WITH_NN_INTRANET_DECLARATION = 116000056,
  TURNOVER_KUKE_WITH_NN_PORTAL_DECLARATION = 116000057,
  TURNOVER_KUKE_WITH_NN_INTRANET_AMENDMENT = 116000058,
  TURNOVER_KUKE_WITH_NN_PORTAL_AMENDMENT = 116000059,
  OUTSTANDINGS_WITH_NN_KUKE_INTRANET_DECLARATION = 116000060,
  OUTSTANDINGS_WITH_NN_KUKE_PORTAL_DECLARATION = 116000061,
  OUTSTANDINGS_WITH_NN_KUKE_INTRANET_AMENDMENT = 116000062,
  TURNOVER_ECG_INTRANET_DECLARATION = 116001022,
  TURNOVER_ECG_INTRANET_AMENDMENT = 116001023,
  TURNOVER_ECG_PORTAL_DECLARATION = 116001051,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_DECLARATION = 116000068,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_AMENDMENT = 116000069,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_PORTAL_DECLARATION = 116000070,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_PORTAL_AMENDMENT = 116000071,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_DECLARATION = 116000072,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_AMENDMENT = 116000073,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_PORTAL_DECLARATION = 116000074,
  TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_PORTAL_AMENDMENT = 116000075,
}

export enum InvoiceType {
  INVOICE = 45000001,
  CREDIT_NOTE = 45000002,
}

export enum Language {
  ENGLISH = 5000041,
  GERMAN = 5000052,
  ITALIAN = 5000071,
  POLISH = 5000127,
  RUSSIAN = 5000134,
}

export enum LegalForm {
  SA = 12001000,
  FP007 = 12001006,
  FP010 = 12001009,
  FP028 = 12001027,
  BANK = 12002002,

  JOINT_VENTURE = 12000081,

  JOINT_VENTURE_ITALY_ATI = 12000082,

  JOINT_VENTURE_ITALY_RTI = 12000083,

  JOINT_VENTURE_ITALY_RTP = 12000084,

  JOINT_VENTURE_ITALY_ATS = 12000085,
  JOINT_VENTURE_ITALY_CONSORZIO = 12000086,
}

export const JOINT_VENTURES: LegalForm[] = [
  LegalForm.JOINT_VENTURE,
  LegalForm.JOINT_VENTURE_ITALY_ATI,
  LegalForm.JOINT_VENTURE_ITALY_RTI,
  LegalForm.JOINT_VENTURE_ITALY_RTP,
  LegalForm.JOINT_VENTURE_ITALY_ATS,
  LegalForm.JOINT_VENTURE_ITALY_CONSORZIO,
];

export enum LumpSum {
  NO = 189000001,
  NOT_LIMITED = 189000002,
}

export enum LimitedLiability {
  PX20 = 434000001,
  PX15 = 434000002,
  PX10 = 434000003,
}

export enum TradeIndustry {
  ALL_OTHER_SECTORS = 190000033,
}

export enum BondStatus {
  BOND_REQUEST = 7000001,
  BOND_REQUEST_ACCEPTED = 7000002,
  REQUEST_ACCEPTED_BY_CLIENT = 7000014,
  BOND_REQUEST_REJECTED = 7000003,
  REQUEST_TERMS_SENT = 7000016,
  REQUEST_TERMS_SENT_OUTSIDE = 7000017,
  REQUEST_IN_ACCEPTANCE = 7000018,
  BOND = 7000004,
  BOND_CANCELLED = 7000005,
  BOND_TO_BE_RELEASED = 7000006,
  BOND_RELEASED = 7000007,
  BOND_TO_BE_CANCELLED = 7000008,
  BOND_REQUEST_CANCELLED = 7000013,
  BON_REQ_REJ_CLIENT = 7000015,
  BON_REQ_IN_ACC = 7000018,
  BOND_ACTIVE = 7000019,
  BOND_EXPIRED = 7000021,
}

export const BOND_ACTIVATED: BondStatus[] = [
  BondStatus.BOND,
  BondStatus.BOND_ACTIVE,
  BondStatus.BOND_TO_BE_CANCELLED,
  BondStatus.BOND_TO_BE_RELEASED,
  BondStatus.BOND_CANCELLED,
];

export enum BondDocumentType {
  REQUEST_FROM = 138000101,
  REQUEST_FOR_APPROVAL_KRU_KPUE_MANAGEMENT_BOARD_REINSURANCE = 138000106,
  RESOLUTION_TO_THE_REQUEST = 138000107,
  OFFER = 138000108,
  BROKERAGE_AGREEMENT_FOR_THE_GUARANTEE = 138000109,
  PROMISE = 138000110,
  CONTRACT = 138000111,
  CONTRACT_ANNEX = 138000112,
  OTHER_DOCUMENTS = 138000113,
  OTHER = 138000004,
}

export enum CompanyRawStatus {
  TO_BE_VERIFIED = 182000001,
  REPORT_ORDERED = 182000002,
  ERROR = 182000003,
  POSITIVE_REPORT = 182000004,
  NEGATIVE_REPORT = 182000005,
  CANCELLED = 182000006,
}

export enum BrokerRelationStatus {
  INACTIVE = 143000001,
  ACTIVATED = 143000002,
  CANCELLED = 143000003,
  USER_ERROR = 143000004,
  ACTIVE = 143000005,
  OBSOLETE = 143000006,
}

export enum BrokerContractOptionType {
  PREMIUM = 255000001,
  NO_CLAIM_BONUS_UPFRONT = 255000002,
  NO_CLAIM_BONUS_RECOVERED = 255000003,
  NO_CLAIM_BONUS_IN_FINE = 255000004,
  MALUS = 255000005,
  ADDITIONAL_PREMIUM = 255000006,
  PROFIT_COMMISSION = 255000007,
  PROFIT_COMMISSION_RECOVERY = 255000008,
}

export enum BrokerContractOptionValue {
  NET = 256000001,
  GROSS = 256000002,
  NA = 256000003,
}

export type IsElementaryRight = ElementaryRight | CollectionElementaryRight;

export enum ElementaryRight {
  ACCESS_MODULE_BOND,
  ACCESS_MODULE_POLICIES,
  ACCESS_MODULE_LIMITS,
  ACCESS_MODULE_CLAIMS,
  ACCESS_MODULE_RATING,
  ACCESS_MODULE_SALES,
  ACCESS_MODULE_FINANCE,
  ACCESS_MODULE_COLLECTION,
  ACCESS_MODULE_ADMINISTRATION,
  ACCESS_MODULE_HR,
  BROKER_PORTFOLIO,
  BROKERCONTRACT_CREATE,
  BROKERCONTRACT_REMOVE,
  BROKERCONTRACT_UPDATE,
  BROKERCONTRACT_UPDATE_CLOSE,
  BROKERCONTRACT_NEW_DOCUMENT,
  BROKERCONTRACTRELATION_GENERATE_DOCUMENT,
  BROKERCONTRACTRELATION_NEW_DOCUMENT,
  BROKERCONTRACT_GENERATE_DOCUMENT,
  BROKERCONTRACTRELATION_CREATE_NEW_VERSION,
  BROKERCONTRACTRELATION_REMOVE,
  BROKERCONTRACTRELATION_CREATE,
  BROKERCONTRACTRELATION_CREATE_POLICY,
  BROKERCONTRACTRELATION_CREATE_INDIVIDUAL_CONTRACT,
  BROKERCONTRACTRELATION_CREATE_GENERAL_CONTRACT,
  BROKERCONTRACTRELATION_ACCESS,
  BROKERCONTRACT_ACCESS,
  BROKER_RELATION_DATE_CHANGE = 26007001,
  SPEC_COMPANY_EDIT,
  CLAIMS_IMPORTANT_CHANGES,
  PORTAL_BROKER,
  PORTAL_CLIENT,
  PORTAL_POLICIES,
  TREATY_ACTIVATION,
  TREATY_SERVICE_EDITION,
  TREATY_SEARCH,
  TREATY_CREATE_UPDATE,
  POLICYCONTRACTVERSIONENDPOINT_UPDATE,
  CONTACTNOTEENDPOINT_UPDATE,
  CONTACTNOTEENDPOINT_DELETE,
  CONTACTPERSONENDPOINT_UPDATE,
  RETROACTIVITY,
  CLIENT_BONDS,
  BATCH_MANAGEMENT,
  PREMIUM_RETURN_CREATE,
  PREMIUM_RETURN_ACCEPT,
  TASK_EDIT_DUE_DATE,
  TASK_CHANGE_ASSIGNMENT,
  TASK_CLOSE,
  TASK_CLOSE_SUPPRESS,
  TASK_CANCEL,
  COMPANY_CREATE,
  COMPANY_SPECIAL_CREATE,
  TASK_LIMIT_ONLY_MINE,
  TASK_LIMIT_ONLY_MY_GROUP,
  TASK_VIEW_OTHER_GROUPS,
  COMPANYRATINGENDPOINT_CREATE,
  BUSINESS_UNIT_EDIT,
  ACCESS_RODO,
  ACCESS_FOOTER,
  PREMIUM_RETURN_EDITION,
  EXTERNAL_USER_ACCESS_ONLY,
  BUSINESS_PARAM_UPDATE,
  BATCH_RUN,
  MY_PROFILE_USER_ROLES_UPDATE,
  MY_PROFILE_CATEGORY_USERS_SEARCH,
  TASKENDPOINT_UPDATE,
  CRM_MODULE_CHANGE,
  BUSINESSLOG_VIEW,
  CLAIM_FIELDS_EDITION,
  USER_ROLE_QUOTA_UPDATE,
  ASSIGNMENT_RULES_UPDATE,
  REJECT_MANAGEMENT_SEARCH,
  BIKRATING,
  /* Invoice */
  INVOICEBATCHENDPOINT_CREATE = 26000077,
  CLIENT_INVOICE_READ = 26000078, // UR1
  INVOICE_CREATE_UPDATE = 26000079, // UR2
  INVOICEITEMBATCHENDPOINT_CREATE = 26000080,
  INTERMEDIARY_INVOICE_READ = 26000081, // UR6
  CLIENT_INVOICE_MASS_EXPORT = 26000082, // UR4
  INTERMEDIARY_INVOICE_MASS_EXPORT = 26000083, // UR5
  INVOICE_SERVICE_EDITION = 26000104,
  ACCESS_MODULE_INTEREST = 26000111,
  INVOICE_SEND_EMAIL = 26000138, // UR3
  INVOICE_MASS_WRITEALLOFF = 26000172, // UR7
  PORTAL_INVOICE_DETAILS_VIEW = 26000189,
  PORTAL_INQUIRY_REGISTRATION_COMPLETED = 26004006,
  LIMIT_ACCEPT_DECISION = 26002227,
  BOND_BENEFICIARY_CHANGE = 26006002,
  CHANGE_BANNER = 26000003,
  LIMIT_DECISION_INTERNAL_COMMENT,
  /* End of invoice */
}

export enum BondingElementaryRight {
  BONDING_CONTRACT_INQUIRY_CREATE = 'BONDING_CONTRACT_INQUIRY_CREATE',
  BONDING_CONTRACT_INQUIRY_UPDATE = 'BONDING_CONTRACT_INQUIRY_UPDATE',
  BONDING_CONTRACT_INQUIRY_VIEW = 'BONDING_CONTRACT_INQUIRY_VIEW',
  BONDING_CONTRACT_INQUIRY_SEARCH = 'BONDING_CONTRACT_INQUIRY_SEARCH',
  BONDING_CONTRACT_TECHNICAL_DETAILS_EDIT = 'BONDING_CONTRACT_TECHNICAL_DETAILS_EDIT',
  BONDING_CONTRACT_VIEW = 'BONDING_CONTRACT_VIEW',
  BONDING_CONTRACT_SEARCH = 'BONDING_CONTRACT_SEARCH',
  BONDING_CONTRACT_PARAMETERS = 'BONDING_CONTRACT_PARAMETERS',
  BONDING_CONTRACT_CREATE_UPDATE = 'BONDING_CONTRACT_CREATE_UPDATE',
  BONDING_CONTRACT_DELETE = 'BONDING_CONTRACT_DELETE',
  BOND_CREATE = 'BOND_CREATE',
  BOND_UPDATE = 'BOND_UPDATE',
  BOND_ACCEPT_REJECT = 'BOND_ACCEPT_REJECT',
  BOND_VIEW = 'BOND_VIEW',
  BOND_ISSUE_RELEASE = 'BOND_ISSUE_RELEASE',
  BOND_DELETE = 'BOND_DELETE',
  BONDING_CONTRACT_OFFER_CREATE = 'BONDING_CONTRACT_OFFER_CREATE',
  BONDING_CONTRACT_OFFER_UPDATE = 'BONDING_CONTRACT_OFFER_UPDATE',
  BONDING_CONTRACT_OFFER_ACCEPT = 'BONDING_CONTRACT_OFFER_ACCEPT',
  BONDING_CONTRACT_OFFER_VIEW = 'BONDING_CONTRACT_OFFER_VIEW',
  BONDING_PREMIUM_RETURN = 'BONDING_PREMIUM_RETURN',
  BONDING_CONTRACT_RATE_EDIT = 'BONDING_CONTRACT_RATE_EDIT',
}

export enum ClaimElementaryRight {
  CLAIM_SEARCH = 'CLAIM_SEARCH',
  CLAIM_VIEW = 'CLAIM_VIEW',
  CLAIM_CREATE_INITIAL = 'CLAIM_CREATE_INITIAL',
  CLAIM_CREATE_INITIAL_PBG = 'CLAIM_CREATE_INITIAL_PBG',
  CLAIM_OFFICER_CHANGE = 'CLAIM_OFFICER_CHANGE',
  CLAIM_GENERATE_PDF = 'CLAIM_GENERATE_PDF',
  CLAIM_UPDATE = 'CLAIM_UPDATE',
  CLAIM_CREATE_NEW_VERSION = 'CLAIM_CREATE_NEW_VERSION',
  CLAIM_COMPLETENESS_CHECK_VIEW = 'CLAIM_COMPLETENESS_CHECK_VIEW',
  CLAIM_COMPLETENESS_CHECK_EDIT = 'CLAIM_COMPLETENESS_CHECK_EDIT',
  CLAIM_LAWSUIT_VIEW = 'CLAIM_LAWSUIT_VIEW',
  CLAIM_LAWSUIT_EDIT = 'CLAIM_LAWSUIT_EDIT',
  CLAIM_NEW_DOCUMENT = 'CLAIM_NEW_DOCUMENT',
  CLAIM_TASKS = 'CLAIM_TASKS',
  CLAIM_LINKED_CLAIMS = 'CLAIM_LINKED_CLAIMS',
  CLAIM_CALCULATION_VIEW = 'CLAIM_CALCULATION_VIEW',
  CLAIM_CALCULATION_EDIT = 'CLAIM_CALCULATION_EDIT',
  CLAIM_INDEMNIFICATION_VIEW = 'CLAIM_INDEMNIFICATION_VIEW',
  CLAIM_INDEMNIFICATION_EDIT = 'CLAIM_INDEMNIFICATION_EDIT',
}

export enum CollectionElementaryRight {
  COLLECTION_SEARCH = 'COLLECTION_SEARCH',
  COLLECTION_CREATE_INITIAL = 'COLLECTION_CREATE_INITIAL',
  COLLECTION_VIEW = 'COLLECTION_VIEW',
  COLLECTION_CREATE = 'COLLECTION_CREATE',
  COLLECTION_UPDATE = 'COLLECTION_UPDATE',
  COLLECTION_SELF_RECOVERY_VIEW = 'COLLECTION_SELF_RECOVERY_VIEW',
  COLLECTION_RELATED_CASES_VIEW = 'COLLECTION_RELATED_CASES_VIEW',
  COLLECTION_HISTORY_VIEW_SIMPLE = 'COLLECTION_HISTORY_VIEW_SIMPLE',
  COLLECTION_HISTORY_VIEW = 'COLLECTION_HISTORY_VIEW',
  COLLECTION_HISTORY_CREATE_UPDATE = 'COLLECTION_HISTORY_CREATE_UPDATE',
  COLLECTION_NEW_DOCUMENT = 'COLLECTION_NEW_DOCUMENT',
  COLLECTION_TASKS = 'COLLECTION_TASKS',
  COLLECTION_COSTS_VIEW = 'COLLECTION_COSTS_VIEW',
  COLLECTION_COSTS_CREATE_UPDATE = 'COLLECTION_COSTS_CREATE_UPDATE',
  COLLECTION_PAYMENTS_VIEW = 'COLLECTION_PAYMENTS_VIEW',
  COLLECTION_PAYMENTS_CREATE_UPDATE_FULL = 'COLLECTION_PAYMENTS_CREATE_UPDATE_FULL',
  COLLECTION_PAYMENTS_CREATE_UPDATE = 'COLLECTION_PAYMENTS_CREATE_UPDATE',
  COLLECTION_PAYMENT_SCHEDULE_VIEW = 'COLLECTION_PAYMENT_SCHEDULE_VIEW',
  COLLECTION_PAYMENT_SCHEDULE_CREATE_UPDATE = 'COLLECTION_PAYMENT_SCHEDULE_CREATE_UPDATE',
  COLLECTION_REQUESTS_VIEW = 'COLLECTION_REQUESTS_VIEW',
  COLLECTION_REQUESTS_CREATE_UPDATE = 'COLLECTION_REQUESTS_CREATE_UPDATE',
  COLLECTION_EXTERNAL_PROVIDER_RELATIONS_VIEW = 'COLLECTION_EXTERNAL_PROVIDER_RELATIONS_VIEW',
  COLLECTION_EXTERNAL_PROVIDER_RELATIONS_CREATE_UPDATE = 'COLLECTION_EXTERNAL_PROVIDER_RELATIONS_CREATE_UPDATE',
  COLLECTION_EXTERNAL_PROVIDER_VIEW = 'COLLECTION_EXTERNAL_PROVIDER_VIEW',
  COLLECTION_EXTERNAL_PROVIDER_CREATE_UPDATE = 'COLLECTION_EXTERNAL_PROVIDER_CREATE_UPDATE',
  PORTAL_COLLECTION_REMOVE_BANK_ACCOUNT = 'PORTAL_COLLECTION_REMOVE_BANK_ACCOUNT',
  PORTAL_COLLECTION_CANCEL_REQUEST = 'PORTAL_COLLECTION_CANCEL_REQUEST',
  PORTAL_COLLECTION_CANCEL_REQUEST_WITH_WAIVER = 'PORTAL_COLLECTION_CANCEL_REQUEST_WITH_WAIVER',
}

export enum CompanyElementaryRight {
  COMPANY_MODULE_ACCESS = 'COMPANY_MODULE_ACCESS',
  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  COMPANY_SPECIAL_CREATE = 'COMPANY_SPECIAL_CREATE',
  COMPANY_SEARCH = 'COMPANY_SEARCH',
  COMPANY_BI_SEARCH = 'COMPANY_BI_SEARCH',
  COMPANY_BI_SELECT = 'COMPANY_BI_SELECT',
  COMPANY_BI_REPORT_ORDER = 'COMPANY_BI_REPORT_ORDER',
  COMPANY_DETAIL_ACCESS = 'COMPANY_DETAIL_ACCESS',
  COMPANY_RATING_DETAIL_ACCESS = 'COMPANY_RATING_DETAIL_ACCESS',
  COMPANY_RATING_EDIT = 'COMPANY_RATING_EDIT',
  COMPANY_RATING_ACCEPTANCE = 'COMPANY_RATING_ACCEPTANCE',
  COMPANY_RATING_ACCEPTOR = 'COMPANY_RATING_ACCEPTOR',
  COMPANY_RATING_CALCULATE = 'COMPANY_RATING_CALCULATE',
  COMPANY_RATING_CREATE = 'COMPANY_RATING_CREATE',
  COMPANY_RATING_AUTODECISION = 'COMPANY_RATING_AUTODECISION',
  COMPANY_RATING_MANAGEMENT_PROPOSAL_KRU_KPUE = 'COMPANY_RATING_MANAGEMENT_PROPOSAL_KRU_KPUE',
  COMPANY_EDIT = 'COMPANY_EDIT',
  COMPANY_MANAGERS_ACCESS = 'COMPANY_MANAGERS_ACCESS',
  COMPANY_MANAGERS_EDIT = 'COMPANY_MANAGERS_EDIT',
  COMPANY_LEGALLY_DECLARED_INSOLVENCY_ACCESS = 'COMPANY_LEGALLY_DECLARED_INSOLVENCY_ACCESS',
  COMPANY_LEGALLY_DECLARED_INSOLVENCY_EDIT = 'COMPANY_LEGALLY_DECLARED_INSOLVENCY_EDIT',
  COMPANY_FINANCIAL_DATA_EDIT = 'COMPANY_FINANCIAL_DATA_EDIT',
  COMPANY_FINANCIAL_DATA_ACCESS = 'COMPANY_FINANCIAL_DATA_ACCESS',
  COMPANY_BI_REPORTS = 'COMPANY_BI_REPORTS',
  COMPANY_DOCUMENT_SEND = 'COMPANY_DOCUMENT_SEND',
  COMPANY_GROUP_ACCESS = 'COMPANY_GROUP_ACCESS',
  COMPANY_GROUP_UPDATE = 'COMPANY_GROUP_UPDATE',
  COMPANY_GROUP_CREATE = 'COMPANY_GROUP_CREATE',
  COMPANY_GROUP_REMOVE = 'COMPANY_GROUP_REMOVE',
  COMPANY_THIRD_PARTY_ACCESS = 'COMPANY_THIRD_PARTY_ACCESS',
  CLIENT_COMPANY_ACCESS = 'CLIENT_COMPANY_ACCESS',
  COMPANY_IMPORT_FILE_LIMIT_MASS_DECISION = 'COMPANY_IMPORT_FILE_LIMIT_MASS_DECISION',
  COMPANY_IDENTIFIERS_EDIT = 'COMPANY_IDENTIFIERS_EDIT',
  COMPANY_DETAIL_SEARCH = 'COMPANY_DETAIL_SEARCH',
  COMPANY_ROLE_MANAGEMENT = 'COMPANY_ROLE_MANAGEMENT',
}

export enum LimitElementaryRight {
  LIMIT_CANCEL_COVER_PLUS_OFFER = 'LIMIT_CANCEL_COVER_PLUS_OFFER',
  LIMIT_PROLONG_DECISION = 'LIMIT_PROLONG_DECISION',
  LIMIT_NEW_DOCUMENT = 'LIMIT_NEW_DOCUMENT',
  LIMIT_REQUEST_CANCEL = 'LIMIT_REQUEST_CANCEL',
  LIMITREQUESTENDPOINT_EXPORT = 'LIMITREQUESTENDPOINT_EXPORT',
  LIMIT_LNT_PND_MANAGEMENT = 'LIMIT_LNT_PND_MANAGEMENT',
  LIMIT_MASS_DECISION_VIEW = 'LIMIT_MASS_DECISION_VIEW',
  ACCESS_MODULE_LIMITS = 'ACCESS_MODULE_LIMITS',
  IMPORT_FILE_LIMIT_MASS_DECISION = 'IMPORT_FILE_LIMIT_MASS_DECISION',
}

export enum PolicyElementaryRight {
  POLICY_INQUIRY_CREATE = 'POLICY_INQUIRY_CREATE',
  POLICY_INQUIRY_REGISTER = 'POLICY_INQUIRY_REGISTER',
  POLICY_INQUIRY_VIEW = 'POLICY_INQUIRY_VIEW',
  POLICY_INQUIRY_UPDATE = 'POLICY_INQUIRY_UPDATE',
  POLICY_INQUIRY_EDGE_OFFER_CREATE = 'POLICY_INQUIRY_EDGE_OFFER_CREATE',
  POLICY_INQUIRY_EDGE_OFFER_UPDATE = 'POLICY_INQUIRY_EDGE_OFFER_UPDATE',
  POLICY_INQUIRY_EDGE_OFFER_VIEW = 'POLICY_INQUIRY_EDGE_OFFER_VIEW',
  POLICY_INQUIRY_EDGE_OFFER_DECISION = 'POLICY_INQUIRY_EDGE_OFFER_DECISION',
  POLICY_INQUIRY_CLIENT_OFFER_CREATE = 'POLICY_INQUIRY_CLIENT_OFFER_CREATE',
  POLICY_INQUIRY_CLIENT_OFFER_UPDATE = 'POLICY_INQUIRY_CLIENT_OFFER_UPDATE',
  POLICY_INQUIRY_CLIENT_OFFER_GENERATE_DRAFT = 'POLICY_INQUIRY_CLIENT_OFFER_GENERATE_DRAFT',
  POLICY_INQUIRY_CLIENT_OFFER_GENERATE_PDF = 'POLICY_INQUIRY_CLIENT_OFFER_GENERATE_PDF',
  POLICY_INQUIRY_CLIENT_OFFER_SEND = 'POLICY_INQUIRY_CLIENT_OFFER_SEND',
  POLICY_INQUIRY_CLIENT_OFFER_CHECK = 'POLICY_INQUIRY_CLIENT_OFFER_CHECK',
  POLICY_INQUIRY_CLIENT_OFFER_DECISION = 'POLICY_INQUIRY_CLIENT_OFFER_DECISION',
  POLICY_INQUIRY_CLIENT_OFFER_VIEW = 'POLICY_INQUIRY_CLIENT_OFFER_VIEW',
  POLICY_CLAUSES_FOR_POLICY_VIEW = 'POLICY_CLAUSES_FOR_POLICY_VIEW',
  POLICY_CLAUSES_CREATE_UPDATE = 'POLICY_CLAUSES_CREATE_UPDATE',
  POLICY_INQUIRY_CALCULATOR_RECALCULATE = 'POLICY_INQUIRY_CALCULATOR_RECALCULATE',
  POLICY_INQUIRY_CALCULATOR_SAVE = 'POLICY_INQUIRY_CALCULATOR_SAVE',
  POLICY_INQUIRY_CALCULATOR_VIEW = 'POLICY_INQUIRY_CALCULATOR_VIEW',
  POLICY_CREATE = 'POLICY_CREATE',
  POLICY_VIEW = 'POLICY_VIEW',
  POLICY_UPDATE = 'POLICY_UPDATE',
  POLICY_GENERATE_DOCUMENTS = 'POLICY_GENERATE_DOCUMENTS',
  POLICY_ISSUE_DOCUMENTS = 'POLICY_ISSUE_DOCUMENTS',
  POLICY_GENERATE_CLIENT_CARD = 'POLICY_GENERATE_CLIENT_CARD',
  POLICY_MULTILIST_VIEW = 'POLICY_MULTILIST_VIEW',
  POLICY_MULTILIST_CREATE_UPDATE = 'POLICY_MULTILIST_CREATE_UPDATE',
  POLICY_SERVICE_DETAILS_VIEW = 'POLICY_SERVICE_DETAILS_VIEW',
  POLICY_SERVICE_DETAILS_UPDATE = 'POLICY_SERVICE_DETAILS_UPDATE',
  POLICY_REPORTING_PERIOD_UPDATE = 'POLICY_REPORTING_PERIOD_UPDATE',
  POLICY_GENERATE_SIGNING_PDF = 'POLICY_GENERATE_SIGNING_PDF',
  POLICY_ANNEX_INQUIRY_CREATE = 'POLICY_ANNEX_INQUIRY_CREATE',
  POLICY_ANNEX_INQUIRY_UPDATE = 'POLICY_ANNEX_INQUIRY_UPDATE',
  POLICY_ANNEX_INQUIRY_VIEW = 'POLICY_ANNEX_INQUIRY_VIEW',
  POLICY_RENEWAL_INQUIRY_CREATE = 'POLICY_RENEWAL_INQUIRY_CREATE',
  POLICY_RENEWAL_INQUIRY_VIEW = 'POLICY_RENEWAL_INQUIRY_VIEW',
  POLICY_DECLARATION_CREATE_UPDATE = 'POLICY_DECLARATION_CREATE_UPDATE',
  POLICY_DECLARATION_VIEW = 'POLICY_DECLARATION_VIEW',
  POLICY_DECLARATION_AMEND = 'POLICY_DECLARATION_AMEND',
  POLICY_CESSION_CREATE = 'POLICY_CESSION_CREATE',
  POLICY_CESSION_UPDATE = 'POLICY_CESSION_UPDATE',
  POLICY_CESSION_SERVICE_EDITION = 'POLICY_CESSION_SERVICE_EDITION',
  POLICY_CESSION_VIEW = 'POLICY_CESSION_VIEW',
  POLICY_CESSION_DOCUMENT_GENERATE = 'POLICY_CESSION_DOCUMENT_GENERATE',
  POLICY_ION_CREATE = 'POLICY_ION_CREATE',
  POLICY_ION_UPDATE = 'POLICY_ION_UPDATE',
  POLICY_ION_VIEW = 'POLICY_ION_VIEW',
  POLICY_ION_VIEW_REDEEMED = 'POLICY_ION_VIEW_REDEEMED',
  POLICY_ION_DELETE = 'POLICY_ION_DELETE',
  POLICY_ACTIVATE = 'POLICY_ACTIVATE',
  POLICY_TERMINATE = 'POLICY_TERMINATE',
  POLICY_ISSUE_TASK = 'POLICY_ISSUE_TASK',
}

export enum SalesRepElementaryRight {
  SALES_REP_SEARCH = 'SALES_REP_SEARCH',
  SALES_REP_CREATE_UPDATE = 'SALES_REP_CREATE_UPDATE',
  SALES_REP_VIEW = 'SALES_REP_VIEW',
  SALES_REP_RELATION_CREATE_UPDATE_BONDING = 'SALES_REP_RELATION_CREATE_UPDATE_BONDING',
  SALES_REP_RELATION_CREATE_UPDATE_POLICIES = 'SALES_REP_RELATION_CREATE_UPDATE_POLICIES',
}

// TODO: Rename to SERVICE_CONTACTS
export enum ServiceContactElementaryRight {
  SERVICE_CONTACT_READ_ALL = 'SERVICE_CONTACT_READ_ALL',
  SERVICE_CONTACT_WRITE_ALL = 'SERVICE_CONTACT_WRITE_ALL',
  SERVICE_CONTACT_READ_POLICIES = 'SERVICE_CONTACT_READ_POLICIES',
  SERVICE_CONTACT_WRITE_POLICIES = 'SERVICE_CONTACT_WRITE_POLICIES',
  SERVICE_CONTACT_READ_ZSS = 'SERVICE_CONTACT_READ_ZSS',
  SERVICE_CONTACT_WRITE_ZSS = 'SERVICE_CONTACT_WRITE_ZSS',
  SERVICE_CONTACT_READ_COLLECTIONS = 'SERVICE_CONTACT_READ_COLLECTIONS',
  SERVICE_CONTACT_WRITE_COLLECTIONS = 'SERVICE_CONTACT_WRITE_COLLECTIONS',
  SERVICE_CONTACT_READ_CLAIMS = 'SERVICE_CONTACT_READ_CLAIMS',
  SERVICE_CONTACT_WRITE_CLAIMS = 'SERVICE_CONTACT_WRITE_CLAIMS',
  SERVICE_CONTACT_READ_COMPANIES = 'SERVICE_CONTACT_READ_COMPANIES',
  SERVICE_CONTACT_WRITE_COMPANIES = 'SERVICE_CONTACT_WRITE_COMPANIES',
  SERVICE_CONTACT_READ_BONDING = 'SERVICE_CONTACT_READ_BONDING',
  SERVICE_CONTACT_WRITE_BONDING = 'SERVICE_CONTACT_WRITE_BONDING',
  SERVICE_CONTACT_READ_FINANCE = 'SERVICE_CONTACT_READ_FINANCE',
  SERVICE_CONTACT_WRITE_FINANCE = 'SERVICE_CONTACT_WRITE_FINANCE',
}

export enum ContactNoteElementaryRight {
  CONTACT_NOTE_READ_ALL = 'CONTACT_NOTE_READ_ALL',
  CONTACT_NOTE_WRITE_ALL = 'CONTACT_NOTE_WRITE_ALL',
  CONTACT_NOTE_READ_POLICIES = 'CONTACT_NOTE_READ_POLICIES',
  CONTACT_NOTE_WRITE_POLICIES = 'CONTACT_NOTE_WRITE_POLICIES',
  CONTACT_NOTE_READ_COLLECTIONS = 'CONTACT_NOTE_READ_COLLECTIONS',
  CONTACT_NOTE_WRITE_COLLECTIONS = 'CONTACT_NOTE_WRITE_COLLECTIONS',
  CONTACT_NOTE_READ_CLAIMS = 'CONTACT_NOTE_READ_CLAIMS',
  CONTACT_NOTE_WRITE_CLAIMS = 'CONTACT_NOTE_WRITE_CLAIMS',
  CONTACT_NOTE_READ_COMPANIES = 'CONTACT_NOTE_READ_COMPANIES',
  CONTACT_NOTE_WRITE_COMPANIES = 'CONTACT_NOTE_WRITE_COMPANIES',
  CONTACT_NOTE_READ_BONDING = 'CONTACT_NOTE_READ_BONDING',
  CONTACT_NOTE_WRITE_BONDING = 'CONTACT_NOTE_WRITE_BONDING',
  CONTACT_NOTE_READ_LIMITS = 'CONTACT_NOTE_READ_LIMITS',
  CONTACT_NOTE_WRITE_LIMITS = 'CONTACT_NOTE_WRITE_LIMITS',
  CONTACT_NOTE_READ_FINANCE = 'CONTACT_NOTE_READ_FINANCE',
  CONTACT_NOTE_WRITE_FINANCE = 'CONTACT_NOTE_WRITE_FINANCE',
  CONTACT_NOTE_DELETE = 'CONTACT_NOTE_DELETE',
  CONTACT_NOTE_DELETE_ALL = 'CONTACT_NOTE_DELETE_ALL',
  CONTACT_NOTE_DELETE_POLICIES = 'CONTACT_NOTE_DELETE_POLICIES',
  CONTACT_NOTE_DELETE_LIMITS = 'CONTACT_NOTE_DELETE_LIMITS',
  CONTACT_NOTE_DELETE_COMPANIES = 'CONTACT_NOTE_DELETE_COMPANIES',
  CONTACT_NOTE_DELETE_CLAIMS = 'CONTACT_NOTE_DELETE_CLAIMS',
  CONTACT_NOTE_DELETE_COLLECTIONS = 'CONTACT_NOTE_DELETE_COLLECTIONS',
  CONTACT_NOTE_DELETE_FINANCE = 'CONTACT_NOTE_DELETE_FINANCE',
  CONTACT_NOTE_DELETE_BONDING = 'CONTACT_NOTE_DELETE_BONDING',
}

export enum RepoDocumentElementaryRight {
  REPO_DOCUMENT_READ_ALL = 'REPO_DOCUMENT_READ_ALL',
  REPO_DOCUMENT_WRITE_ALL = 'REPO_DOCUMENT_WRITE_ALL',
  REPO_DOCUMENT_READ_POLICIES = 'REPO_DOCUMENT_READ_POLICIES',
  REPO_DOCUMENT_WRITE_POLICIES = 'REPO_DOCUMENT_WRITE_POLICIES',
  REPO_DOCUMENT_READ_ZSS = 'REPO_DOCUMENT_READ_ZSS',
  REPO_DOCUMENT_WRITE_ZSS = 'REPO_DOCUMENT_WRITE_ZSS',
  REPO_DOCUMENT_READ_COLLECTIONS = 'REPO_DOCUMENT_READ_COLLECTIONS',
  REPO_DOCUMENT_WRITE_COLLECTIONS = 'REPO_DOCUMENT_WRITE_COLLECTIONS',
  REPO_DOCUMENT_READ_CLAIMS = 'REPO_DOCUMENT_READ_CLAIMS',
  REPO_DOCUMENT_WRITE_CLAIMS = 'REPO_DOCUMENT_WRITE_CLAIMS',
  REPO_DOCUMENT_READ_COMPANIES = 'REPO_DOCUMENT_READ_COMPANIES',
  REPO_DOCUMENT_WRITE_COMPANIES = 'REPO_DOCUMENT_WRITE_COMPANIES',
  REPO_DOCUMENT_READ_BONDING = 'REPO_DOCUMENT_READ_BONDING',
  REPO_DOCUMENT_WRITE_BONDING = 'REPO_DOCUMENT_WRITE_BONDING',
  REPO_DOCUMENT_READ_LIMITS = 'REPO_DOCUMENT_READ_LIMITS',
  REPO_DOCUMENT_WRITE_LIMITS = 'REPO_DOCUMENT_WRITE_LIMITS',
  REPO_DOCUMENT_READ_FINANCE = 'REPO_DOCUMENT_READ_FINANCE',
  REPO_DOCUMENT_WRITE_FINANCE = 'REPO_DOCUMENT_WRITE_FINANCE',
}

export enum ServiceNoteElementaryRight {
  SERVICE_NOTE_READ_ALL = 'SERVICE_NOTE_READ_ALL',
  SERVICE_NOTE_WRITE_ALL = 'SERVICE_NOTE_WRITE_ALL',
  SERVICE_NOTE_READ_POLICIES = 'SERVICE_NOTE_READ_POLICIES',
  SERVICE_NOTE_WRITE_POLICIES = 'SERVICE_NOTE_WRITE_POLICIES',
  SERVICE_NOTE_READ_COLLECTIONS = 'SERVICE_NOTE_READ_COLLECTIONS',
  SERVICE_NOTE_WRITE_COLLECTIONS = 'SERVICE_NOTE_WRITE_COLLECTIONS',
  SERVICE_NOTE_READ_CLAIMS = 'SERVICE_NOTE_READ_CLAIMS',
  SERVICE_NOTE_WRITE_CLAIMS = 'SERVICE_NOTE_WRITE_CLAIMS',
  SERVICE_NOTE_READ_COMPANIES = 'SERVICE_NOTE_READ_COMPANIES',
  SERVICE_NOTE_WRITE_COMPANIES = 'SERVICE_NOTE_WRITE_COMPANIES',
  SERVICE_NOTE_READ_BONDING = 'SERVICE_NOTE_READ_BONDING',
  SERVICE_NOTE_WRITE_BONDING = 'SERVICE_NOTE_WRITE_BONDING',
  SERVICE_NOTE_READ_FINANCE = 'SERVICE_NOTE_READ_FINANCE',
  SERVICE_NOTE_WRITE_FINANCE = 'SERVICE_NOTE_WRITE_FINANCE',
}

export enum BusinessReportElementaryRight {
  BUSINESS_REPORT_GENERATE = 'BUSINESS_REPORT_GENERATE',
  BUSINESS_REPORT_SEARCH = 'BUSINESS_REPORT_SEARCH',
  REPORTS_EXECUTIONS_ACCESS = 'REPORTS_EXECUTIONS_ACCESS',
}

export enum CountryRatingElementaryRight {
  COUNTRY_RATING_SEARCH = 'COUNTRY_RATING_SEARCH',
  COUNTRY_RATING_CREATE = 'COUNTRY_RATING_CREATE',
}

export enum CountryCategoryLimitStatus {
  ACTIVE = 423000001,
  INACTIVE = 423000002,
}

export enum BondingContractInquiryStatus {
  // kuke
  COMPLETENESS_VERIFICATION = 284000001,
  CANCELLED = 284000002,
  RISK_ASSESSMENT = 284000003,
  LIMIT_GRANTED = 284000004,
  LIMIT_REFUSED = 284000005,
  EXPIRED = 284000006,
  CONTRACT_CREATED = 284000007,

  // credendo
  ACCEPTED = 284000010,
  REJECTED = 284000011,
  REGISTERED = 284000013,
  CLOSED = 284000014,
}

export enum BondingContractOfferStatus {
  NEW = 319000001,
  SENT_TO_APPROVAL = 319000002,
  ACCEPTED = 319000003,
  CANCELLED = 319000004,
  REJECTED = 319000006,
  EXPIRED = 319000005,
  SENT_TO_CLIENT = 319000007,
  VOTING = 319000008,
  CONTRACT_CREATED = 319000009,
  NBI_OFFER = 319000011,
  CONTRACT_CREATED_EXTERNALLY = 319000012,
  VOTING_FINISHED = 319000010,
}

export enum BondingContractInquiryRejectionReason {
  CONTRACT_TERMS_NOT_ACCEPTED = 346001001,
  LIMIT_NOT_NEEDED = 346001002,
  FORMAL_REQUIREMENTS_NOT_MET = 346001003,
  NEGATIVE_LIMIT_DECISION = 346001004,
  CONTRACT_RISK_NOT_ACCEPTED = 346001005,
  APPLICANT_NOT_RELIABLE = 346001006,
  LIMIT_NOT_USED = 346001007,
  BREACH_OF_CONTRACT = 346001008,
}

export enum BondingContractOfferRejectionReason {
  LIMIT_TOO_LOW = 324000001,
  COMMISSIONS_TOO_HIGH = 324000002,
  MINIMAL_PREMIUM_TOO_HIGH = 324000003,
  ADDITIONAL_COLLATERAL_MISSING = 324000004,
  INSUFFICIENT_LIMIT = 324000005,
  APPLICANT_NOT_RELIABLE = 324000006,
  LIMIT_NOT_NEEDED = 324000007,
  LIMIT_NOT_USED = 324000008,
  BREACH_OF_CONTRACT = 324000009,
}

export enum ContractRejectionReason {}

export enum BondRejectionReason {
  REINSURANCE_REFUSAL = 468000013,
}

export enum UserRole {
  SUPER_USER = 6000001,
  PORTAL_USER = 6000011,
  BROKER = 6000012,
  CLIENT = 6000013,
  SPECIAL_BROKER = 6000025,
  CLAIMS_COM_MEMBER = 6000032,
  BE_SPECIAL_BROKER = 6000052,
  // KUKE
  GW_DIRECTOR = 6001047,
  KUKE_SUPERADMIN = 6001000,
  UW_MASTER = 6001001,
  UW_DIRECTOR = 6001002,
  UW_TEAM_LEADER = 6001003,
  DUW_DIRECTOR = 6001038,
  KUKE_PORTAL_ADMIN = 6002006,
  CL_DIRECTOR = 6001055 /*"Dyrektor BLS"*/,
  CL_MANAGER = 6001056 /*"Kierownik BLS"*/,

  PORTAL_CTX_POLICIES = 6002000,
  PORTAL_CTX_LIMITS = 6002001,
  PORTAL_CTX_TURNOVERS = 6002002,
  PORTAL_CTX_COLLECTION = 6002003,
  PORTAL_CTX_CLAIMS = 6002004,
  PORTAL_CTX_BONDING = 6002005,
  PORTAL_CTX_ADMIN = 6002006,
  COUNTRY_MANAGER = 6000065, // Credendo, HR Module
  DIRECT_MANAGER = 6000066, // Credendo, HR Module
  BOARD_MANAGER = 6000067, // Credendo, HR Module
  HR_MANAGER = 6000068, // Credendo, HR Module
  DSP_MASTER = 6001010,
  RBS_DIRECTOR = 6001012,
  ECG_CLIENT_POLICIES = 6003038,
  ECG_CLIENT_BONDING = 6003039,
  ECG_CLIENT_ADMIN = 6003040,
  ECG_BROKER_POLICIES = 6003041,
  ECG_BROKER_BONDING = 6003042,
  ECG_BROKER_ADMIN = 6003043,
}

export enum UserType {
  INTRANET = 67000001,
  EXTRANET = 67000002,
  TECHNICAL = 67000003,
}

export enum VersionType {
  ANNEX = 10000002,
}

export enum CustomFieldValueType {
  STRING = 165000001,
  INTEGER = 165000002,
  DECIMAL = 165000003,
  DATE = 165000004,
  COMPANY = 165000005,
  DICTIONARY = 165000006,
  BOOLEAN = 165000007,
  LIST = 165000008,
  TEXT = 165000009,
  MULTISELECT = 165000010,
  COMBO = 165000011,
  NUMBER = 165000012,
  CREDENDOLOL = 165000013,
}

export enum ContractStatus {
  CONTRACT_REQUEST = 2000001,
  CONTRACT_REQUEST_ACCEPTED = 2000002,
  CONTRACT_REQUEST_REJECTED = 2000003,
  CONTRACT_NOT_ACTIVATED = 2000004,
  CONTRACT_ACTIVATED = 2000005,
  CONTRACT_TO_BE_CANCELLED = 2000006,
  CONTRACT_WITHOUT_RESULT = 2000007,
  CONTRACT_ACTIVE = 2000008,
  CONTRACT_CANCELLED = 2000009,
  CONTRACT_CLOSED = 2000010,
  CONTRACT_USER_ERROR = 2000011,
  CONTRACT_REQUEST_WITHOUT_RESULT = 2000012,
  VERSION_OBSOLETE = 2000013,
  CONTRACT_PROSPECT = 2000015,
  RUN_OFF = 2000016,
  SUSPENDED = 2000018,
}

export enum LimitDecisionSource {
  MASS_DECISION = 154000001,
  AUTO_DECISION = 154000002,
  MANUAL_DECISION_INTRANET = 154000003,
  MANUAL_DECISION_PORTAL = 154000004,
  MIGRATION = 154000006,
}

export enum LimitDecisionAnnotation {
  SHORTER_LOAN_PERIOD = 237000001,
  UKRAINIAN_BUYER = 237000002,
}

export enum LimitDecisionType {
  APPROVAL = 47000001,
  PARTIAL_APPROVAL = 47000002,
  CANCELLATION = 47000003,
  REDUCTION = 47000004,
  RISK_WITHDRAWAL = 47000005,
  REFUSAL = 47000006,
}

export enum BusinessParamType {
  LNT_QUALITATIVE_ASSESSMENT = 297001026,
}

export enum BusinessReportStatus {
  PENDING = 408000001,
  IN_PROGRESS = 408000002,
  COMPLETED = 408000003,
  FAILED = 408000004,
}

export enum LimitCoverPlusStatus {
  PROPOSAL = 166000001,
  ACTIVE = 166000002,
  OUTDATED_PROPOSAL = 166000003,
  CANCELLED_PROPOSAL = 166000008,
  ENDED = 166000004,
  ABANDONED = 166000005,
  CANCELLED = 166000006,
  SUSPENDED = 166000007,
  UNACCEPTED_PROPOSAL = 166000011,
  INACTIVE_PROPOSAL = 166000010,
  INACTIVE_COVER = 166000009,
}

export namespace BondInvoicingSchema {
  export const PERIODIC = 409000001;
  export const PERIODIC_UPFRONT = 409000002;
  export const FLAT_FEE_FIRST_YEAR_ONLY = 409000003;
  export const FLAT_FEE = 409000004;

  export const FLAT_FEES = [FLAT_FEE, FLAT_FEE_FIRST_YEAR_ONLY];
}

export namespace Frequency {
  export const MONTHLY = 33000001;
  export const QUARTERLY = 33000002;
  export const YEARLY = 33000003;
  export const ONCE_PER_INSURANCE_PERIOD = 33000004;
  export const HALF_A_YEAR = 33000005;
  export const WEEKLY = 33000009;
  export const BIWEEKLY = 33000010;
  export const SINGLE_DECISION = 33000011;
}

export enum CountryGroup {
  ALL_COUNTRIES = 187000012,
}

export enum CountryInvocingType {
  DOM_EXP = 188000001,
  DOM_CONTINENTS = 188000002,
  DOM_EU_EXP = 188000003,
  NON_MARKET = 188000004,
  ALL = 188000005,
}

export enum LimitTaskType {
  ZL3_ACCEPT_LIMIT_DECISION = 18001044,
}

export enum Rating {
  X = 57000008,
  KUKE_NC = 57000010,
  KUKE_1 = 57000011,
  KUKE_2 = 57000012,
  KUKE_3 = 57000013,
  KUKE_4 = 57000014,
  KUKE_5 = 57000015,
  KUKE_6 = 57000016,
  KUKE_7 = 57000017,
  KUKE_8 = 57000018,
  KUKE_9 = 57000019,
  KUKE_10 = 57000020,
  KUKE_NR = 57000021,
  KUKE_UNQUALIFIED = 57000022,
  KUKE_HIGH = 57000023,
  KUKE_HIGHER = 57000024,
  KUKE_AVERAGE = 57000025,
  KUKE_LOWER = 57000026,
  KUKE_LOW = 57000027,
  SUV_PLUS = 57000029,
  CC0 = 57000030,
  CC1 = 57000031,
  CC2 = 57000032,
  CC3 = 57000033,
  CC4 = 57000034,
  CC5 = 57000035,
}

export enum RatingLimitation {
  KUKE_NC = 317000007,
  KUKE_NR = 317000008,
}

export enum OECDRating {
  R0 = 155000001,
  R1 = 155000002,
  R2 = 155000003,
  R3 = 155000004,
  R4 = 155000005,
  R5 = 155000006,
  R6 = 155000007,
  R7 = 155000008,
  NR = 155000009,
}

export enum LimitRequestStatus {
  PENDING = 42000001,
  DONE = 42000002,
  WAITING_FOR_BI_REPORT = 42000003,
  CANCELLED = 42000004,
  VERIFICATION = 42000005,
  INACTIVE = 42000006,
}

export enum LimitStatus {
  ACTIVE = 278000001,
  ABANDONED = 278000002,
  MOVED = 278000003,
}

export enum LimitCategory {
  POLICY = 289000001,
  POLICY_INQUIRY = 289000002,
  BONDING_CONTRACT = 289000003,
  BONDING_CONTRACT_INQUIRY = 289000004,
}

export enum LimitRequestType {
  STANDARD = 227000001,
  FALCON = 227000002,
  FARMER = 227000003,
  FARTHER_DELIVERIES = 227000005,
}

export enum LimitDecisionStatus {
  SENT_TO_APPROVAL = 158000001,
  ACTIVE = 158000002,
  CANCELLED = 158000003,
  INACTIVE = 158000004,
  EXPIRED = 158000005,
  UNAPPROVED = 158000006,
}

export enum LimitRequestSource {
  MIGRATION = 167000001,
}

export enum Source {
  MIGRATION = 392000001,
  INTERNAL = 392000002,
  PORTAL = 392000003,
}

export enum CompanyFinancialDataFormat {
  DRU = 82000004,
  FIDAT = 82000005,
}

export enum CompanyFinancialDataVersionType {
  UNITARY = 168000001,
  CONSOLIDATED = 168000002,
}

export enum CompanyFinancialDataType {
  // Ratios type ids

  // KUKE
  // Liquidity
  LIQUIDITY = 86000036,
  CURRENT_RATIO = 86000019,
  QUICK_RATIO = 86000020,
  CASH_RATIO = 86000021,

  // Profitability
  PROFITABILITY = 86000037,
  SALES_PROFIT = 86000025,
  OPERATION_PROFIT = 86000024,
  NET_PROFIT_ROS = 86000023,
  RETURN_FROM_PROPERTY_ROA = 86000026,
  RETURN_FROM_PROPERTY_ROE = 86000027,

  // Liability
  LIABILITY = 86000038,
  GENERAL_DEBT = 86000028,
  INDEBTEDNESS_OF_EQUITY = 86000029,
  COVERAGE_RATIO_FOR_RECEIVABLES = 86000022,
  CREDIT_INDEBTEDNESS = 86000030,
  SHORT_TERM_DEBT_EBITDA = 86000035,
  NET_DEBT_EBITDA = 86000041,

  // Rotation
  ROTATION = 86000039,
  DEBT_TURNOVER_IN_DAYS = 86000031,
  STOCK_ROTATION_IN_DAYS = 86000032,
  TURNOVER_OF_LIABILITIES_IN_DAYS = 86000033,

  // Others
  OTHERS = 86000040,
  WILCOX_FORMULA = 86000034,
  THE_BASIC_INDICATOR_OF_THE_LIABILITIES_STRUCTURE = 86000017,
  THE_GOLDEN_RULE_OF_THE_BALANCE = 86000018,

  // CREDENDO
  RATIOS_AND_INDICATORS_CR = 86000080,
  LIQUIDITY_AND_WORKING_CAPITAL_CR = 86000081,
  PROFITABILITY_CR = 86000082,
  CASH_FLOW_MEASURES_CR = 86000083,
  SOLVENCY_CR = 86000084,
  DEBT_COVERAGE_CR = 86000085,
  ACTIVITY_RATIOS_CR = 86000086,
}

export enum PeriodUnit {
  YEAR = 38000003,
  QUARTER = 38000002,
  MONTH = 38000001,
  DAY = 38000004,
}

export enum Currency {
  GBP = 4000058,
  PLN = 4000130,
  AED = 4000002,
  AFN = 4000004,
  ALL = 4000005,
  AMD = 4000006,
  ANG = 4000007,
  AOA = 4000008,
  ARS = 4000011,
  AUD = 4000012,
  AWG = 4000013,
  AZN = 4000015,
  BAM = 4000016,
  BDT = 4000018,
  BGN = 4000020,
  BHD = 4000021,
  BIF = 4000022,
  BMD = 4000023,
  BND = 4000024,
  BOB = 4000025,
  BRL = 4000026,
  BSD = 4000027,
  BWP = 4000029,
  SBD = 4000141,
  SCR = 4000142,
  EUR = 4000055,
  USD = 4000172,
  BBD = 4000017,
  BTN = 4000028,
  BYN = 4000211,
  BZD = 4000032,
  CAD = 4000033,
  CDF = 4000034,
  CHF = 4000035,
  CLF = 4000036,
  CLP = 4000037,
  CNY = 4000038,
  COP = 4000039,
  CRC = 4000040,
  CUP = 4000042,
  CVE = 4000043,
  CZK = 4000045,
  DJF = 4000046,
  DKK = 4000047,
  DOP = 4000048,
  DZD = 4000049,
  EGP = 4000052,
  ERN = 4000053,
  ETB = 4000054,
  FJD = 4000056,
  FKP = 4000057,
  GEL = 4000059,
  GHS = 4000207,
  GIP = 4000061,
  GMD = 4000062,
  GNF = 4000063,
  GTQ = 4000065,
  GYD = 4000067,
  HKD = 4000068,
  HNL = 4000069,
  HRK = 4000070,
  HTG = 4000071,
  HUF = 4000072,
  IDR = 4000073,
  ILS = 4000074,
  INR = 4000075,
  IQD = 4000076,
  IRR = 4000077,
  ISK = 4000078,
  JMD = 4000079,
  JOD = 4000080,
  JPY = 4000081,
  KES = 4000082,
  KGS = 4000083,
  KHR = 4000084,
  KMF = 4000085,
  KPW = 4000086,
  KRW = 4000087,
  KWD = 4000088,
  KYD = 4000089,
  KZT = 4000090,
  LAK = 4000091,
  LBP = 4000092,
  LKR = 4000093,
  LRD = 4000094,
  LSL = 4000095,
  LYD = 4000098,
  MAD = 4000099,
  MDL = 4000100,
  MGA = 4000101,
  MKD = 4000103,
  MMK = 4000104,
  MNT = 4000105,
  MOP = 4000106,
  MUR = 4000109,
  MVR = 4000110,
  MWK = 4000111,
  MXN = 4000112,
  MXV = 4000114,
  MYR = 4000115,
  MZN = 4000209,
  NAD = 4000117,
  NGN = 4000118,
  NIO = 4000119,
  NOK = 4000120,
  NPR = 4000121,
  NZD = 4000122,
  OMR = 4000123,
  PAB = 4000124,
  PEN = 4000125,
  PGK = 4000127,
  PHP = 4000128,
  PKR = 4000129,
  PYG = 4000132,
  QAR = 4000133,
  RON = 4000135,
  RSD = 4000136,
  RUB = 4000137,
  RWF = 4000139,
  SAR = 4000140,
  SDG = 4000206,
  SEK = 4000145,
  SGD = 4000146,
  SHP = 4000147,
  SLL = 4000150,
  SOS = 4000151,
  SRD = 4000152,
  SSP = 4000205,
  STN = 4000204,
  SVC = 4000155,
  SYP = 4000156,
  SZL = 4000157,
  THB = 4000158,
  TJS = 4000160,
  TMT = 4000210,
  TND = 4000162,
  TOP = 4000163,
  TRY = 4000166,
  TTD = 4000167,
  TWD = 4000168,
  TZS = 4000169,
  UAH = 4000170,
  UGX = 4000171,
  UYU = 4000174,
  UZS = 4000175,
  VES = 4000203,
  VND = 4000177,
  VUV = 4000178,
  WST = 4000179,
  XAF = 4000180,
  XCD = 4000181,
  XOF = 4000182,
  XPF = 4000183,
  YER = 4000184,
  ZAR = 4000187,
  ZMW = 4000208,
  XDR = 4000212,
}

export enum CurrencyTable {
  A = 280000001,
  B = 280000002,
  C = 280000003,
  EUR = 280000004,
  PLN = 280000005,
  HUF = 280000006,
}

export enum DocumentType {
  BOND = 27000001,
  BOND_ANNEX = 27000002,
  CONTRACT = 27000003,
  CONTRACT_ANNEX = 27000004,
  POLICY = 27000005,
  INVOICE = 27000006,
  BOND_PORTAL = 27000007,
  CLAIM_VERSION = 27000008,
  LIMIT = 27000010,
  INVOICE_PAYMENT_CONFIRMATION = 27000036,
  INTERNAL_CLAIM_MEMO = 27000059,
  COURT_INSTRUCTION_PROMISSORY_NOTE = 27000088,
  COLLECTION_ORDER_FOR_AGENCY = 27000090,
  COLLECTION_HISTORY = 27000096,
  BROKER_RELATION = 27000097,
  OFFER = 27000098,
  POLICY_DRAFT = 27000100,
  BROKER_CONTRACT = 27000101,
  COLLECTION_CASE_REGISTRATION_FOR_ARCHIVES = 27000103,
  INTEREST_NOTE_POLICY = 27000105,
  COLLECTION_DEFAULT_EMAIL = 27000106,
  GENERAL_CONTRACT = 27000111,
  KUKE_POLICY = 27000113,
  WZ = 27000114,
  WZKP = 27000115,
  WZR = 27000116,
  WZRU = 27000117,
  DEC_AGREE_SUBIN = 27000133,
  FRAMEWORK_AGRE = 27000135,
  POLICY_ANNEX = 27000144,
  POLICY_OFFER = 27000158,
  POLICY_CESSION = 27000160,
  POLICY_TERMINATION = 27000186,
  POLICY_RIGHTS_CESSION = 27000167,
  COMPANY_PAYMENT_BALANCE_REQUEST = 27000164,
  COMPANY_PAYMENT_HISTORY_REQUEST = 27000165,
  COMPANY_FINANCIAL_DATA_REQUEST_EMAIL = 27000169,
  POLICY_OTHER_SCRIPT = 27000168,
  POLICY_CESSION_ATTACHMENT = 27000173,
  POWER_OF_ATTORNEY_OFFER = 27000228,
  APP_RISK_ASSESSMENT = 27000236,
  COMMISSION_REFUND_REQUEST = 27000184,
  CLAIM_CONFIRMATION_REGISTRATION_BALANCE = 27000277,
  BONUS_DOCUMENTS = 27000324,
  POLICY_DECISION_SHEET = 27000344,
  DSP_COVER_TEMP = 27000300,
  FRAMEWORK_AGREE = 27000135,
  LEADING_BOARD_EMAIL = 27000244,
  POLICY_COVER_LETTER = 27000255,
  B_RELATION_COVER_LETTER = 27000350,
  UNIT_COURT_AGREEMENT_PROPOSITION = 27000351,
  GENERIC_APPENDIX = 27000360,
  PBG_ATTACHMENTS = 27000362,
  UNDERWRITING_ASSESSMENT = 27000367,
  BONDING_CONTRACT_DRAFT = 27000407,
}

export enum GeneratedDocumentType {
  DF_MONITORING = 27000189,
}

export enum RepositoryDocumentType {}

export enum KukeRepoOperation {
  PROPOSALS = 0,
  WORDINGS = 1,
  SIGNIGS = 2,
}

export enum CompanyContactNoteRole {
  CREDITOR = 300000005,
  DEBTOR = 300000006,
}

export enum BusinessObjectType {
  CONTRACT_VERSION = 19000001,
  BOND_VERSION = 19000002,
  COMPANY = 19000003,
  CONTRACT = 19000004,
  BOND = 19000005,
  POLICY = 19000006,
  POLICY_VERSION = 19000007,
  INVOICE = 19000008,
  CLAIM = 19000009,
  CLAIM_VERSION = 19000010,
  LIMIT_REQUEST = 19000011,
  QUOTATION = 19000012,
  LIMIT_DECISION = 19000013,
  CONTACT_NOTE = 19000014,
  CONTRACT_RENEWAL = 19000016,
  POLICY_RENEWAL = 19000015,
  CLIENT_TEMPLATE = 19000017,
  POLICY_INQUIRY_VERSION = 19000018,
  USER = 19000019,
  BROKER_CONTRACT = 19000020,
  MASTER_POLICY = 19000021,
  LIMIT = 19000022,
  LIMIT_COVER_PLUS = 19000023,
  POLICY_INQUIRY = 19000024,
  COLLECTION = 19000025,
  COLLECTION_VERSION = 19000026,
  INVOICE_PAYMENT = 19000027,
  SALES_LEAD = 19000028,
  EXTERNAL_PROVIDER = 19000029,
  EXTERNAL_PROVIDER_VERSION = 19000030,
  CLAIM_CALCULATION = 19000031,
  CLAIM_INDEMNIFICATION = 19000032,
  MALUS_REQUEST = 19000033,
  COLLECTION_ACCOUNTED_COSTS = 19000050,
  COLLECTION_ESTIMATED_COSTS = 19000034,
  COLLECTION_REQUEST = 19000036,
  THIRD_PARTY = 19000037,
  LIMIT_LIST = 19000038,
  OFFER = 19000039,
  BONDING_CONTRACT_INQUIRY = 19000040,
  BONDING_CONTRACT_INQUIRY_VERSION = 19000041,
  BROKER_CONTRACT_VERSION = 19000042,
  CLAIM_INDEMNIFICATION_VERSION = 19000043,
  CESSION = 19000044,
  CESSION_VERSION = 19000060,
  BROKER_CONTRACT_RELATION = 19000045,
  BROKER_CONTRACT_RELATION_VERSION = 19000046,
  BONDING_CONTRACT_OFFER = 19000048,
  TREATY = 19000049,
  BONUS_REQUEST = 19000057,
  LIMIT_LIST_VERSION = 19000062,
  EDGE_OFFER = 19000063,
  CLAIM_LAWSUIT = 19000064,
  POLICY_DRAWDOWN = 19000066,
  COMPANY_RATING_VERSION = 19000068,
  BI_REPORT = 19000069,
  REPORTING_PERIOD = 19000070,
  COUNTRY_CATEGORY_LIMIT_VERSION = 19000076,
  USER_HOLIDAY = 19000075,
  COUNTRY_RATING_VERSION = 19000077,
  CLAIM_RECOVERY_COMMISSION = 19000079,
  INVOICE_ITEM = 19000083,
}

export const POLICY_TYPES = [
  BusinessObjectType.MASTER_POLICY,
  BusinessObjectType.POLICY_INQUIRY,
  BusinessObjectType.POLICY_INQUIRY_VERSION,
  BusinessObjectType.POLICY,
  BusinessObjectType.POLICY_VERSION,
  BusinessObjectType.OFFER,
  BusinessObjectType.CESSION,
  BusinessObjectType.CESSION_VERSION,
];

export const BONDING_TYPES = [
  BusinessObjectType.CONTRACT,
  BusinessObjectType.CONTRACT_VERSION,
  BusinessObjectType.BOND,
  BusinessObjectType.BOND_VERSION,
  BusinessObjectType.QUOTATION,
  BusinessObjectType.MALUS_REQUEST,
  BusinessObjectType.BONUS_REQUEST,
  BusinessObjectType.BONDING_CONTRACT_INQUIRY,
  BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION,
  BusinessObjectType.BONDING_CONTRACT_OFFER,
];

export enum Action {
  // Bond
  ACCEPT_BOND_REQUEST = 25000001,
  REJECT_BOND_REQUEST = 25000002,
  REOPEN_BOND_REQUEST = 25000003,
  ISSUE_BOND = 25000004,
  CANCEL_BOND = 25000005,
  RELEASE_BOND = 25000006,

  // Contract
  ACCEPT_CONTRACT_REQUEST = 24000001,
  REJECT_CONTRACT_REQUEST = 24000002,
  RESTART_CONTRACT_REQUEST = 24000003,
  ISSUE_CONTRACT = 24000004,
  ACTIVATE_CONTRACT = 24000005,
  CANCEL_CONTRACT = 24000006,
  REOPEN_CONTRACT = 24000007,
  SET_CONTRACT_WITHOUT_RESULT = 24000008,

  // Policy contract
  POL_ACT = 35000001,
  POL_DACT = 35000002,

  // Quotation
  CLOSE_QUOTATION = 63000004,

  // Treaty
  // todo update ids when dictionaries set
  ACTIVATE_TREATY = 79000001,
  DEACTIVATE_TREATY = 79000002,

  //
  OBJ_REJ = 35000004,
  OBJ_UER = 35000005,
  USER_DEACTIVATE = 113000002,

  // Broker relation
  ACTIVATE_BROKER_RELATION = 145000001,
  CANCEL_BROKER_RELATION = 145000002,
}

export enum ContractCompanyRole {
  CLIENT = 9000001,
  BROKER = 9000002,
  MAIN_CLIENT = 9000003,
  BUYER = 9000004,
  BENEFICIARY = 9000005,
}

export enum BondingContractDocumentType {
  REQUEST_FROM = 126000101,
  REQUEST_FOR_APPROVAL_KRU_KPUE_MANAGEMENT_BOARD_REINSURANCE = 126000106,
  RESOLUTION_TO_THE_REQUEST = 126000107,
  OFFER = 126000108,
  BROKERAGE_AGREEMENT_FOR_THE_GUARANTEE = 126000109,
  PROMISE = 126000110,
  CONTRACT = 126000111,
  CONTRACT_ANNEX = 126000112,
  OTHER_DOCUMENTS = 126000113,
  OTHER = 126000006,
  SPECIAL_TEXT = 126000007,
}

export enum DictionaryPropertyType {
  BOND_VALUE_EDITABLE = 41000001,
  COMMISSION_VALUE = 41000002,
  COMMISSION_PERCENTAGE = 41000003,
  COUNTRY_RATING = 41000004,
  COUNTRY_OECD = 41000005,
  DELIVERY_FEE = 41000006,
  COUNTRY_BOND = 41000013,
  LANGUAGE_BOND = 41000014,
  VALID_PERIOD = 41000015,
  CUSTOM_FIELD___DICT_NAME = 41000022,
  CUSTOM_FIELD___DEFAULT_VALUE = 41000031,
  CUSTOM_FIELD___DISABLED = 41000032,
  CUSTOM_FIELD___VIRTUAL_DEFINITION = 41000078,
  FIELD_REQUIRED = 41000023,
  VISIBILITY = 41000024,
  FINANCIAL_STANDING_OBLIGED = 41000033,
  CUSTOM_FIELD___MIN_VALUE = 41000035,
  CUSTOM_FIELD___MAX_VALUE = 41000036,
  CUSTOM_FIELD___HIDDEN = 41000044,
  CUSTOM_FIELD_HIDDEN_IN_PORTAL = 41000048,
  CUSTOM_FIELD__OPTIONAL = 41000072,
  POLICY_INQUIRY_OFFER_REJECTION_REASON_TEXT_REQUIRED = 41000046,
  TASK_BO_NOT_MANDATORY = 41000047,
  ALLOW_MULTIPLE_TREATIES = 41000050,
  ALLOW_NON_INSURERS_IN_TREATY = 41000051,
  EXCLUSION_CLAUSE = 41000049,
  PROFILE_QUOTA_DATATYPE = 41000058,
  BROKER_PROVISION_CALCULATION = 41000059,
  LIMIT_CONDITION_TYPE = 41000067,
  CUSTOM_FIELD__LIMIT_AUTOMATYCZNY = 401000020,
  BOND_PRODUCT_CODE = 41000099,
  MANY_ROLES_FROM_CATEGORY = 41000100,
}

export enum RepoDocumentCategory {
  INTERNAL = 169000001,
  BENEFICIARY = 169000002,
  EXTERNAL = 169000003,
}

export enum QuotationStatus {
  QUOTATION_DRAFT = 62000001,
  QUOTATION_READY = 62000002,
  QUOTATION_PENDING = 62000003,
  QUOTATION_CLOSED = 62000004,
  QUOTATION_EXPIRED = 62000005,
  QUOTATION_CONCLUDED = 62000006,
  QUOTATION_REPORT_TO_CONFIRM = 62000007,
  QUOTATION_REPORT_WAITING = 62000008,
  QUOTATION_REJECTED = 62000009,
}

export enum InvoiceCategory {
  INVOICE = 139000001,
  BROKER = 139000002,
  INTEREST = 139000003,
}

export enum FINoteCategory {
  REINSURANCE = 139000004,
  INTERMEDIARY_INVOICE = 139000005,
}

export type FinancialDocumentCategory = InvoiceCategory | FINoteCategory;

export enum InvoicePaymentStatus {
  CREATED_MANUALLY = 467000001,
  IMPORTED = 467000002,
  CONFIRMATION_SENT = 467000003,
}

export enum InvoiceStatus {
  ISSUED = 51000001,
  SENT = 51000002,
  SENT_MANUALLY = 51000013,
  MIGRATION = 51000003,
  COMM_GESTURE = 51000004,
  PAID = 51000005,
  DRAFT = 51000006,
  TO_BE_REMOVED = 51000007,
  TO_BE_REMOVED_PRESERVING_ITEMS = 51000008,
  TO_BE_PAID = 51000009,
  IN_COLLECTION = 51000010,
  OVERDUE = 51000011,
  WRITTEN_OFF = 51000012,
  BALANCED = 51000015,
}

export enum InvoiceItemStatus {
  AUTOMATIC = 71000001,
  IMPORT = 71000002,
  DO_NOT_INVOICE = 71000003,
  CANCELLED = 71000004,
  MANUAL = 71000005,
}

export enum InvoiceItemType {
  PROVISSION = 53000001,
  DEED_FEE = 53000002,
  MANAGEMENT_FEE = 53000003,
  NON_RECURRENT_MANAGEMENT_FEE = 53000004,
  MINIMAL_ANNUAL_COMMISSION = 53000005,
  DELIVERY_FEE = 53000006,
  FACILITY_FEE = 53000007,
  PROMISE_FEE = 53000008,
  FRONTING_FEE = 53000009,
}

export enum InvoiceItemSubtype {
  PREV = 59000001,
  REGUL = 59000002,
  INSURANCE_PREMIUM = 59000006,
  PREMIUM_DOMESTIC_NN = 59000026,
  PREMIUM_EXPORT_NN = 59000027,
}

export enum PolicyContractVersionStatus {
  REQUEST = 30000020,
  REQUEST_ACCEPTED = 30000021,
  REQUEST_REJECTED = 30000022,
  PROLONGATION_REQUEST = 30000023,
  PROLONGATION_REQUEST_EXTRA_RIGHTS = 30000051,
  PROLONGATION_REQUEST_ACCEPTED = 30000024,
  PROLONGATION_REQUEST_REJECTED = 30000025,
  ISSUED = 30000032,
  ACTIVATED = 30000026,
  ACTIVATED_LOCKED = 30000027, // only CR
  // CONTRACT_TO_BE_CANCELLED = 30000028,
  USER_ERROR = 30000034,
  VERSION_REPLACED = 30000035,

  TERMINATION_REQUEST = 30000040,
  TERMINATION_REJECTED = 30000041,
  TERMINATION_REQUEST_ACCEPTED = 30000042,
  TERMINATION_ACTIVATED = 30000046,
  TERMINATION_RESIGNATION = 30000047,

  // business statuses not stored in db
  BS_ACTIVE = 30000029,
  BS_TERMINATED_CLOSED = 30000030,
  BS_OBSOLETE = 30000031,
  BS_ACTIVE_LOCKED = 30000033, // only CR
  BS_EXPIRED = 30000036,
  BS_EXPIRED_LOCKED = 30000037, // only CR
}

export const POLICY_CONTRACT_ACTIVATED_STATUSES: PolicyContractVersionStatus[] = [
  PolicyContractVersionStatus.ACTIVATED,
  PolicyContractVersionStatus.ACTIVATED_LOCKED,
  PolicyContractVersionStatus.TERMINATION_ACTIVATED,
  PolicyContractVersionStatus.USER_ERROR,
];

export const POLICY_CONTRACT_TERMINATION_STATUSES: PolicyContractVersionStatus[] = [
  PolicyContractVersionStatus.TERMINATION_REQUEST,
  PolicyContractVersionStatus.TERMINATION_ACTIVATED,
  PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
];

export const POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES = [
  PolicyContractVersionStatus.REQUEST,
  PolicyContractVersionStatus.REQUEST_ACCEPTED,
  PolicyContractVersionStatus.TERMINATION_REQUEST,
  PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
];

export const POLICY_CONTRACT_ALL_LIVE_STATUSES_BEFORE_ISSUED = [
  PolicyContractVersionStatus.REQUEST,
  PolicyContractVersionStatus.REQUEST_ACCEPTED,
  PolicyContractVersionStatus.PROLONGATION_REQUEST,
  PolicyContractVersionStatus.PROLONGATION_REQUEST_ACCEPTED,
  PolicyContractVersionStatus.PROLONGATION_REQUEST_EXTRA_RIGHTS,
  PolicyContractVersionStatus.TERMINATION_REQUEST,
  PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
];

export enum PolicyContractVersionOrigin {
  KUKE_MIGRATION = 360000001,
  SI_KUKE = 360000002,
  OFFER = 360000004,
  POLICY_VERSION = 360000005,
}

export enum TreatyStatus {
  ACT = 74000001,
  INA = 74000002,
}

export enum TaskType {
  VALIDATE_CONTRACT_REQUEST = 18000001,
  ISSUE_CONTRACT = 18000002,
  REVIEW_CONTRACT = 18000003,
  VALIDATE_BOND_REQUEST = 18000004,
  ISSUE_BOND = 18000005,
  REDUCE_LIMIT = 18000006,
  ACCEPT_LIMIT = 18000007,
  REVIEW_CONTACT_NOTE = 18000008,
  SALES_LEAD = 18000009,
  REMINDER = 18000010,
  SECOND_LEVEL = 18000011,
  CHECK_CLAIM_THREAT = 18000012,
  BROKER_ALERT = 18000015,
  EXPECTS_FINANCIAL_DATA = 18000029,
  PREPARE_POLICY_ANNEX = 18000030,
  POLICY_UNLOCK = 18000074,
  POLICY_LOCK = 18000077,
  // KUKE
  ZPO5 = 18001006,
  CONFIRM_TO_OR_DD_DECLARATION = 18020047,
}

export enum TaskStatus {
  OPEN = 17000001,
  CLOSED = 17000002,
  CLOSED_SUPPRESSED = 17000004,
  CANCELLED = 17000003,
}

export enum TaxPayer {
  CLIENT = 414000001,
  SELLER = 414000002,
}

export enum ClientKnownSinceTimeElapsed {
  LESS_THAN = 87000001,
  MORE_THAN = 87000002,
}

export enum ClientSegment {
  PLATINIUM = 101000001,
  GOLD = 101000002,
  SILVER = 101000003,
  BRONZE = 101000004,
}

export enum OtherCompanyRole {
  GUARANTOR = 433000001,
  EXPORTER = 433000002,
  IMPORTER = 433000003,
  BUYER = 433000004,
  DEBTOR = 433000005,
  ULTIMATE_USER = 433000006,
  MAIN_CONTRACTOR = 433000007,
  SPONSOR = 433000008,
  INVESTOR = 433000009,
  TARGET_COMPANY = 433000010,
  PROJECT_COMPANY = 433000011,
}

export enum PolicyContractType {
  // credendo policy products
  TOP_UP = 31000001,
  EXCESS_OF_LOSS = 31000002,
  EXCESS_OF_LOSS_PARTNER = 31000003,
  CAPTIVE = 31000004,
  SINGLE_RISK = 31000005,
  TURNOVER = 31000006,

  // Mehib ST products
  EXPORT_RECEIVABLES_C = 31001001,
  FACTORING_CF = 31001002,

  // Mehib MLT products
  BUYER_CREDIT_V = 31001003,
  SUPPLIER_CREDIT_S = 31001004,
  SUPPLIER_CREDIT_DISCOUNTING_KV = 31001005,
  FOREIGN_INVESTMENT_B = 31001006,
  TIED_AID_TAI = 31001007,
  PRESHIPMENT_RISK_G = 31001008,

  // kuke KOM policy products
  KUKE_KOM_OP = 31000116,
  KUKE_KOM_EP = 31000117,
  KUKE_KOM_FE = 31000119,
  KUKE_KOM_FK = 31000120,
  KUKE_KOM_SP = 31000121,

  // kuke GSP policy products
  KUKE_GSP_PBG = 31000223,

  // ECG products
  GLOBAL_RISK = 31002001,
  SELECTIVE_RISK = 31002002,
  ECG_TOP_UP = 31002003,
}

// todo: fix other active usages
export enum ProductLimitListStatus {
  ACTIVE = 218000001,
  INACTIVE = 218000002,
}

export enum ProductLimitListVersionStatus {
  ACTIVE = 358000001,
  INACTIVE = 358000002,
  ACTIVATED = 358000003,
  DEACTIVATED = 358000004,
}

export enum PolicyMonitoringType {
  GREEN = 417000001, // Mehib
  MLT = 417000002, // Mehib
  POLICY = 417000003, // Credendo
}

export enum CompanyType {
  COMPANY = 102000001,
  INSURANCE = 102000002,
  BROKER = 102000003,
  BUSINESSUNIT = 102000004,
  BANK = 102000005,
  SAVING_BANK = 102000006,
  AGENT = 102000007,
  CENTRAL = 102000008,
  FARM = 102000012,
  BRANCH = 102000013,
  BROKER_BRANCH = 102000014,
  FRONTER = 102000021,
  ECA = 102000020,
  LAW_FIRM = 102000015,
  FACTORING = 102000009,
  BUSINESS_ADVISOR = 102000016,
  BAILIFF = 102000018,
  THIRD_PARTY = 102000019,
  GOVERNMENT = 102000010,
  CIVIC_BODY = 102000011,
  COURT_OF_LAW = 102000017,
  SIGNALER = 102000022,
}

export enum CompanyDocumentType {
  BROKER_LOGO = 123000020,
  FINANCIAL_DATA_REQUEST_LETTER = 123000216,
  MANAGMENT_OR_KRU_REQUEST = 123000217,
  KPUE_REQUEST = 123000218,
  RIF = 123000219,
}

export enum SalesDocumentType {
  BOARD_APPLICATIONS = 129000030,
  POLICY_INQUIRY = 129000032,
}

export enum ClaimDocumentType {
  INVOICES = 128000100,
  SUBJECT_OF_CONTRACT_STATEMENT = 128000101,
  NO_DISPUTE_STATEMENT = 128000102,
  DISPUTED_AMOUNT_STATEMENT = 128000103,
  DEBT_ACKNOWLEDGEMENT = 128000104,
  COURT_DEBT_ACKNOWLEDGEMENT = 128000105,
  RECEPTION_ACKNOWLEDGEMENT = 128000106,
  COLLATERALS = 128000107,
  CREDIT_LIMIT_CONDITIONS_STATEMENT = 128000108,
  DOCS_INSOLVENCY_DOCS_PROCEEDINGS = 128000109,
  CORRESPONDENCE_DISCOUNTS_CLAIMS_COMPENSATIONS = 128000110,
  ACCOUNT_TRACK_RECORD = 128000111,
  STATEMENT_OF_ACCOUNT_TRACK_RECORD = 128000112,
  DISCRETIONARY_COVER_STATEMENT = 128000113,
  FURTHER_DELIVERIES_STATEMENT = 128000115,
  ORDERS_AND_CONFIRMATIONS = 128000116,
  PAYMENT_CORRESPONDENCE = 128000117,
  SUBINSUREDS_STATEMENTS = 128000114,
  CUSTOM_DOCUMENTS = 128000118,
  CONTRACT = 128000119,
  CONDITIONS_OF_SALESD = 128000120,
  COUNTRY_OF_ORIGIN = 128000121,
  POWER_OF_ATTORNEY = 128000122,
  CORR_IN_OUT = 128000005,
  OTHER = 128000006,
  INCURRED_COSTS_CONFIRMATION = 128000123,
  LOSS_OCCURENCE_CONFIRMATION = 128000124,
  PRODUCTION_COSTS_RECORDS = 128000125,
  WARRANTIES = 128000126,
  CORRESPONDENCE_PRELIMINARY_ASSESSMENT = 128000127,
  COMPENSATION_CLAIM_BONDING = 128000129,
  CORRESPONDENCE_REG_LIQUIDATION_OF_CLAIM = 128000130,
  APPEAL_FROM_A_DECISION = 128000131,
  KUKE_ANSWER_TO_APPEAL = 128000132,
  AGREEMENT = 128000133,
  CLAIM_DECISION_SENT_TO_CLIENT = 128000134,
  INTERNAL_CONSULTATION = 128000135,
  DOCUMENTATION_REG_CLAIM_DECISION = 128000136,
  CORRESPONDENCE_WITH_BANK_FACTOR_OTHERS = 128000137,
  OTHER_DOCUMENTS = 128000138,
  CORRESPONDENCE_WITH_EMBASSY = 128000139,
  LETTER_EXPLAINING_THE_CLAIM = 128000140,
}

export enum RepositoryDocumentType {
  INVOICE_CREDIT_NOTE = 469000001,
  EXTERNAL_PROVIDER_CORRESPONDENCE = 469000008,
  BOARD_COMMITTEE_DECISION = 469000026,
  DOCUMENTATION_REGARDING_THE_DECISION_ON_LIQUIDATION_OF_THE_CLAIM = 469000046,
  OTHER_DOCUMENTS = 469000054,
  INVOICES_DESCRIPTION = 469000055,
  CORRESPONDENCE_BANK_FACTOR_OTHER_AUTHORIZED_DUE_TO_ASSIGNMENT = 469000057,
}

export enum CollectionDocumentType {
  INTERNAL_DOCUMENTS = 205000016,
  INTERNAL_CORRESPONDENCE = 205000018,
  COMPLAINS = 205000019,
  SKIP_TRACING = 205000020,
  EXTERNAL_PROVIDER_CORRESPONDENCE_IN = 205000022,
}

export enum AddressType {
  REGISTRATION = 11000001,
  CORRESPONDENCE = 11000002,
  EXECUTION = 11000003,
  BOND_INVOICING = 11000004,
  OTHER = 11000005,
  // KUKE
  LEGAL_RESIDENCE = 11000006,
}

export enum SalesLeadStatus {
  NEW = 105000001,
}

export enum SalesLeadProduct {
  BONDING = 107000002,
  CREDIT_INSURANCE = 107000003,
}

export namespace ContractType {
  export const INDIVIDUAL = 108000001;
  export const BOOSTER = 108000002;
  export const OLIMPIA = 108000003;
  export const ASTIMM = 108000004;
  export const VERSPIEREN = 108000005;
  export const BANK_BOOSTER = 108000006;
  export const IIG = 108000007;
  export const INDIVIDUAL_BOOSTER = 108000008;

  // kuke products
  export const KOM_GENERAL_CONTRACT = 108000100; // Umowa kontraktowa - kraj
  export const KOM_INDIVIDUAL_CONTRACT = 108000104; // Umowa środowiskowa - kraj
  export const GSP_GENERAL_CONTRACT = 108000101; // Umowa generalna - eksport
  export const GSP_INDIVIDUAL_CONTRACT = 108000102; // Umowa indywidualna - eksport
  export const GSP_CREDIT_PAYMENT = 108000105; // Umowa spłaty kredytu - eksport
  export const GSP_GENERAL_PAYMENT = 108000106; //  Umowa generalna płatnicza
  export const GSP_INDIVIDUAL_PAYMENT = 108000107; // Umowa indywidualna płatnicza

  // ECG
  export const ECG_INDIVIDUAL = 108000201;

  export const BOOSTER_TYPES = [BOOSTER, BANK_BOOSTER];
  export const MGA_TYPES = [OLIMPIA, ASTIMM, VERSPIEREN, IIG];
}

export namespace BondDifferentPriceType {
  export const PERCENT = 191000001;
  export const PREMIUM = 191000002;
  export const PREMIUM_ERROR = 191000003;
  export const PREMIUM_PERIOD = 191000004;

  export const PERCENT_TYPES = [PERCENT];
  export const PREMIUM_TYPES = [PREMIUM, PREMIUM_ERROR, PREMIUM_PERIOD];
}

export enum BondingExternalContractType {
  GENERAL = 488000001,
  CREDIT = 488000002,
  FACTORING = 488000003,
  DELIVERY = 488000004,
}

export enum BondType {
  CONCESSION = 1000001,
  COUNTERGUARANTEE = 1000002,
  CUSTOMS = 1000003,
  FRONTING_LEGAL_BONDS = 1000004,
  GENERAL_CONTRACTUAL_BONDS = 1000005,
  OTHER_CONTRACTUAL_BONDS = 1000006,
  OTHER_LEGAL_BONDS = 1000007,
  PARTICIPATION_CONTRACTUAL_BONDS = 1000008,
  PARTICIPATION_LEGAL_BONDS = 1000009,
  TIMBER = 1000010,
  TRANSPORT = 1000011,
  FRONTING_CONTRACTUAL_BONDS = 1000012,
  ANVORAUSZAHLUNG = 1000013,
  VERTRAGSERFULLUNG = 1000014,
  VERBRAUCHERSICHERHEIT = 1000015,
  MÄNGELANSPRUCHE = 1000016,
  AUSFÜHRUNG_UND_MANGELANSPRUCHE = 1000017,
  ZOLL = 1000018,
  WARENLIEFERUNGEN = 1000019,
  BIETUNG = 1000020,
  MIETBURGSCHAFT = 1000021,
  ARBEITZEITKONTEN = 1000022,
  BAUHANDWERKERFORDERUNGEN_GEMASS_ART = 1000023,
  NUTZUNG_BENUTZUNG = 1000024,
  ABFALLVERBRINGUNG = 1000025,
  REKULTIVIERUNG = 1000026,
  BID_BOND_IT = 1000042,
  PERFORMANCE_BOND_IT = 1000043,

  WADIUM_ON_LINE = 1001002,
  NALEZYTE_WYKONANIE_KONTRAKTU = 1001003,
  USUNIECIA_WAD_I_USTEREK = 1001004,
  NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK = 1001005,
  CELNA = 1001006,
  ZWROT_ZALICZKI = 1001007,
  PLATNICZA = 1001008,
  REGWARANCJA_WADIUM_ON_LINE = 1001011,
  REGWARANCJA_NALEZYTE_WYKONANIE_KONTRAKTU = 1001012,
  REGWARANCJA_USUNIECIA_WAD_I_USTEREK = 1001013,
  REGWARANCJA_NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK = 1001014,
  REGWARANCJA_CELNA = 1001015,
  REGWARANCJA_ZWROT_ZALICZKI = 1001016,
  REGWARANCJA_PLATNICZA = 1001017,
  PROMESA_WADIUM = 1001018,
  PROMESA_WADIUM_ON_LINE = 1001002,
  PROMESA_NALEZYTE_WYKONANIE_KONTRAKTU = 1001021,
  PROMESA_USUNIECIA_WAD_I_USTEREK = 1001022,
  PROMESA_NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK = 1001023,
  PROMESA_CELNA = 1001024,
  PROMESA_ZWROT_ZALICZKI = 1001025,
  PROMESA_PLATNICZA = 1001026,
  KOSZTY_USUNIECIA_ODPADOW = 1001027,
  ZARZADZANIE_SKLADOWISKIEM_ODPADOW = 1001028,
  SZKODY_SRODOWISKOWE = 1001029,
  MIWDZYNARODOWE_PRZEMIESZCZANIE_ODPADOW = 1001030,
  REGWARANCJA_KOSZTY_USUNIECIA_ODPADOW = 1001031,
  REGWARANCJA_ZARZADZANIE_SKLADOWISKIEM_ODPADOW = 1001032,
  REGWARANCJA_SZKODY_SRODOWISKOWE = 1001033,
  REGWARANCJA_MIEDZYNARODOWE_PRZEMIESZCZANIE_ODPADOW = 1001034,
  PROMESA_KOSZTY_USUNIECIA_ODPADOW = 1001035,
  PROMESA_ZARZADZANIE_SKLADOWISKIEM_ODPADOW = 1001036,
  PROMESA_SZKODY_SRODOWISKOWE = 1001037,
  PROMESA_MIEDZYNARODOWE_PRZEMIESZCZANIE_ODPADOW = 1001038,
  WADIUM = 1001039,
  REGWARANCJA_WADIUM = 1001040,
  SPLATY_KREDYTU = 1001041,
  KUKE_PERFORMANCE_BOND = 1001003,
  CREDIT_PAYMENT = 1001041,
  DELIVERY_PAYMENT = 1001042,
  FACTORING_PAYMENT = 1001043,
  CREDIT_PAYMENT_FACTORING = 1001045,
  KUKE_DELIVERY_PRODUCTION_PAYMENT = 1001044,
  CREDIT_PAYMENT_ENERGETIC = 1001046,
  DELIVERY_PAYMENT_ENERGETIC = 1001048,
  FACTORING_PAYMENT_ENERGETIC = 1001047,
  DELIVERY_CHAIN_PAYMENT_ENERGETIC = 1001049,
  KUKE_BID_BOND_ONLINE = 1001002,
  KUKE_DELIVERY_PRODUCTION_PAYMENT_ENERGETIC = 1001050,
}

export enum BondSubType {
  WARENLIEFERUNGEN_60_TAGE = 34000072,
  TECHNICAL_USER_TEMPLATE = 34000073,
  TECHNICAL_USER_SPECIAL_TEXT = 34000074,
  BID_BOND_WITH_FIXED_PERCENTAGE = 34000118,
  BID_BOND_WITH_FREE_PERCENTAGE = 34000119,
  PERFORMANCE_BOND = 34000088,
  BE_PERFORMANCE_BOND = 34000148,
  PROMESSE = 34000015,
  TIMBER_SALES = 34000014,
}

export namespace ContractFees {
  export const FACILITY_FEE_UNIT: {[index: number]: FeeUnit} = {
    [ContractType.INDIVIDUAL]: 'PERCENT',
    [ContractType.BOOSTER]: 'FIXED',
  };
}

export enum ContractNoticeReason {
  CLIENT_TERMINATES = 121000001,
  INSURER_TERMINATES = 121000002,
  TERMINATION_AGREEMENT = 121000004,
  INSURER_TERMINATES_IMMEDIATELY = 121000005,
}

export enum NoticeReason {
  TERMINATIONART10 = 39000001,
  OTHERINSURER = 39000002,
  DAMAGE = 39000007,
  INSUFFICIENT_LIMIT_COVERAGE_CLAUSE_227 = 39000021,
}

export enum BankAccountType {
  STANDARD = 131000001,
  SEPA = 131000002,
}

export enum PolicyClauseType {
  OP_201601__516_FARMER_LIMIT = 52133112,
  OP_201801__516_FARMER_LIMIT = 52154108,
  SP_201705__516_FARMER_LIMIT = 52148039,

  OP_201601__520_FALCON_LIMIT = 52133017,
  OP_201801__520_FALCON_LIMIT = 52154030,
  SP_201706__520_FALCON_LIMIT = 52148040,
  FK_201611__5192_FALCON_LIMIT = 52149002,

  OP_201601__3000 = 52133111,
  OP_201801__3000 = 52154094,
  SP_201705__3000 = 52148037,

  OP_201801__209_1 = 52154039,
  OP_201801__209_2 = 52154040,
  OP_201801__209_3 = 52154041,
  OP_201801__209_4 = 52154042,
  OP_201801__229_1 = 52154043,
  OP_201801__229_2 = 52154044,
  OP_201801__229_3 = 52154045,
  OP_201801__229_4 = 52154046,
  OP_201801__249_1 = 52154047,
  OP_201801__249_2 = 52154048,
  OP_201801__249_3 = 52154049,
  OP_201801__249_4 = 52154050,

  OP_201601__209_1 = 52133081,
  OP_201601__209_2 = 52133082,
  OP_201601__209_3 = 52133083,
  OP_201601__209_4 = 52133084,
  OP_201601__219_1 = 52133085,
  OP_201601__219_2 = 52133086,
  OP_201601__219_3 = 52133087,
  OP_201601__219_4 = 52133088,
  OP_201601__229_1 = 52133089,
  OP_201601__229_2 = 52133090,
  OP_201601__229_3 = 52133091,
  OP_201601__229_4 = 52133092,
  OP_201601__239_1 = 52133093,
  OP_201601__239_2 = 52133094,
  OP_201601__239_3 = 52133095,
  OP_201601__239_4 = 52133096,
  OP_201601__249_1 = 52133097,
  OP_201601__249_2 = 52133098,
  OP_201601__249_3 = 52133099,
  OP_201601__249_4 = 52133100,

  PBG_2020__250 = 52160003,

  OP_2016_01__227 = 52133022,
  OP_2018_12__227 = 52154016,
  SP_2017_06__227 = 52148026,
}

export enum PolicyClauseCategory {
  STANDARD = 233000001,
  NON_STANDARD = 233000002,
  ADDITIONAL = 233000003,
}

export namespace PolicyInquiryStatus {
  export const POLICY_INQUIRY = 103000001;
  export const POLICY_INQUIRY_ACCEPTED = 103000002;
  export const POLICY_INQUIRY_REJECTED = 103000003;
  export const POLICY_INQUIRY_USER_ERROR = 103000003;
  export const POLICY_INQUIRY_REGISTERED = 103000005;
  export const POLICY_INQUIRY_EXPIRED = 103000006;
  export const POLICY_INQUIRY_WAITING_FOR_THE_REPORT = 103000007;
  export const POLICY_INQUIRY_VERIFICATION_OF_DATA = 103000008;
  export const POLICY_INQUIRY_CANCELLED = 103000009;
  export const POLICY_INQUIRY_NO_COMPANY_DATA = 103000010;
  export const POLICY_INQUIRY_CLOSED = 103000011;
  export const CLOSED_STATUSES = [
    POLICY_INQUIRY_CANCELLED,
    POLICY_INQUIRY_EXPIRED,
    POLICY_INQUIRY_CLOSED,
    POLICY_INQUIRY_USER_ERROR,
    POLICY_INQUIRY_REJECTED,
  ];
  export const PENDING_STATUSES = [
    POLICY_INQUIRY_REGISTERED,
    POLICY_INQUIRY_VERIFICATION_OF_DATA,
    POLICY_INQUIRY_NO_COMPANY_DATA,
    POLICY_INQUIRY_WAITING_FOR_THE_REPORT,
    POLICY_INQUIRY_ACCEPTED,
  ];
}

export enum PolicyInquiryCompanyRole {
  SUBINSURED = 130000001,
  BASE_INSURER = 130000002,
  CO_INSURER = 130000003,
}

export enum NumberType {
  TAXID = 144000001,
  NATIONALID = 144000002,
  REGISTRYNUMBER = 144000003,
}

export enum CompanyIdentifierType {
  EXTERNAL_ID = 208000001,
  LEGACY_ID = 208000002,
  EXT_INFO_ID = 208000004,
  BIC = 208000005,
  GSP_ID = 208000006,
  MDG_NUMBER = 208000009,
  NATIONAL_ID = 208000010,
  TAX_ID = 208000011,
  STATISTICAL_NUMBER = 208000012,
  GILDA_ID = 208000014,
  EXT_INFO_ID_2 = 208000013,
  SALESFORCE = 208000015,
  SCHUFA_ID = 208000016,
}

export enum CompanyStatus {
  DATA_CONSISTENT = 474000001,
  DATA_INCONSISTENT = 474000002,
}

export enum ClaimIndemnificationStatus {
  PREPARATION_FOR_PAYMENT = 261000001,
  SENT_FOR_PAYMENT = 261000002,
  PAID = 261000003,
  WAITING_FOR_FIRST_APPROVAL = 261000004,
  WAITING_FOR_SECOND_APPROVAL = 261000005,
  USER_ERROR = 261000006,
}

export namespace ClaimStatus {
  export const UNDER_ASSESSMENT = 77000001;
  export const WAITING_FOR_FIRST_APPROVAL = 77000002;
  export const REJECTED_CCOM = 77000003;
  export const APPROVED_CCOM = 77000004;
  export const WAITING_FOR_BANK_ACCOUNT_APPROVAL = 77000005;
  export const WAITING_FOR_PAYMENT = 77000006;
  export const PAID = 77000007;
  export const CLOSED = 77000008;
  export const USER_ERROR = 77000009;
  export const WAITING_FOR_SECOND_APPROVAL = 77000010;

  export const REGISTERED = 77000011;

  export const CLAIM_REGISTRED = 77001001;
  export const DOCUMENTS_APPLICATION = 77001002;
  export const DOCUMENTS_APPLICATION_COMPLIMENT = 77001003;
  export const DOCUMENTS_APPLICATION_REMAINDER = 77001004;
  export const CLAIM_ASSESMENT = 77001005;
  export const DOCUMENTS_ASSESMENT = 77001006;
  export const DECISION = 77001007;
  export const CLOSED_PAID = 77001008;
  export const CLOSED_REJECTED = 77001009;
  export const CLOSED_DEBT_PAID = 77001010;
  export const CLOSED_CLAIM_REATRETED = 77001011;
  export const KUKE_USER_ERROR = 77001012;
}

export enum ClaimCauseOfLoss {
  LOSS_THREAT = 69000001,
  INSOLVENCY = 69000002,
  PROTRACTED_DEFAULT = 69000003,
  NON_FULLFILMENT = 69000004,
  POLITICAL_RISK = 69000005,
  FORCE_MAJEURE = 69000006,
  BOND_REQUEST_FOR_PAYMENT = 69000007,
  BANKRUPTCY = 69000008,
  DELAY_OF_PAYMENT = 69000009,
  OTHER = 69000010,
  DRAFT = 69000011,
  POTENTIAL_CLAIM = 69000012,
  CLAIM = 69000013,
}

export enum ClaimInvoiceCauseOfLoss {
  PROTECTED_DEFAULT = 362000001,
  LEGAL_INSOLVENCY = 362000002,
  FORCE_MAJEURE = 362000003,
  POLITICAL_RISK = 362000004,
  COLLECTION_WITH_BANK_GUARANTEE = 362000005,
  INSOLVENCY = 362000006,
  UNILATERAL_CONTRACT_CANCELATION = 362000007,
  OTHER = 362000008,
}

export enum ClaimCauseOfLossDetail {}

export enum ClaimLegalStatus {
  LEGAL_ACTIONS = 171000001,
  VOLUNTARLY_AGREEMENT = 171000002,
  INSOLVENCY_ALL_TYPES = 171000003,
  EQUALS_TO_INSOLVENCY = 171000004,
  PAYMENT_PLAN = 171000005,
  OVERDUE_OR_LOSS_THREAT = 171000006,
  PROTRACTED_OVERDUES = 171000007,
  RESTRUCTURING_REQUESTED = 171000008,
  RESTRUCTURING_AGREED = 171000009,
  CHAPTER_11_OR_UDICIAL_REORGANISATION = 171000010,
  INSOLVENCY = 171000011,
  BANKRUPTCY = 171000012,
  ARBITRATION_OR_LEGAL_ACTIONS = 171000013,
  FRAUD = 171000014,
  OTHER = 171000015,
}

export namespace ClaimDocumentStatus {
  export const NOT_RECEIVED = 70000001;
  export const RECEIVED_WITH_DATE = 70000002;
  export const RECEIVED_WITH_ERRORS = 70000003;
  export const RECEIVED_NOT_COMPLETE = 70000004;
  export const NO_DOCUMENT = 70000005;
  export const NOT_REQUIRED = 70000006;
  export const RECEIVE_DATE_REQUIRED = [NO_DOCUMENT, RECEIVED_WITH_DATE, RECEIVED_WITH_ERRORS, RECEIVED_NOT_COMPLETE];
}

export namespace ClaimDocumentsStatus {
  export const DOCUMENTS_REQUEST = 365000001;
  export const DOCUMENTS_AMENDMENT_REQUEST = 365000002;
  export const DOCUMENTS_REQUEST_REMINDER = 365000003;
  export const DOCUMENTS_ANALYSIS = 365000004;
  export const DOCUMENTS_COMPLETED = 365000005;
}

export namespace ClaimCalculationDocumentType {
  export const BALANCE_AND_BANK_ACCOUNT = 299000001;
  export const BALANCE = 299000002;
  export const BANK_ACCOUNT = 299000003;
  export const ACCOUNTANCY = 299000004;
}

export namespace BrokerContractPowerOfAttorney {
  export const TO_CONCLUDE_A_CONTRACT = 314000001;
  export const TO_BRING_TO_CONCLUDE_A_CONTRACT = 314000002;
  export const OTHER = 314000003;
}

export namespace BrokerContractStatus {
  export const REGISTERED = 320000001;
  export const ACTIVE = 320000002;
  export const TERMINATED = 320000003;
  export const CLOSED = 320000004;
}

export namespace RecoveryType {
  export const MAIN_DEBT = 309000001;
  export const INTERESTS = 309000002;
  export const CORRECTION_MAIN_DEBT = 309000003;
  export const CORRECTION_INTERESTS = 309000004;
  export const CORRECTIONS = [CORRECTION_MAIN_DEBT, CORRECTION_INTERESTS];

  export const MEHIB_MAIN_DEBT = 309000101;
  export const MEHIB_LATE_PAYMENT_INTEREST = 309000102;
  export const MEHIB_INTEREST = 309000103;
  export const MEHIB_FINANCED_AND_INSURED_PREMIUM = 309000104;
  export const MEHIB_CAPITALISED_INTERESTS = 309000105;
  export const MEHIB_CREDIT_NOTE = 309000106;
  export const MEHIB_ADVANCE_PAYMENT = 309000107;
  export const MEHIB_DISCOUNT = 309000108;
  export const MEHIB_HAIRCUT_MAIN_DEBT = 309000109;
  export const MEHIB_HAIRCUT_LATE_PAYMENT_INTEREST = 309000110;
  export const MEHIB_HAIRCUT_INTEREST = 309000111;
  export const MEHIB_OTHER = 309000112;
  export const MEHIB_WRITE_OFF_MAIN_DEBT = 309000113;
  export const MEHIB_WRITE_OFF_LATE_PAYMENT_INTEREST = 309000114;
  export const MEHIB_WRITE_OFF_INTEREST = 309000115;
  export const MEHIB_OTHER_INTEREST = 309000116;
  export const MEHIB_OTHER_LATE_PAYMENT_INTEREST = 309000117;
}

export namespace LossAdjustmentExpenseType {
  export const LAWYER = 310000001;
  export const BAILIFF = 310000002;
  export const COURT = 310000003;
  export const RECOVERY_AGENCY = 310000004;
  export const OTHER = 310000005;
  export const CORRECTION_LAWYER = 310000006;
  export const CORRECTION_BAILIFF = 310000007;
  export const CORRECTION_COURT = 310000008;
  export const CORRECTION_RECOVERY = 310000009;
  export const CORRECTION_OTHER = 310000010;
  export const CORRECTION_EXPENSES = [
    CORRECTION_LAWYER,
    CORRECTION_BAILIFF,
    CORRECTION_COURT,
    CORRECTION_RECOVERY,
    CORRECTION_OTHER,
  ];
  export const OTHER_EXPENSES = [LAWYER, BAILIFF, COURT, RECOVERY_AGENCY, OTHER];
}

export namespace BrokerRelationTypeOfAgreement {
  export const REPRESENTATION_IN_ACTIVITIES_RELATED_TO_THE_LIQUIDATION_OF_DAMAGES = 312000001;
  export const MONITORING_THE_PAYMENT_DEADLINES_OF_PREMIUM_INSTALLMENTS = 312000002;
  export const OTHER = 312000003;
}

export enum PolicyContractCompanyRole {
  CLIENT = 28000001,
  SUBINSURED = 28000002,
  BASE_INSURER = 28000003,
  CO_INSURER = 28000006,
  RISK = 28000004,
  COLLECTION_COMPANY = 28000005,
}

export enum BISource {
  INTERNAL = 0,
  CREDITREFORM = 109000001,
  CERVED = 109000002,
  COFACE = 109000003,
  BISNODE = 109000004,
  GRAYDON = 109000005,
  COFACE_REST = 109000009,
  WISERFUNDING = 109000011,
}

export enum BrokerType {
  BROKER = 148000001,
  AGENT = 148000002,
  UNDERWRITING_AGENT = 148000003,
  MULTI_AGENT = 148000004,
  BOOSTER_BROKER = 148000005,
  AGENT_BANK = 148000007,
}

export enum BrokerContractTerminationReason {
  AGENT_NEGLECT = 133000003,
  ORDINARY = 133000004,
}

export function isOfferRejectionState(statutId: PolicyInquiryOfferStatus) {
  return statutId === PolicyInquiryOfferStatus.REJECTED || statutId === PolicyInquiryOfferStatus.REJECTED_BY_CLIENT;
}

export enum PolicyInquiryOfferStatus {
  NOT_BINDING = 151000001,
  BINDING = 151000002,
  ACTIVE = 151000007,
  // Only KUKE
  ACCEPTED = 151000003,
  REJESTERED = 151000004,
  REJECTED = 151000005,
  CLOSED = 151000016,
  FOR_DECISION = 151000008,
  ACCEPTED_BY_INSURER = 151000009,
  NEW = 151000006,
  ACCEPTED_BY_CLIENT = 151000012,
  REJECTED_BY_CLIENT = 151000013,
  EXPIRED = 151000014,
  REJECTED_BY_INSURER = 151000011,
  CHANGED_ACCEPTED_BY_INSURER = 151000010,
}

export const OFFER_PENDING_STATUSES: PolicyInquiryOfferStatus[] = [
  PolicyInquiryOfferStatus.NEW,
  PolicyInquiryOfferStatus.FOR_DECISION,
  PolicyInquiryOfferStatus.REJECTED_BY_INSURER,
  PolicyInquiryOfferStatus.CHANGED_ACCEPTED_BY_INSURER,
  PolicyInquiryOfferStatus.ACCEPTED_BY_INSURER,
  PolicyInquiryOfferStatus.ACTIVE,
];

export const OFFER_CALCULABLE_STATUSES: PolicyInquiryOfferStatus[] = [
  PolicyInquiryOfferStatus.NEW,
  PolicyInquiryOfferStatus.FOR_DECISION,
  PolicyInquiryOfferStatus.REJECTED_BY_INSURER,
];

export const OFFER_ACCEPTED_STATUSES: PolicyInquiryOfferStatus[] = [
  PolicyInquiryOfferStatus.ACCEPTED,
  PolicyInquiryOfferStatus.ACCEPTED_BY_CLIENT,
  PolicyInquiryOfferStatus.ACCEPTED_BY_INSURER,
];
export const OFFER_NEW_DOCUMENTS_STATUSES: PolicyInquiryOfferStatus[] = OFFER_CALCULABLE_STATUSES;

export enum CompanyResponsiblePersonRole {
  UNDERWRITER = 159000001,
  POLICY_MANAGER = 159000002,
  BONDING_MANAGER = 159000003,
}

export enum ClaimRiskType {
  CREDIT_INSURANCE = 56000001,
  SURETY = 56000002,
  PROD_RISK = 56000003,
  MLT_CREDIT_INSURANCE = 56000004,
  MLT_INVESTMENT_INSURANCE = 56000005,
  MLT_PRODUCTION_RISK = 56000006,
}

export enum ClaimLawsuitStatus {
  IN_PROGRESS = 373000001,
  LAWSUIT_WON = 373000002,
  LAWSUIT_LOST = 373000003,
  USER_ERROR = 373000004,
}

export const COMPANY_TYPE_BROKERS: number[] = [
  CompanyType.BROKER,
  CompanyType.BROKER_BRANCH,
  CompanyType.SAVING_BANK,
  CompanyType.BANK,
  CompanyType.AGENT,
  CompanyType.ECA,
  CompanyType.SIGNALER,
];

export const COMPANY_TYPE_MEHIB_REINSURERS: number[] = [CompanyType.INSURANCE, CompanyType.ECA];
export const COMPANY_TYPE_MEHIB_NON_BANK: number[] = [
  CompanyType.INSURANCE,
  CompanyType.ECA,
  CompanyType.COMPANY,
  CompanyType.BROKER,
  CompanyType.BUSINESSUNIT,
];

export const COMPANY_TYPE_INSURERS: number[] = [CompanyType.INSURANCE];

export namespace Country {
  export const BE = 3000021;
  export const PL = 3000180;
  export const GB = 3000214;
  export const DE = 3000058;
  export const IT = 3000111;
  export const US = 3000234;
  export const AM = 3000007; /*Armenia, Armenia */
  export const AZ = 3000017; /*Azerbaijan, Azerbejdżan */
  export const BY = 3000037; /*Belarus, Białoruś */
  export const TM = 3000223; /*Turkmenistan, Turkmenistan */
  export const UA = 3000231; /*Ukraine, Ukraina */
  export const UZ = 3000236; /*Uzbekistan, Uzbekistan */
  export const KZ = 3000126; /*Kazakhstan, Kazachstan */
  export const KG = 3000117; /*Kyrgyzstan, Kirgistan */
  export const MK = 3000145; /*Macedonia, Macedonia Północna */
  export const MD = 3000140; /*Moldova, Mołdawia */
  export const MN = 3000148; /*Mongolia, Mongolia */
  export const MM = 3000147; /*Myanmar, Mjanma */
  export const RU = 3000192; /*Russian Federation, Rosja */
  export const HU = 3000101; /*Hungary, Węgry */
  export const IE = 3000103; // Ireland
  export const CA = 3000039;

  // for KUKE mailing
  export const RUSSIAN_LANG = [AM, AZ, BY, TM, UA, UZ, KZ, KG, MK, MD, MN, MM, RU];
}

export enum CollectionStatus {
  COLLECTION_DESISTED = 201000030,
  DISPUTE_PARTIAL_DESISTANCE = 201000028,
  REMISSION = 201000043,
  PARTIAL_REPAYMENT_REMISSION = 201000041,
  CASE_CLOSED_OF_CLIENT_S_FAULT = 201000031,
  FRAUD_CASE_CLOSED = 201000033,
}

export enum CollectionStatusGroup {
  PRELEGAL_ACTION = 213000001,
  LEGAL_ACTION = 213000002,
  BAILIFF = 213000003,
  INSOLVENCY = 213000004,
  RESTRUCTURING = 213000005,
  CLOSED = 213000006,
  ANALYSIS = 213000007,
}

export enum CollectionQuestionnaire {
  REQUEST_CONTACT = 215000006,
  ASYMMETRIC_TRANSACTIONS = 215000018,
  ASYMMETRIC_TRANSACTIONS_STATEMENT = 215000019,
  NOT_LED_BY_INSURER = 215000020,
  INSOLVENCY_RESTRUCT = 215000002,
  INSOLVENCY_BANKRUPTCY = 215000003,
  INSOLVENCY_LIQUIDATION = 215000004,
  LEGAL_PATH = 215000005,
  ORDER_TO_INSURER = 215000022,
}

export namespace CollectionType {
  export const RECOVERY_CREDIT_INSURANCE = 202000001;
  export const RECOVERY_BONDING = 202000002;
  export const DUE_PREMIUMS = 202000003;
  export const CREDIT_INSURANCE_BUYER_WITH_LIMIT = 202000004;
  export const CREDIT_INSURANCE_DISCRETIONARY_COVER = 202000005;
  export const CREDIT_INSURANCE_DISCRETIONARY_COVER_PLUS_LIMIT = 202000006;

  export const HIDDEN_IN_PORTAL = new Set<number>([RECOVERY_CREDIT_INSURANCE, RECOVERY_BONDING, DUE_PREMIUMS]);
  export const HIDDEN_IN_INTRANET_FOR_GUI_CREATION = new Set<number>([DUE_PREMIUMS]);
  export const NN_TYPES = [CREDIT_INSURANCE_DISCRETIONARY_COVER, CREDIT_INSURANCE_DISCRETIONARY_COVER_PLUS_LIMIT];
  export const RECOVERY_TYPES = [RECOVERY_CREDIT_INSURANCE, RECOVERY_BONDING];
  export const CREDIT_INSURANCE = [
    CREDIT_INSURANCE_BUYER_WITH_LIMIT,
    CREDIT_INSURANCE_DISCRETIONARY_COVER,
    CREDIT_INSURANCE_DISCRETIONARY_COVER_PLUS_LIMIT,
  ];
}

export namespace CollectionPaymentMainDebtType {
  export const PAYMENT = 209000001;
  export const CREDIT_NOTE = 209000002;
  export const REMISSION = 209000003;
}

export enum CollectionPaymentAllocation {
  GENERAL = 308000001,
  INSURER = 308000002,
  INSURED = 308000003,
}

export enum CollectionRecoveryType {
  INSURER = 230000001,
  TOGETHER = 230000002,
}

export enum CollectionRecoveryLedBy {
  INSURED = 231000001,
  LEGAL_DEPARTMENT = 231000002,
  EXTERNAL_PROVIDER = 231000003,
  COLLECTION_DEPARTMENT = 231000004,
}

export enum CollectionCostCalcType {
  COSTS_REIMBURSED_BY_INSURER = 234000001,
  INSURER_COSTS = 234000002,
  COSTS_REIMBURSED_BY_CREDITOR = 234000003,
  CREDITOR_COSTS = 234000004,
}

export enum CollectionCostType {
  COMMISSION = 235000001,
  COURT_EXECUTION_PROCEDURE = 235000002,
}

export enum CollectionRequestDecisionType {
  FURTHER_DELIVERIES_REFUSAL = 271000001,
  FURTHER_DELIVERIES_WITHOUT_LIMIT_APPROVAL = 271000002,
  FURTHER_DELIVERIES_WITH_LIMIT_APPROVAL = 271000003,
  COLLECTION_ON_HOLD_APPROVAL = 271000004,
  COLLECTION_ON_HOLD_REFUSAL = 271000005,
  WITHDRAWAL_EARLY_PAYMENT = 271000006,
  WITHDRAWAL_UNSATISFIED_CONDITIONS = 271000007,
}

export enum CollectionRequestDecisionStatus {
  SENT_TO_ACCEPTANCE = 272000001,
  ACCEPTED = 272000002,
  CLOSED = 272000004,
  TERMINATED = 272000006,
}

export enum ContactPersonGroupType {
  CREDITOR = 203000001,
  DEBTOR = 203000002,
  EXTERNAL_SERVICE_PROVIDER = 203000003,
}

export enum ContactPersonType {
  MAIN = 206000004,
  PORTAL_ADMIN = 206000015,
}

export namespace ContactPersonTypes {
  export const HIDDEN_IN_PORTAL = new Set<number>([ContactPersonType.MAIN, ContactPersonType.PORTAL_ADMIN]);
}

export enum ContactType {
  PHONE = 16000001,
  MOBILE = 16000002,
  EMAIL = 16000003,
  FAX = 16000004,
  WWW = 16000005,
  PEC = 16000006,
}

export namespace ExternalProviderType {
  export const AGENT = 221000002;
  export const INFORMATION_PROVIDER = 221000005;
}

export enum ProfileQuotaType {
  // limit decision
  MAX_SINGLE_LIMIT = 149000001,
  LIMIT_SUM_FOR_BUYER = 149000002, // total sum of limits

  // bond
  MAX_SINGLE_BOND = 149000003,
  MAX_TOTAL_LIABILITY = 149000004,

  // collection
  CLOSING_THE_CASE = 149000005,
  COLLECTION_ON_HOLD = 149000006,
  FURTHER_DELIVERIES = 149000007,
  ESTIMATED_COSTS = 149000008,
}

export enum BondCustomFieldType {
  PROROGA_DAL = 150000004,
  PROROGA_AL = 150000009,
  DATA_PRESENTAZIONE_DICHIARAZIONE = 150000030,
  INTERESSI = 150000033,
  INTERESSI_ORDINARIA_1 = 150000034,
  INTERESSI_ORDINARIA_2 = 150000035,
  DAMAGE_CLAUSE = 150000050,
  DAMAGE_CLAUSE_TYPE = 150000060,
  TIMBER_LOT_COST = 150000053,
  PAYMENT_DAYS = 150000051,
}

export enum PremiumSubinsured {
  SUBINSURED = 162000001,
  MAIN_INSURED_UNGROUPPED = 162000002,
  FRONTING = 162000003, // only Credendo
}

export enum UserGroup {
  CREDCOM = 25,
  UCSU = 130,
  EXCOM = 5028,
  SURCOM = 5030,
  PUW1 = 149,
}

export enum UserGroupType {
  BONDING = 122000001,
  POLICY_OFFER_VOTING = 122000003,
  RISK_POLICY_OFFER_VOTING = 122000006,
  BONDING_OFFER_VOTING = 122000005,
}

export enum Field {
  BI_REGISTRATION_NAME = 172000001,
  BI_NATIONAL_ID = 172000002,
  BI_TAX_ID = 172000003,
  BI_STAT_ID = 172000004,
  BI_ZIP_CODE = 172000005,
  BI_TOWN = 172000006,
  BI_STREET = 172000007,
}

export enum FieldValueType {
  AMOUNT = 236000001,
  MULTIPLICITY = 236000002,
}

export enum PolicyGuarantorRelationType {
  PUBLIC = 174000001,
  PRIVATE = 174000002,
}

export enum PolicyProjectType {
  CLASS_A = 175000001,
  CLASS_B = 175000002,
  CLASS_C = 175000003,
  NOT_CLASSIFIED = 175000004,
}

export enum PolicyInsuranceProgrammeType {
  GOVERNMENT = 176000001,
  INDIVIDUAL = 176000002,
}

export enum PolicyTransactionType {
  BANK_TO_BANK = 177000001,
  PROJECT_FINANCE = 177000002,
  STRUCTURE_FINANCE = 177000003,
  CORPORATE_RISK = 177000004,
  OTHER = 177000005,
}

export enum ReportType {
  NBB = 20000001,
}

export enum InstalmentType {
  INTERESTS = 179000001,
  LOAN = 179000002,
}

export enum UserStatus {
  NEW = 112000001,
  ACTIVATED = 112000002,
  DISABLED = 112000003,
  PASS_CHANGE = 112000005,
  DISABLED_IN_AD = 112000007,
  DELETED_IN_AD = 112000008,
  DELETED = 112000009,
}

export enum RatingType {
  BONDING = 207000001,
  ST_INSURANCE = 207000002,
  MLT_INSURANCE = 207000003,
  BANK = 207000004,
  GSP = 207000005,
  GRC = 207000006,
  KYC = 207000007,
  ENVIRONMENTAL = 207000008,
  S_AND_P = 207000009,
  CC_CATEGORY = 207000010,
  FITCH = 207000011,
  MOODY_S = 207000012,
  CREDIT_INSURANCE = 207000013,
}

export enum RatingCategory {
  AUTO = 442000001,
  MANUAL = 442000002,
  MANUAL_GRC = 442000003,

  // kuke
  BONDING = 442000004,
  ST_INSURANCE = 442000005,
  GSP = 442000006,
  MLT_INSURANCE = 442000007,
  BANK = 442000008,
  KYC = 442000009,

  // Mehib

  MEHIB_BONDING = 442000010,
  MEHIB_ST_INSURANCE = 442000011,
  MEHIB_MLT_INSURANCE = 442000012,
  MEHIB_BANK = 442000013,
  GRC = 442000014,
  MEHIB_KYC = 442000015,
  CC = 442000015,
  ENVIRONMENTAL = 442000017,
  SP = 442000018,
  FITCH = 442000019,
  MOODY = 442000020,
}

export enum RatingDepartment {
  // KUKE
  ZARZ = 342000001,
  KRU = 342000002,
  KPUE = 342000003,

  // MEHIB
  LEVEL_1 = 342000004,
  LEVEL_2 = 342000005,
  COMMITTEE = 342000006,
  BOARD = 342000007,
  MINISTRY = 342000008,
  ENVIRONMENTAL = 207000008,
  S_AND_P = 207000009,
  FITCH = 207000011,
  MOODY_S = 207000012,
}

export enum UnderwritingType {
  RENEWABLE = 81000001,
  NON_RENEWABLE = 81000002,
  NOT_REINSURED = 81000003,
  PERIOD_EXCEEDED = 81000004,
}

export enum RatingStatus {
  IN_ACCEPTANCE = 220000001,
  WAITING_FOR_APPROVAL = 220000006,
  WAITING_FOR_1ST_APPROVAL = 220000007,
  WAITING_FOR_2ND_APPROVAL = 220000008,
  WAITING_FOR_3RD_APPROVAL = 220000009,
  OBSOLETE = 220000002,
  REJECTED = 220000003,
  ACTIVE = 220000004,
  INACTIVE = 220000005,
}

export enum RatingCalcType {
  KUKE_SIMPLE = 195000001,
  KUKE_EXTENDED = 195000002,
}

export enum RatingScale {
  CC = 266000002,
}

export enum RatingSource {
  UW = 219000001,
  INSURANCE = 219000002,

  //  KUKE
  MANUAL = 219000001,
  AUTOMAT = 219000002,
  MIGRATION = 219000003,
  FILE_IMPORT = 219000004,
}

export enum ContactNoteReasonType {
  COMPLAINT = 76000001,
  ISSUE = 76000002,
  REQUEST_FOR_INFORMATION = 76000003,
  SALES_CONTACT = 76000004,
  SURVEY = 76000005,
  COLLECTION = 76000006,
  DEBTOR = 76000007,
  BUYER = 76000008,
}

export enum InquiryProductType {
  // credendo products
  EXCESS_OF_LOSS = 124000001,
  EXCESS_OF_LOSS_PARTNER = 124000002,
  TOP_UP = 124000003,
  CAPTIVE = 124000004,
  SINGLE_RISK = 124000005,
  TURNOVER = 124000006,

  // kuke KOM products
  KUKE_KOM_OP = 124000116,
  KUKE_KOM_EP = 124000117,
  KUKE_KOM_FE = 124000119,
  KUKE_KOM_FK = 124000120,
  KUKE_KOM_SP = 124000121,

  // kuke GSP products
  KUKE_GSP_PBG = 124000223,

  // MEHIB ST products
  EXPORT_RECEIVABLES_C = 124001001,
  FACTORING_CF = 124001002,

  // MEHIB MLT products
  BUYER_CREDIT_V = 124001003,
  SUPPLIER_CREDIT_S = 124001004,
  SUPPLIER_CREDIT_DISCOUNTING_KV = 124001005,
  FOREIGN_INVESTMENT_B = 124001006,
  TIED_AID_TAI = 124001007,
  PRESHIPMENT_RISK_G = 124001008,

  // ECG
  GLOBAL_RISK = 124002001,
  SELECTIVE_RISK = 124002002,
  ECG_TOP_UP = 124002003,
}

export namespace InquiryProductTypes {
  export const MEHIB_MLT = [
    InquiryProductType.BUYER_CREDIT_V,
    InquiryProductType.SUPPLIER_CREDIT_S,
    InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV,
    InquiryProductType.FOREIGN_INVESTMENT_B,
    InquiryProductType.TIED_AID_TAI,
    InquiryProductType.PRESHIPMENT_RISK_G,
  ];
  export const MEHIB_ST = [InquiryProductType.EXPORT_RECEIVABLES_C, InquiryProductType.FACTORING_CF];
  export const KUKE_PRODUCT_PACKED = [
    InquiryProductType.KUKE_KOM_OP,
    InquiryProductType.KUKE_KOM_SP,
    InquiryProductType.KUKE_KOM_FK,
    InquiryProductType.KUKE_KOM_FE,
  ];
  export const KUKE_SHORT = [InquiryProductType.KUKE_KOM_EP, InquiryProductType.KUKE_GSP_PBG];
  export const CREDENDO_MULTI_RISK = [
    InquiryProductType.EXCESS_OF_LOSS,
    InquiryProductType.EXCESS_OF_LOSS_PARTNER,
    InquiryProductType.TOP_UP,
    InquiryProductType.CAPTIVE,
  ];
  export const CREDENDO_SINGLE_RISK = [InquiryProductType.SINGLE_RISK, InquiryProductType.TURNOVER];
  export const ECG = [InquiryProductType.GLOBAL_RISK, InquiryProductType.SELECTIVE_RISK, InquiryProductType.ECG_TOP_UP];
  export const ECG_MULTI_RISK = [InquiryProductType.GLOBAL_RISK, InquiryProductType.SELECTIVE_RISK];
}

export namespace PolicyContractTypes {
  export const MEHIB_MLT = [
    PolicyContractType.BUYER_CREDIT_V,
    PolicyContractType.SUPPLIER_CREDIT_S,
    PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV,
    PolicyContractType.FOREIGN_INVESTMENT_B,
    PolicyContractType.TIED_AID_TAI,
    PolicyContractType.PRESHIPMENT_RISK_G,
  ];
  export const MEHIB_ST = [PolicyContractType.EXPORT_RECEIVABLES_C, PolicyContractType.FACTORING_CF];
}

export enum PolicyInquiryStatementType {
  DATA_CONSISTENT_WITH_FACTS = 225000001,
  FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS = 225000002,
  GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS = 225000003,
  CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME = 225000004,
  INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA = 225000005,
  CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY = 225000006,
  RUSSIAN_EMBARGO = 225000007,
  MARKETING_CONSENT = 225000008,
  PAPER_INVOICE = 225000009,
}

export enum PolicyContractVerStatementType {
  KUKE_CONSENT_TO_PAPER_INVOICES = 279000001,
  KUKE_CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME = 279000040,
  KUKE_KOM_INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA = 279000051,
  KUKE_GSP_INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA = 279000052,
  KUKE_CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY = 279000060,
  KUKE_MARKETING_CONSENT = 279000080,
}

export namespace BonusMalusRequestStatus {
  export const REQUEST = 243000001;
  export const PENDING = 243000002;
  export const REGISTERED = 243000005;
  export const REJECTED = 243000003;
  export const ACCEPTED = 243000004;
  export const RENOUNCED = 243000006;
  export const CALCULATED = 243000007;

  export const ACTIVATED = [ACCEPTED, RENOUNCED, CALCULATED];
  export const TO_BE_ACCEPTED = [PENDING, REGISTERED];
  export const DECISION_FIELDS_VISIBLE = [PENDING, REGISTERED, REJECTED, ACCEPTED, RENOUNCED];
  export const REJECTION_FIELDS_VISIBLE = [PENDING, REGISTERED, REJECTED];
}

export namespace BonusMalusRequestType {
  export const NO_CLAIM_BONUS = 241000001;
  export const LOW_CLAIM_BONUS = 241000002;
  export const PROFIT_SHARE = 241000003;
  export const TURNOVER_OUTSTANDING_PREMIUM = 241000004;
  export const CHANGE_IN_RISK = 241000005;
  export const MALUS = 241000006;
  export const BONUS_RECOVERY = 241000007;
  export const PROFIT_SHARE_RECOVERY = 241000008;
  export const NO_CLAIM_REFUND = 241000010;
  export const WHOLETRUNOVER_REFUND = 241000011;
  export const TA_PDR = 241000012;

  export const PERCENTAGE_POSSIBLE = [NO_CLAIM_BONUS, LOW_CLAIM_BONUS, PROFIT_SHARE, MALUS];
  export const CALCULATION_POSSIBLE = [
    NO_CLAIM_BONUS,
    LOW_CLAIM_BONUS,
    PROFIT_SHARE,
    NO_CLAIM_REFUND,
    WHOLETRUNOVER_REFUND,
    TA_PDR,
  ];
}

export enum InsuranceType {
  ENTIRE_TURNOVER_INSURANCE = 263000001,
  INSURANCE_FOR_ONE_BUYER = 263000002,
  SELECTIVE_INSURANCE = 263000003,
}

export enum QuotationLoanType {
  AMORTIZING = 265000001,
  REVOLVING = 265000002,
  BULLET = 265000003,
}

export enum QuotationType {
  STANDARD = 232000001,
  BANK_BOOSTER = 232000002,
}

export enum PolicyInquiryOfferVotingStatus {
  ACCEPTED = 283000001,
  REJECTED = 283000002,
  PENDING = 283000003,
  POLICY_CREATED = 283000004,
  NOT_SENT = 283000005,
}

export enum ClaimDecisionType {
  ACCEPTED = 55000001,

  MEHIB_REGISTERED = 55002001,
  MEHIB_PRELIMINARY_ACCEPTED = 55002002,
  MEHIB_REFUSED = 55002003,
  MEHIB_ACCEPTED_FOR_PAYMENT = 55002004,
  MEHIB_RECOVERED_BEFORE_INDEMNIFICATION = 55002005,
  MEHIB_RECOVERED_AFTER_INDEMNIFICATION = 55002006,
  MEHIB_WRITTEN_OFF = 55002007,
  MEHIB_PUT_ON_HOLD = 55002008,
}

export enum ClaimAcceptingBody {
  LEVEL_2 = 422000001,
  BOARD = 422000002,
  COMMITTEE = 422000003,
  MINISTRY = 422000004,
}

export enum ClaimCalculationDecision {
  ACCEPTED = 259000001,
  PARTIALLY_ACCEPTED = 259000002,
  REJECTED = 259000003,
  PAYOUT_REVOKED = 259000004,
  DISCRETIONARY_ACCEPTED = 259000005,
}

export enum ClaimCalculationStatus {
  IN_PROGRESS = 257000001,
  WAITING_FOR_FIRST_APPROVAL = 257000002,
  APPROVED = 257000003,
  SENT_FOR_PAYMENT = 257000004,
  WAITING_FOR_SECOND_APPROVAL = 257000005,
  REJECTED = 257000006,
  USER_ERROR = 257000007,
  GO = 257000008,
}

export enum ClaimAlgorithm {
  STANDARD = 64000001,
  IT = 64000002,
  BASIC_SR = 64000003,
  SPECIAL_SR = 64000004,
}

export enum ClaimVersionValueType {
  AFTER_RECOVERY = 60000001,
  NQL = 60000003,
  INSURANCE_COVER = 60000010,
  DEDUCTIBLES = 60000013,
  INSURED_AMOUNT = 60000018,
  SHARE_PERCENT = 60000019,
}

export enum PolicyTerminationType {
  TERMINATION_AGREEMENT = 307000002,
  TERMINATED_BY_INSURED = 307000004,
  INSURER_TERMINATION = 307000003,
  // Only Kuke
  RESIGNATION = 307000001,
  EARLY_REPAYMENT_OF_INSURED_RECEIVABLES = 307000006,
  CANCELLATION_PAYMENT = 307000007,
  CANCELLATION__DOCUMENTS = 307000008,
  CONTRACT_CONTINUED_UNDER_A_DIFFERENT_CONTRACT_NUMBER = 307000009,
  // Only Credendo
  WITHDRAWAL_BY_CREDENDO = 307000010,
  TERMINATION_AGREEMENT_BEFORE_THE_END_PERIOD = 307000011,
}

export enum EconomicCountryGroup {
  OECD = 163000001,
  NOECD = 163000002,
}

export enum RejectManagementSource {
  REPOSITORY = 335000001,
}

export enum CessionStatus {
  NOT_ACTIVE = 334000001,
  ACTIVE = 334000002,
}

export enum CessionType {
  CHANGE_OF_THE_MAIN_INSURED = 137001013,
}

export enum FrontingType {
  NO_FRONTING = 338000001,
  FRONTING = 338000002,
  FRONTING_ASSUMED = 338000003,
  ACTIVE_REINSURANCE = 338000004,
}

export enum BIReportType {
  PREMIUM_REPORT = 110000001,
  COMMERCIAL_REPORT = 110000002,
  COMPACT_REPORT = 110000003,
  INVESTIGATION = 110000004,
}

export enum ReinsuranceMode {
  STANDARD = 364000001,
  FACULTATIVE = 364000002,
  NONE = 364000003,
  MIXED = 364000004,
}

export enum ReinsuranceType {
  QS = 66000001,
  XOL = 66000002,
  FAC = 66000003,
  FRONTING = 66000004,
}

export enum PolicyInquiryOfferAction {
  REGISTER = 228000004,
  ACCEPTBY_CLIENT_STANDARD = 228000014,
  ACCEPTBY_CLIENT_ELECTRONIC_SIGNATURE = 228000015,
}

export enum ScreeningResult {
  NO_MATCH = 348000001,
}

export enum ScreeningFinding {
  NONE = 349000001,
}

export enum ScreeningCategory {
  NONE = 351000001,
}

export enum GlobalConditionsOfInsurance {
  KUKE_OP_2016_01 = 37000133,
  KUKE_OP_2018_12 = 37000154,
  KUKE_PBG_99_IN_P_2020 = 37000160,
  KUKE_SP_2017_06 = 37000148,
  KUKE_FK_2016_11 = 37000149,
  KUKE_FE_2016_11 = 37000150,
}

export enum BusinessLineGroupCompetency {
  BONDING_COMMERCIAL = 212000101,
  BONDING_NON_COMMERCIAL = 212000102,
  POLICIES_COMMERCIAL = 212000103,
  POLICIES_NON_COMMERCIAL = 212000104,
}

export enum ClaimVersionType {
  CLAIM_ADVICE = 136000004,
  CLAIM_CREDENDO = 136000005,
  MIGRATION = 136000003,

  CLAIM = 136001001,
  PARTIAL_CLAIM_CORRECTION = 136001002,
  ENTIRE_CLAIM_CORRECTION = 136001003,
  APPEAL_FROM_DECISION = 136001004,
  CLAIM_WITHDRAW = 136001005,

  MEHIB_CLAIM_ADVICE = 136002001,
  MEHIB_CLAIM_NOTIFICATION = 136002002,
  MEHIB_CLAIM_SETTLEMENT = 136002003,

  ECG_OVERDUE_NOTIFICATION = 136003001,
  ECG_CLAIM_NOTIFICATION = 136003002,
}

export enum Template {
  POLICY_INQUIRY_CALCULATOR = 10492,
  // kuke
  KPUE_APPLICATION_QUARANTES = 10170, // polish name: Wniosek KPUE - gwarancje
  APPLICATION_FOR_KRU = 10171, // polish name: Wniosek na KRU
  APPLICATION_TO_THE_MANAGMENT_BOARD = 10172, // polish name: Wniosek na Zarząd
  APPLICATION_TO_THE_MANAGMENT_BOARD_COLECTION = 10102, // polish name: Wniosek na zarząd (windykacja)
  APPLICATION_TO_THE_MANAGMENT_BOARD_CLAIM = 10576, // polish name: Wniosek na Zarzad / KRU
  APPLICATION_TO_THE_KRU_COLECTION = 10601, // polish name: Wniosek na KRU (windykacja)
  APPLICATION_TO_THE_KRU_CLAIM = 10603, // polish name: Wniosek na KRU (szkody)
}

export enum InformationReportOrderMode {
  STANDARD = 382000001,
}

export enum DocumentProcessStatus {
  STARTED = 372000001,
  PROCESSING = 372000002,
  FINISHED = 372000003,
  ERROR = 372000004,
}

export enum DocumentProcessType {
  WORDING = 371000001,
  AUTENTI = 371000002,
  CLIENT = 371000003,
}

export enum DeclarationEntryNNRole {
  DOMESTIC_NN = 399000001,
  EXPORT_NN = 399000002,
}

export enum AcquisitionMethod {
  BOND_DEPT = 400000001,
  SALESMAN = 400000002,
}

export enum PolicyDrawdownVersionStatus {
  DRAFT = 410000001,
  PENDING_APPROVAL = 410000002,
  APPROVED = 410000003,
}

export enum PolicyDrawdownRepaymentScheduleVersionStatus {
  REGISTERED = 458000001,
  WAITING_FOR_APPROVAL = 458000002,
  APPROVED = 458000003,
  USER_ERROR = 458000004,
}

export enum PolicyDrawdownType {
  MAIN_DEBT = 397000001,
  INTEREST = 397000002,
  LATE_PAYMENT_INTERESTS = 397000003,
  FINANCED_AND_INSURED_PREMIUM = 397000004,
  CAPITALISED_INTERESTS = 397000005,
  MATERIALS_AND_PARTS = 397000006,
  WAGES_AND_CONTRIBUTIONS = 397000007,
  GENERAL_EXPENSES = 397000008,
  PRE_FINANCING = 397000009,
  OTHER_COSTS = 397000010,
  CASH = 397000011,
  INVESTMENT_IN_KIND = 397000012,
  OWNERSHIP_LOAN = 397000013,
}

export enum DeductibleType {
  FLAT = 405000003,
}

export enum PdrConvention {
  CONVENTION_A = 390000001,
  CONVENTION_B = 390000002,
  NO_PDR_CALCULATION = 390000003,
}

export enum TrainingType {
  INTRANET = 438000001,
  EXTRANET = 438000002,
}

export enum InvestmentForm {
  CASH = 407000001,
  INVESTMENT_IN_KIND = 407000002,
  OWNERSHIP_LOAN = 407000003,
}

export enum HolidayStatus {
  WAITING_FOR_ACCEPTANCE = 454000001,
  ACCEPTED = 454000002,
  REJECTED = 454000003,
  DRAFT = 454000004,
}

export enum HolidayType {
  VACATION = 453000001,
  MATERNITY_PATERNITY = 453000002,
  CHILDCARE = 453000003,
  UNPAID_HOLIDAYS = 453000004,
  OTHER = 453000005,
}

export enum RepaymentType {
  CONSTANT_ANNUITY = 421000001,
  EQUAL_CAPITAL = 421000002,
  OTHER = 421000003,
}

export enum CountryRiskMitigation {
  LOCAL = 425000001,
  OFFSHORE = 425000002,
  NONE = 425000003,
}

export enum CreditEnhancementSecurity {
  ASSET_BASED = 426000001,
  FIXED_ASSET = 426000002,
  NONE = 426000003,
}

export enum InsuranceCoverOfPremium {
  YES = 427000001,
  NO = 427000002,
  PARTLY = 427000003,
}

export enum ExportRiskCategory {
  LOW = 432000001,
  MEDIUM = 432000002,
  HIGH = 432000003,
}

export enum ClaimRepaymentScheduleVersionStatus {
  REGISTERED = 459000001,
  WAITING_FOR_APPROVAL = 459000002,
  APPROVED = 459000003,
  USER_ERROR = 459000004,
  OBSOLETE = 459000005,
}

export enum ClaimRepaymentScheduleInstalmentType {
  MAIN_DEBT = 461000001,
  CONTRACTUAL_INTEREST = 461000002,
  LATE_PAYMENT_INTEREST = 461000003,
}

export enum ClaimRelatedPartyType {
  EXPORTER = 464000001,
  COINSURED = 464000002,
  SUBINSURED = 464000003,
  SURETY = 464000004,
  BANK_GUARANTEE = 464000005,
}

export enum SalesRepRelationStatus {
  INACTIVE = 184000001,
  ACTIVE = 184000002,
  CANCELLED = 184000003,
}

const IndividualOrIndividualBoosterOrBoosterCodeRegexp: RegExp = /^(?:INDIVIDUAL|IBOOSTER|BOOSTER)$/;

export function getContractTypeCodeRegexp(isCredendo: boolean): RegExp | null {
  if (isCredendo) {
    return IndividualOrIndividualBoosterOrBoosterCodeRegexp;
  }
  return null;
}
