import {Component} from '@angular/core';
import {DashboardService} from '../../../../bonding_shared/services/dashboard.service';
import {ChartRestLoader} from '../chart-rest-loader';
import {BusinessPropertiesService} from '../../../../bonding_shared/services/business-properties.service';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';
import {VMap} from './vmap';
import {SettingsService} from '../../../../bonding_shared/services';
import {StringUtils} from '../../../../bonding_shared/utils';

@Component({
  selector: 'claim-map',
  template: `<div id="{{mapType}}"  style="height: 400px;"></div>`
})
export class ClaimMapComponent extends VMap implements ChartRestLoader {

  constructor(public service: DashboardService, public businessProps: BusinessPropertiesService, public settingsService: SettingsService) {
    super(service, businessProps, settingsService);
    this.applicationModuleId = ApplicationModule.CLAIM;
  }

  onLabelShowFunction(countryData: ClaimCountryPerformance, currency: string): (event: any, label: any, code: string) => void {
    function f(event: any, label: any, code: string): void {
      label.html(
        '<h2>' + ((label[0].firstChild.textContent) ? label[0].firstChild.textContent : label[0].innerHTML) + '</h2>' +
        '<p>Claim reserve: ' + StringUtils.formatNumber(countryData[code] && countryData[code].reserve ? countryData[code].reserve : 0) + '&nbsp;' + currency + '</p>' +
        '<p>Claim premium percent: ' + (countryData[code] && countryData[code].reservePercent ? countryData[code].reservePercent : 0) + '</p>' +
        '<p>Claims count: ' + (countryData[code] && countryData[code].claimsNumber ? countryData[code].claimsNumber : 0) + '</p>' +
        '<p>Claims percent: ' + (countryData[code] && countryData[code].claimsNumberPercent ? countryData[code].claimsNumberPercent : 0) + '</p>'
      );
    }
    return f;
  }

  saveRestResult(result: any) {
    if (result.country) {
      const code: string = result.country.code.toLowerCase();
      this.countryPerformance[code] = {
        reserve: result.reserve,
        reservePercent: result.reservePercent,
        claimsNumber: result.claimsNumber,
        claimsNumberPercent: result.claimsNumberPercent
      };
    }
  }

  getPerformanceValues (claimCountryPerformance: ClaimCountryPerformance): {[key: string]: number} {
    const values: {[key: string]: number} = {};
    for (const k in claimCountryPerformance) {
      if (k) {
        values[k] = claimCountryPerformance[k].reserve;
      }
    }
    return values;
  }
}

export class ClaimCountryPerformance {
  [code: string]: {
    reserve: number;
    reservePercent: number;
    claimsNumber: number;
    claimsNumberPercent: number;
  };
}
