import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'possessed-references-consent',
  template: `
    <div class="row flex">
      <!-- 2 (23) -->
      <checkbox-entity
        class="start"
        name="possessedReferences"
        [(ngModel)]="consents.possessedReferences"
        [readonly]="readonly"
        [showErrors]="showErrors"
      ></checkbox-entity>
      <p>Posiadane referencje</p>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class PossessedReferencesConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
