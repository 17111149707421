import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  BONDING_CONTRACT_OFFER_DETAILS,
  BondingContractOfferDto,
  CompanyRatingService,
  CompanyRatingVersionDto,
  ContractVersionService,
  DictionaryBaseDto,
  GrowlService,
  LoggedUserService,
  RouterService,
  StateTransitionDto,
  TemplateService,
  TemplateSimpleDto,
  UserGroupBaseDto,
  UserIdDto,
  UserService,
  VoteDto,
} from '../../../bonding_shared';
import {
  BondingContractOfferRejectionReason,
  BondingContractOfferStatus,
  BondingElementaryRight,
  BondType,
  BusinessObjectType,
  ContactNoteElementaryRight,
  ContractType,
  Currency,
  DictionaryProfile,
  DocumentType,
  RatingType,
  Source,
  UserGroup,
} from '../../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {BondingContractOfferService} from '../../../bonding_shared/services/bonding-contract-offer.service';
import {Observable} from 'rxjs';
import {NgForm} from '@angular/forms';
import {Button} from '../../../bonding_shared/components/details-view/button';
import {BondingContractOfferVotingDialogComponent} from './components/bonding-contract-offer-voting-dialog.component';
import {BusinessUnit} from '../../../bonding_shared/model/business-unit';
import {DocumentListComponent} from '../../document';
import {GroupedItem} from '../../../bonding_shared/components/item-dropdown/item-grouped-dropdown.component';
import {BondingContractOfferAbstractService} from '../../../bonding_shared/services/bonding-contract-offer-abstract.service';

@Component({
  selector: BONDING_CONTRACT_OFFER_DETAILS,
  templateUrl: './bonding-contract-offer-details.component.pug',
})
export class BondingContractOfferDetailsComponent extends DetailsView implements OnInit, AfterViewInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  @ViewChild('closeReasonForm', {static: false}) closeReasonForm: NgForm;
  @ViewChild('documentLists', {static: true}) documentListComponent: DocumentListComponent;
  @ViewChild('votingDialog')
  set votingDialog(cmp: BondingContractOfferVotingDialogComponent) {
    if (cmp) {
      this._votingDialog = cmp;
    }
  }

  get votingDialog(): BondingContractOfferVotingDialogComponent {
    return this._votingDialog;
  }

  _votingDialog: BondingContractOfferVotingDialogComponent;

  offer: BondingContractOfferDto;
  readonly = false;
  showDetails = false;
  viewParams: BondingContractOfferViewParams;
  bondCurrencyDictProfile = DictionaryProfile.KUKE_BOND_CURRENCY;

  readonly ContractType = ContractType;
  readonly BondingElementaryRight = BondingElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly Currency = Currency;
  readonly BondingContractOfferStatus = BondingContractOfferStatus;
  readonly Source = Source;

  newOfferButton = new Button('bondingContractOffer.details.button.newOffer', this.newOffer.bind(this), true);
  voteButton = new Button('bondingContractOffer.details.voting.vote', this.openVotingDialog.bind(this), false);
  newContractButton = new Button(
    'bondingContractOffer.details.button.newContract',
    this.onCreateFromOffer.bind(this),
    true
  );

  approvalUsers: UserIdDto[] = null;
  draftTemplatesGrouped: GroupedItem[] = [];

  readonly newContractActions = [
    {id: 1, name: 'bondingContractOffer.details.button.newContract'},
    {id: 2, name: 'bondingContractOffer.details.button.markCreatedExternally'},
  ];

  votingGroups: UserGroupBaseDto[] = null;
  showRejectionReasonErrors = false;
  companyRating: CompanyRatingVersionDto;

  constructor(
    protected offerService: BondingContractOfferAbstractService,
    private contractService: ContractVersionService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService,
    private userService: UserService,
    private templateService: TemplateService,
    private companyRatingService: CompanyRatingService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  templates: TemplateSimpleDto[] = [];
  templateUW: TemplateSimpleDto;

  ngOnInit() {
    this.serverErrors = undefined;
    this.commonInit<BondingContractOfferViewParams>(this.route);
    if (this.credendo) {
      this.loadUWTemplate();
    }
  }

  initViewWithId(id: number) {
    this.initOffer(this.offerService.getById<BondingContractOfferDto>(id));
  }

  initViewWithCustomParams(q: BondingContractOfferViewParams) {
    if (this.offerService instanceof BondingContractOfferService) {
      if (q.inquiryVersionId) {
        this.initOffer(this.offerService.initialOffer(+q.inquiryVersionId, null, null));
      } else if (q.edgeOfferId) {
        this.initOffer(this.offerService.initialOffer(null, +q.edgeOfferId, null));
      } else if (q.copyOfferId) {
        this.initOffer(this.offerService.initialOffer(null, null, +q.copyOfferId));
      }
    }
  }

  public ngAfterViewInit(): void {
    this.form = this.ngForm.form;
  }

  initOffer(offerObs: Observable<BondingContractOfferDto>) {
    offerObs.subscribe({
      next: (pll) => this.setOffer(pll),
      error: (error) => {
        this.objectNotFound = true;
        this.handleServerError(error);
      },
    });
  }

  setOffer(inV: BondingContractOfferDto) {
    console.log('set offer: ', inV);
    this.offer = inV;
    this.handleButtons();
    // this.showDetails = showDetails;
    this.loadApprovalUsers();
    if (this.kuke && !this.portal) {
      this.loadTemplates();
      this.loadTemplateForBondingContractDraft();

      const ratingCategoryId =
        this.offer.inquiryVersion.inquiry.businessUnit.id === BusinessUnit.KUKE_GSP
          ? RatingType.GSP
          : RatingType.BONDING;
      this.companyRating = null;
      this.companyRatingService
        .getLastCompanyRatingVersion(this.offer.inquiryVersion.mainClient.id, ratingCategoryId)
        .subscribe((result) => (this.companyRating = result));
    }
  }

  onSave() {
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    const originalId = this.offer.id;
    this.offerService.save(this.offer).subscribe({
      next: (bcv) => {
        this.setOffer(bcv);
        this.afterObjectSaved(originalId, () => this.router.toBondingContractOfferDetails(bcv.id));
      },
      error: (error) => this.handleServerError(error, true, false),
    });
  }

  handleButtons() {
    this.saveButton.disabled = false;
    this.saveButton.hidden = this.readOnly;
    this.cancelButton.hidden = this.readOnly;
    this.newOfferButton.hidden =
      !this.offer ||
      !this.offer.id ||
      !this.offer.edge ||
      this.offer.businessStatus.id !== BondingContractOfferStatus.ACCEPTED;
    this.newContractButton.hidden = this.isNewContractHidden();
  }

  isNewContractHidden(): boolean {
    return (
      (this.kuke && this.portal) ||
      !this.offer ||
      !this.offer.id ||
      this.offer.edge ||
      !!this.offer.contractVersion ||
      this.offer.businessStatus.id !== BondingContractOfferStatus.ACCEPTED
    );
  }

  get readOnly(): boolean {
    return (
      (this.kuke && this.portal) ||
      (this.offer &&
        this.offer.businessStatus &&
        [
          BondingContractOfferStatus.CANCELLED,
          BondingContractOfferStatus.EXPIRED,
          BondingContractOfferStatus.REJECTED,
          BondingContractOfferStatus.ACCEPTED,
          BondingContractOfferStatus.VOTING,
          BondingContractOfferStatus.VOTING_FINISHED,
          BondingContractOfferStatus.CONTRACT_CREATED,
          BondingContractOfferStatus.CONTRACT_CREATED_EXTERNALLY,
        ].includes(this.offer.businessStatus.id))
    );
  }

  get client() {
    return BusinessUtils.getThirdPartyCompany(this.offer && this.offer.inquiryVersion.inquiry.client);
  }

  get edgeOffer(): boolean {
    return this.offer ? this.offer.edge : this.viewParams ? !!this.viewParams.inquiryVersionId : false;
  }

  newOffer() {
    this.onNewOffer(1);
  }

  private onNewOffer(id: 1 | 2) {
    switch (id) {
      case 1:
        this.router.toBondingContractOfferDetails(null, <BondingContractOfferViewParams>{edgeOfferId: this.offer.id});
        break;
      case 2:
        this.router.toBondingContractOfferDetails(null, <BondingContractOfferViewParams>{copyOfferId: this.offer.id});
        break;
      default:
        console.log('unknown action');
    }
  }

  onCreateFromOffer() {
    this.onNewContract(1);
  }

  onNewContract(id: number) {
    switch (id) {
      case 1:
        this.contractService.createFromOffer(this.offer.id).subscribe({
          next: (cv) => this.router.toContractDetails(cv.id),
          error: (error) => this.handleServerError(error),
        });
        break;
      case 2:
        if (this.offerService instanceof BondingContractOfferService) {
          this.offerService.markCreatedExternally(this.offer.id).subscribe({
            next: (bcv) => {
              this.setOffer(bcv);
              this.afterObjectSaved();
            },
            error: (error) => this.handleServerError(error),
          });
        }
        break;
      default:
        console.log('unknown action');
    }
  }

  openVotingDialog() {
    this.votingDialog.openDialog();
  }

  onCancel(): void {
    this.clearErrors();
    this.commonInit<BondingContractOfferViewParams>(this.route);
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  doVote(vote: VoteDto) {
    if (this.offerService instanceof BondingContractOfferService) {
      this.offerService.doVote(this.offer.id, vote).subscribe({
        next: (bcv) => {
          this.setOffer(bcv);
          this.afterObjectSaved();
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  get showLifecycleButton() {
    return this.kuke && this.portal
      ? this.offer?.businessStatus?.id === BondingContractOfferStatus.SENT_TO_CLIENT
      : this.offer?.businessStatus?.id !== BondingContractOfferStatus.CANCELLED;
  }

  get showStateTransitionConfirmation(): (transition: StateTransitionDto) => boolean {
    return (transition: StateTransitionDto) => {
      this.initVotingGroups(transition);
      return (
        transition.newStatus.id === BondingContractOfferStatus.REJECTED ||
        transition.newStatus.id === BondingContractOfferStatus.CANCELLED ||
        transition.newStatus.id === BondingContractOfferStatus.VOTING
      );
    };
  }

  private initVotingGroups(transition: StateTransitionDto) {
    if (transition.newStatus.id === BondingContractOfferStatus.VOTING && !this.votingGroups) {
      this.userService
        .getUserGroups()
        .subscribe(
          (groups) => (this.votingGroups = groups.filter((g) => [UserGroup.EXCOM, UserGroup.SURCOM].includes(g.id)))
        );
    }
  }

  get rejectionReasonDictionaryProfile(): number | undefined {
    return this.portal ? DictionaryProfile.PORTAL_BONDING_CONTRACT_OFFER_REJECT_REASON : undefined;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  copyOffer() {
    this.onNewOffer(2);
  }

  get hiddenRejectionReasonIds(): number[] {
    return this.offer?.inquiryVersion?.inquiry?.businessUnit?.id === BusinessUnit.KUKE_GSP
      ? [
          BondingContractOfferRejectionReason.MINIMAL_PREMIUM_TOO_HIGH,
          BondingContractOfferRejectionReason.INSUFFICIENT_LIMIT,
        ]
      : [];
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  private loadApprovalUsers() {
    if (this.approvalUsers || !this.kuke || this.readOnly) {
      return;
    }
    this.userService.getIntranetUsers().subscribe((users) => {
      this.approvalUsers = users;
    });
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.offer.productType);

    this.templateService
      .findByType(
        undefined,
        BusinessObjectType.BONDING_CONTRACT_OFFER,
        undefined,
        dictionarySelectors,
        undefined,
        undefined,
        undefined,
        true
      )
      .subscribe((result) => {
        this.templates = result;
      });
  }

  private loadUWTemplate() {
    this.templateService
      .findByType(DocumentType.UNDERWRITING_ASSESSMENT, BusinessObjectType.BONDING_CONTRACT_OFFER, null)
      // tslint:disable-next-line:no-shadowed-variable
      .subscribe((result) =>
        result.length > 1 ? console.error('There should be only one template!') : (this.templateUW = result[0])
      );
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.offer.id);
  }

  public newDocument(template: TemplateSimpleDto): void {
    this.router.toDocumentDetailsNew(template.id, this.offer.id);
  }

  confirmationValidInput(): (transition: StateTransitionDto) => () => boolean {
    return (t) => {
      if (
        t &&
        (t.newStatus.id === BondingContractOfferStatus.REJECTED ||
          t.newStatus.id === BondingContractOfferStatus.CANCELLED)
      ) {
        return () => this.validRejectionInput();
      }
      return () => true;
    };
  }

  validRejectionInput(): boolean {
    if (!this.closeReasonForm) {
      return true;
    }
    this.showRejectionReasonErrors = !this.closeReasonForm.valid;
    return this.closeReasonForm.valid;
  }

  newDocumentDisabled() {
    return (
      this.inProgress ||
      !this.templateUW ||
      !this.templateUW?.id ||
      !this.offer ||
      !this.offer?.id ||
      !(
        this.offer?.status?.id === BondingContractOfferStatus.NEW ||
        this.offer?.status?.id === BondingContractOfferStatus.NBI_OFFER
      )
    );
  }

  createPrepareSuretyProposalTask() {
    this.offerService.createPrepareSuretyProposalTask(this.offer.id).subscribe((taskId) => {
      this.router.toTaskDetails(taskId);
    });
  }

  isAvailablePandaBondingContract() {
    return true;
  }

  generateDraftPdf(template: GroupedItem) {
    const oid = this.offer.id;
    this.offerService.getBondingContractDraftDocument(
      oid,
      template.id,
      template.params.find((item) => item.key === 'name').value + '_' + oid + '.pdf'
    );
  }

  private loadTemplateForBondingContractDraft() {
    this.offerService.loadTemplateForBondingContractDraft(this.offer).subscribe((result) => {
      this.draftTemplatesGrouped = result.map((t) => {
        const parentItem = <GroupedItem>{name: t.name, children: []};

        parentItem.children.push(<GroupedItem>{
          name: 'bondingContractOffer.details.button.draftBondingContractGenerate',
          id: t.id,
          params: [
            {key: 'operation', value: 'generate'},
            {key: 'name', value: t.name},
          ],
        });

        if (this.isAvailablePandaBondingContract()) {
          parentItem.children.push(<GroupedItem>{
            name: 'bondingContractOffer.details.button.pandaDraftBondingContractButton',
            id: t.id,
            params: [
              {key: 'operation', value: 'sendToRepo'},
              {key: 'name', value: t.name},
            ],
          });
        }

        return parentItem;
      });
    });
  }

  private generateDraftPdfAndSendPanda(templateId: number) {
    this.serverErrors = null;
    this.showErrors = false;
    this.inProgress = true;

    this.offerService.uploadDraftBondingContract(templateId, this.offer.id).subscribe({
      next: () => this.growlService.notice('Bonding contract draft has been sent!'),
      error: (error) => {
        this.showErrors = true;
        this.serverErrors = error;
        this.inProgress = false;
      },
      complete: () => (this.inProgress = false),
    });
  }

  onPrintGroupedSelected(template: GroupedItem) {
    if (template.params.find((item) => item.key === 'operation').value === 'sendToRepo') {
      this.generateDraftPdfAndSendPanda(template.id);
    }
    if (template.params.find((item) => item.key === 'operation').value === 'generate') {
      this.generateDraftPdf(template);
    }
  }

  get hiddenBondTypes() {
    const hiddenBondTypes = new Set();

    if (!this.offer.inquiryVersion) {
      return undefined;
    }
    if (
      (this.offer.inquiryVersion.exporterCriteriaMet && this.offer.inquiryVersion.mitigatingClimateChange) ||
      (!this.offer.inquiryVersion.exporterCriteriaMet && !this.offer.inquiryVersion.mitigatingClimateChange)
    ) {
      return undefined;
    }
    if (this.offer.inquiryVersion.exporterCriteriaMet) {
      hiddenBondTypes.add(BondType.CREDIT_PAYMENT_ENERGETIC);
      hiddenBondTypes.add(BondType.DELIVERY_PAYMENT_ENERGETIC);
      hiddenBondTypes.add(BondType.FACTORING_PAYMENT_ENERGETIC);
      hiddenBondTypes.add(BondType.DELIVERY_CHAIN_PAYMENT_ENERGETIC);
      hiddenBondTypes.add(BondType.KUKE_DELIVERY_PRODUCTION_PAYMENT_ENERGETIC);
    } else if (this.offer.inquiryVersion.mitigatingClimateChange) {
      hiddenBondTypes.add(BondType.CREDIT_PAYMENT);
      hiddenBondTypes.add(BondType.DELIVERY_PAYMENT);
      hiddenBondTypes.add(BondType.FACTORING_PAYMENT);
      hiddenBondTypes.add(BondType.CREDIT_PAYMENT_FACTORING);
      hiddenBondTypes.add(BondType.KUKE_DELIVERY_PRODUCTION_PAYMENT);
    }
    return hiddenBondTypes;
  }
}

export class BondingContractOfferViewParams {
  // string when reading
  readFromCache?: string | boolean;
  inquiryVersionId?: string | number;
  edgeOfferId?: string | number;
  copyOfferId?: string | number;
}
