import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {
  AddressDto,
  AppConfigService,
  BankAccountDto,
  CacheService,
  ClaimService,
  ClaimVersionDto,
  CollectionPaymentScheduleDto,
  CollectionPaymentsDto,
  CollectionSimpleDto,
  CollectionVersionBaseDto,
  CollectionVersionDto,
  CollectionVersionService,
  ConfirmDialogComponent,
  ContactPersonDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  FormatService,
  FormDialogComponent,
  GrowlService,
  LoggedUserService,
  PolicyContractService,
  PolicyContractTechnicalDetailsDto,
  PolicyContractVersionService,
  RepositoryService,
  RouterService,
  StringUtils,
  TemplateService,
  UserRange,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  CollectionElementaryRight,
  CollectionPaymentAllocation,
  CollectionQuestionnaire,
  CollectionRecoveryLedBy,
  CollectionRecoveryType,
  CollectionStatus,
  CollectionStatusGroup,
  CollectionType,
  ContactNoteElementaryRight,
  ContactPersonGroupType,
  ContactPersonType,
  Currency,
  DocumentType,
  KukeRepoOperation,
  PolicyContractType,
  ProfileQuotaType,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
  Template,
  UserRole,
} from '../../bonding_shared/model/dictionary-ids';
import {CollectionGuiService} from './services/collection-gui.service';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {GroupedItem} from '../../bonding_shared/components/item-dropdown/item-grouped-dropdown.component';
import {PortalCollectionClaimService} from '../../portal/services/portal-collection-claim.service';
import * as _ from 'lodash';
import {CollectionSelectorsComponent} from './components/collection-selectors.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'collection-details',
  templateUrl: 'collection-details.component.pug',
})
export class CollectionDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('selectors', {static: true}) selectors: CollectionSelectorsComponent;
  readonly ProfileQuotaType = ProfileQuotaType;
  readonly ContactPersonGroupType = ContactPersonGroupType;
  readonly CollectionElementaryRight = CollectionElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly excludedDocumentTypesIds: number[] = [
    DocumentType.COLLECTION_DEFAULT_EMAIL,
    DocumentType.COURT_INSTRUCTION_PROMISSORY_NOTE,
    DocumentType.COLLECTION_CASE_REGISTRATION_FOR_ARCHIVES,
    DocumentType.COLLECTION_HISTORY,
    DocumentType.LEADING_BOARD_EMAIL,
  ];
  version = CollectionGuiService.getEmptyVersion();
  dialogVersion: CollectionVersionDto = <CollectionVersionDto>{};
  technicalDetails: PolicyContractTechnicalDetailsDto;
  versions = <CollectionVersionBaseDto[]>[];
  paymentItems;
  costsItems;
  claimVersion: ClaimVersionDto;
  bankAccount = <BankAccountDto>{};
  templatesGrouped: GroupedItem[] = [];
  public equivalentCurrency: DictionaryBaseDto;
  private _otherCreditor = false;
  private _otherDebtor = false;
  private originalStatus: DictionaryBaseDto;
  private firstVersionSaved = false;
  public firstVersion = false;
  lastPaymentAmountValue: number;
  lastPaymentAmountCurrency: DictionaryBaseDto;
  lastPaymentDate: Date;
  private readonly commentRequiredStatuses = [
    CollectionStatus.COLLECTION_DESISTED,
    CollectionStatus.DISPUTE_PARTIAL_DESISTANCE,
    CollectionStatus.PARTIAL_REPAYMENT_REMISSION,
    CollectionStatus.REMISSION,
    CollectionStatus.CASE_CLOSED_OF_CLIENT_S_FAULT,
    CollectionStatus.FRAUD_CASE_CLOSED,
  ];

  get parent(): CollectionSimpleDto {
    return this.version.parent;
  }

  set parent(value: CollectionSimpleDto) {
    this.version.parent = value;
  }

  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactPersonType = ContactPersonType;
  readonly CollectionRecoveryType = CollectionRecoveryType;
  readonly CollectionRecoveryLedBy = CollectionRecoveryLedBy;
  readonly CollectionType = CollectionType;
  readonly UserRole = UserRole;

  readonly collectionUserRange: UserRange = 'collection';
  readonly lawyerUserRange: UserRange = 'lawyer';

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  _changeBankAccountDialog: FormDialogComponent;
  @ViewChild('changeBankAccount')
  set changeBankAccountDialog(changeBankAccountDialog: FormDialogComponent) {
    this._changeBankAccountDialog = changeBankAccountDialog;
  }

  get changeBankAccountDialog(): FormDialogComponent {
    return this._changeBankAccountDialog;
  }
  _changeStatusDialog: FormDialogComponent;
  @ViewChild('changeStatusDialog')
  set changeStatusDialog(changeStatusDialog: FormDialogComponent) {
    this._changeStatusDialog = changeStatusDialog;
  }

  get changeStatusDialog(): FormDialogComponent {
    return this._changeStatusDialog;
  }
  @ViewChild('invoiceInfoDialog', {static: true}) invoiceInfoDialog: FormDialogComponent;
  @ViewChild(ConfirmDialogComponent, {static: true}) confirmDialog: ConfirmDialogComponent;
  @ViewChild('toClaimNotValid') toClaimNotValid: ConfirmDialogComponent;
  @ViewChild('cancellationWithoutWaiverDialog', {static: true}) cancellationWithoutWaiverDialog: FormDialogComponent;
  @ViewChild('cancelWithWaiverDialog') cancelWithWaiverDialog: ConfirmDialogComponent;
  @ViewChild('cancelWithWaiverDialog2') cancelWithWaiverDialog2: ConfirmDialogComponent;
  @ViewChild('cancelWithWaiverDialog3') cancelWithWaiverDialog3: ConfirmDialogComponent;
  @ViewChild('changeBankAccountConfirmationDialog') changeBankAccountConfirmationDialog: ConfirmDialogComponent;

  constructor(
    protected _route: ActivatedRoute,
    public service: CollectionVersionService,
    protected policyContractService: PolicyContractService,
    public guiService: CollectionGuiService,
    protected policyContractVersionService: PolicyContractVersionService,
    public router: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected templateService: TemplateService,
    private loggedUserService: LoggedUserService,
    protected dictBaseService: DictionaryBaseService,
    protected cacheService: CacheService,
    private formatService: FormatService,
    private portalCollectionClaimService: PortalCollectionClaimService,
    public appService: AppConfigService,
    private repositoryService: RepositoryService,
    protected claimService: ClaimService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.initializeSelectors();
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.dictBaseService
      .getDictionaryEntry('Currency', Currency.PLN)
      .subscribe((cur) => (this.equivalentCurrency = cur));
    const versionId = +params['versionId'];
    this.serverErrors = undefined;
    if (versionId) {
      this.getVersion(versionId);
    } else {
      this.firstVersion = true;
      this._route.queryParams.subscribe((q: CollectionInitialParams) => {
        if (this.portal) {
          this.policyContractVersionService.getClientPolicies().subscribe((policies) => {
            if (policies.result && policies.result.length > 1) {
              alert(this.translateService.instant('collection.portalPolicyAlert'));
            }
          });
        }
        if (q.readFromCache && this.cacheService.collection) {
          this.loadInitialVersion(this.cacheService.collection);
        } else if (q.typeId) {
          this.dictBaseService.getDictionaryEntry('CollectionType', +q.typeId).subscribe((t) => {
            const v = CollectionGuiService.getEmptyVersion();
            v.parent.type = t;
            this.setVersion(v);
            this.selectors.setPolicyCriteriaAndCloseSelectors();
          });
        } else {
          this.setVersion(CollectionGuiService.getEmptyVersion());
        }
      });
    }
  }

  initializeSelectors() {
    this.selectorNameList = ['ExternalServiceProvider', 'CurrentCreditor', 'CurrentDebtor'];
    this.initializeSelectorEmitters(true);
  }

  onSave() {
    this.validateAndCall(() => this.service.save(this.version));
  }

  public get commentRequiredForStatus(): boolean {
    return this.version.status && this.commentRequiredStatuses.includes(this.version.status.id);
  }

  onStatusChange() {
    if (this.commentRequiredForStatus) {
      this.dialogVersion = JSON.parse(JSON.stringify(this.version));
      this.openChangeStatusDialog();
    } else {
      this.updateVersion(() => this.service.save(this.version));
    }
  }

  openChangeStatusDialog() {
    this.changeStatusDialog.open('collection.details.changeStatus').then((result) => {
      if (result === true) {
        this.updateVersion(() => this.service.save(this.dialogVersion));
      } else if (result === false) {
        this.version.status = this.originalStatus;
      } else {
        this.version.status = this.originalStatus;
      }
    });
  }

  updateVersion(backendCall: () => Observable<CollectionVersionDto>) {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      backendCall().subscribe(
        (version) => {
          this.serverErrors = undefined;
          this.inProgress = false;
          if (version.warnings && version.warnings.length) {
            this.growlService.warning(version.warnings.map((e) => e.message).join('\n'));
          }
          this.setVersion(version);
        },
        (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        }
      );
    }
  }

  private validateAndCall(backendCall: () => Observable<CollectionVersionDto>) {
    if (this.version.parent.id) {
      this.updateVersion(backendCall);
    } else {
      this.serverErrors = undefined;
      this.showErrors = false;
      if (this.formValidates()) {
        if (
          this.loggedUserService.portal &&
          !this.version.id &&
          CollectionType.CREDIT_INSURANCE.includes(this.version.parent.type.id)
        ) {
          this.invoiceInfoDialog.openAndExecuteOnConfirm(() => {
            this.save(backendCall);
          });
        } else {
          this.save(backendCall);
        }
      }
    }
  }

  private save(backendCall: () => Observable<CollectionVersionDto>) {
    this.inProgress = true;
    this.firstVersionSaved = !(this.parent.id && this.parent.id > 0);
    backendCall().subscribe(
      (version) => {
        if (version.warnings && version.warnings.length) {
          this.inProgress = false;
          this.growlService.warning(version.warnings.map((e) => e.message).join('\n'));
        } else {
          this.afterObjectSaved(this.version.id, () => this.router.toCollectionDetails(version.id));
        }
        this.setVersion(version);
        this.firstVersion = false;
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  showSavedMsg(msg?: string) {
    if (this.firstVersionSaved) {
      alert(this.translateService.instant('collection.details.savedDetails'));
    }
  }

  handleButtons() {
    this.newVersionButton.hidden = !this.version.id || this.portal;
    this.newVersionButton.disabled = !this.version.last;
    this.saveButton.hidden = !!this.version.id || !this.isInitialized();
    this.cancelButton.hidden = !!this.version.id;
    this.paymentItems =
      this.version.parent &&
      this.version.parent.type &&
      this.version.parent.type.id === CollectionType.RECOVERY_CREDIT_INSURANCE
        ? [
            !this.isDuePremiums() && {
              name: 'collection.details.insurersPayments',
              action: this.router.toCollectionPaymentsForInsurer.bind(this.router),
            },
            !this.isDuePremiums() && {
              name: 'collection.details.insuredsPayments',
              action: this.router.toCollectionPaymentsForInsured.bind(this.router),
            },
            {
              name: 'collection.paymentSchedules.title',
              action: this.router.toCollectionPaymentSchedule.bind(this.router),
            },
          ]
        : [
            !this.isDuePremiums() && {
              name: 'collection.details.payments',
              action: this.router.toCollectionPayments.bind(this.router),
            },
            {
              name: 'collection.paymentSchedules.title',
              action: this.router.toCollectionPaymentSchedule.bind(this.router),
            },
          ];
    this.costsItems = !this.portal && [
      {name: 'collectionCosts.accountedCosts', action: this.router.toCollectionAccountedCosts.bind(this.router)},
      {name: 'collectionCosts.estimatedCosts', action: this.router.toCollectionEstimatedCosts.bind(this.router)},
    ];
  }

  isInitialized() {
    return this.version.validFrom;
  }

  loadInitialVersion(parent: CollectionSimpleDto) {
    this.service.initialVersion(parent).subscribe((version) => this.setVersion(version));
  }

  onCreateNewVersion() {
    this.showErrors = false;
    this.service.newVersion<CollectionVersionDto>(this.parent.id).subscribe((version) => {
      this.setVersion(version);
    });
  }

  onCancel() {
    super.onCancel(this._route);
  }

  getVersion(versionId: number) {
    this.service.getById<CollectionVersionDto>(versionId).subscribe(
      (version) => {
        this.setVersion(version);
      },
      (error) => {
        this.objectNotFound = true;
      }
    );
  }

  setVersion(version: CollectionVersionDto) {
    this.version = version;
    this.originalStatus = version.status;
    this.objectNotFound = false;
    this.inProgress = false;
    if (this.version.parent.claim && this.version.parent.claim.policyContract && !this.portal) {
      this.policyContractService.getTechnicalDetails(this.version.parent.claim.policyContract.id).subscribe((data) => {
        this.technicalDetails = data;
      });
    } else if (this.version.parent.policy && !this.portal) {
      this.policyContractService.getTechnicalDetails(this.version.parent.policy.id).subscribe((data) => {
        this.technicalDetails = data;
      });
    }
    this._otherCreditor = this.version.currentCreditor !== undefined;
    this._otherDebtor = this.version.currentDebtor !== undefined;
    this.handleButtons();
    this.refreshVersions();
    this.loadTemplates();
    if (this.portal && this.firstVersion && !this.version.parent.creditor) {
      this.version.parent.creditor = this.loggedUserService.getLoggedUserData().company;
    }
    if (this.isRecoveryBonding() && this.version.parent.claim) {
      this.loadClaimVersion(this.version.parent.claim?.id);
    }
    this.setLastPayment();
  }

  setLastPayment() {
    this.lastPaymentAmountValue = 0;
    if (this.version?.parent?.payments?.mainDebt?.length > 0) {
      this.version.parent.payments.mainDebt.forEach((payment) => {
        if (!this.isRecovery) {
          if (DateUtils.compare(this.lastPaymentDate, payment.dateOfPayment) < 0) {
            this.lastPaymentAmountCurrency = payment.currency;
            this.lastPaymentAmountValue = payment.amountGross;
            this.lastPaymentDate = payment.dateOfPayment;
          } else if (DateUtils.compare(this.lastPaymentDate, payment.dateOfPayment) === 0) {
            if (this.lastPaymentAmountCurrency?.id > payment.currency?.id) {
              this.lastPaymentAmountCurrency = payment.currency;
              this.lastPaymentAmountValue = payment.amountGross;
              this.lastPaymentDate = payment.dateOfPayment;
            } else if (this.lastPaymentAmountCurrency?.id === payment.currency?.id) {
              this.lastPaymentAmountValue += payment.amountGross;
            }
          }
        } else if (
          payment.allocation.id === CollectionPaymentAllocation.INSURER ||
          payment.allocation.id === CollectionPaymentAllocation.GENERAL
        ) {
          const paymentDate =
            payment.allocation.id === CollectionPaymentAllocation.INSURER
              ? payment.dateOfPaymentInsurer
              : payment.dateOfPayment;

          if (DateUtils.compare(this.lastPaymentDate, paymentDate) < 0) {
            this.lastPaymentAmountCurrency = payment.currency;
            this.lastPaymentAmountValue = payment.amountGross;
            this.lastPaymentDate = paymentDate;
          } else if (DateUtils.compare(this.lastPaymentDate, paymentDate) === 0) {
            if (this.lastPaymentAmountCurrency?.id > payment.currency?.id) {
              this.lastPaymentAmountCurrency = payment.currency;
              this.lastPaymentAmountValue = payment.amountGross;
              this.lastPaymentDate = paymentDate;
            } else if (this.lastPaymentAmountCurrency?.id === payment.currency?.id) {
              this.lastPaymentAmountValue += payment.amountGross;
            }
          }
        }
      });
    }
  }

  areTheSameCurrencies(array: any[]): boolean {
    return array && array.every((inv) => inv.currency && inv.currency.id === this.version.currency.id);
  }

  getFirstInvoiceDate() {
    return _.min(this.version.collectionInvoices.map((i) => i.issueDate));
  }

  getFirstInvoicePaymentDate() {
    return _.min(this.version.collectionInvoices.map((i) => i.dueDate));
  }

  refreshVersions() {
    const index = this.versions.findIndex((version) => version.id === this.version.id);
    if (index >= 0) {
      this.versions[index] = this.version;
    } else if (this.parent.id) {
      this.service.getVersionsByParentId(this.parent.id).subscribe((res) => (this.versions = res.result));
    }
  }

  creatingAcceptanceTask(): boolean {
    return this.version.task && !this.version.task.id;
  }

  decideAcceptanceTask(): boolean {
    return (
      this.version.task &&
      this.version.task.id &&
      this.version.task.user &&
      this.loggedUserService.isThisUser(this.version.task.user.id)
    );
  }

  accept() {
    this.updateVersion(() => this.service.accept(this.version.id));
  }

  reject() {
    this.updateVersion(() => this.service.reject(this.version.id));
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get disabled(): boolean {
    return !!this.version.id;
  }

  get caseNotClosed(): boolean {
    return this.version.statusGroup.id !== CollectionStatusGroup.CLOSED;
  }

  get cancellationDialogContext() {
    return {
      mail: this.version.collector.email,
      notificationDate: this.formatService.formatDate(this.version.notificationDate),
      debtor: (this.parent.debtor
        ? this.parent.debtor
        : BusinessUtils.getThirdPartyCompany(this.parent.thirdParty).company
      ).registrationName,
      caseNumber: this.parent.number,
      balance: this.version.balance + ' ' + this.version.currency.code,
    };
  }

  cancellationWithWaiver() {
    this.inProgress = true;
    const messageParams = {
      collectorEmail: this.version.collector.email,
    };
    this.cancelWithWaiverDialog.openAndExecuteOnConfirm(
      'collection.details.cancelRequestWithWaiver',
      'collection.details.cancelRequestWithWaiverMessage',
      () => this.cancellationWithWaiverStep2(),
      () => (this.inProgress = false),
      messageParams
    );
  }

  get debtorRegistrationName(): string {
    return !!this.parent.debtor
      ? this.parent.debtor.registrationName
      : !!this.parent.thirdParty.company
      ? this.parent.thirdParty.company.registrationName
      : !!this.parent.thirdParty.newCompanyRaw
      ? this.parent.thirdParty.newCompanyRaw.registrationName
      : this.parent.thirdParty.lastCompanyRaw.registrationName;
  }

  get debtorAddress(): AddressDto {
    return !!this.parent.debtor
      ? this.parent.debtor.address
      : this.parent.thirdParty.company
      ? this.parent.thirdParty.company.address
      : !!this.parent.thirdParty.newCompanyRaw
      ? this.parent.thirdParty.newCompanyRaw.address
      : this.parent.thirdParty.lastCompanyRaw.address;
  }

  cancellationWithWaiverStep2() {
    const messageParams = {
      registrationDate: DateUtils.format(this.version.requestDate),
      debtor: this.debtorRegistrationName + ' ' + StringUtils.prettyAddress(this.debtorAddress),
      collectionNumber: this.parent.number + '',
      registeredAmount: StringUtils.formatNumber(this.version.totalOpenAmount) + ' ' + this.version.currency.code,
      openAmount: StringUtils.formatNumber(this.version.balance) + ' ' + this.version.currency.code,
      policyNumber: this.parent.policy.number + '',
    };
    this.cancelWithWaiverDialog2.openAndExecuteOnConfirm(
      'collection.details.cancelRequestWithWaiver',
      'collection.details.cancelRequestWithWaiverMessageStep2',
      () => this.cancellationWithWaiverStep3(),
      () => (this.inProgress = false),
      messageParams
    );
  }

  cancellationWithWaiverStep3() {
    this.service.cancelWithClaimWaiver(this.version).subscribe(
      (res) => {
        this.setVersion(res);
        const messageParams = {
          repositoryId: DateUtils.format(this.parent.creationDate),
        };
        this.cancelWithWaiverDialog3.openAndExecuteOnConfirm(
          'collection.details.cancelRequestWithWaiver',
          '',
          () => (this.inProgress = false),
          () => (this.inProgress = false),
          messageParams
        );
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  cancellationWithoutWaiver() {
    this.inProgress = true;
    this.cancellationWithoutWaiverDialog.openAndExecuteOnConfirm(() => {
      this.service.cancelWithoutClaimWaiver(this.version.id).subscribe(
        (res) => this.setVersion(res),
        (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        }
      );
    });
  }

  get cancellationWithoutWaiverDialogText(): string {
    if (this.isInitialized()) {
      const messageParams = {
        registrationDate: DateUtils.format(this.version.requestDate),
        debtor: this.debtorRegistrationName + ' ' + StringUtils.prettyAddress(this.debtorAddress, true),
        collectionNumber: this.parent.number,
        openAmount: StringUtils.formatNumber(this.version.balance) + ' ' + this.version.currency.code,
      };
      return this.translateService.instant('collection.details.cancellationWithoutWaiver', messageParams);
    }
    return '';
  }

  isCancellationWithoutWaiverAvailable(): boolean {
    return !this.version.collectionObligation;
  }

  get otherCreditor(): boolean {
    return this._otherCreditor;
  }

  set otherCreditor(v: boolean) {
    if (!v) {
      this.version.currentCreditor = null;
    }
    this._otherCreditor = v;
  }

  get otherDebtor(): boolean {
    return this._otherDebtor;
  }

  set otherDebtor(v: boolean) {
    if (!v) {
      this.version.currentDebtor = null;
    }
    this._otherDebtor = v;
  }

  loadTemplates() {
    this.templatesGrouped = [];
    this.templateService.findByType(undefined, BusinessObjectType.COLLECTION, null, []).subscribe((result) => {
      if (result !== undefined && result !== null) {
        result.forEach((r) => {
          const templateGrouped = this.templatesGrouped.find((e) => e.name === r.type.name);
          if (templateGrouped) {
            if (!templateGrouped.children) {
              templateGrouped.children = [];
            }
            templateGrouped.children.push(<GroupedItem>{name: r.name, id: r.id, typeId: r.type.id});
          } else {
            this.templatesGrouped.push(<GroupedItem>{
              name: r.type.name,
              typeId: r.type.id,
              children: [<GroupedItem>{name: r.name, id: r.id, typeId: r.type.id}],
            });
          }
        });
      }
    });
  }

  loadClaimVersion(claimId: number) {
    this.claimService.getLastVersionByClaimId(claimId).subscribe(
      (claimVersion) => {
        this.claimVersion = claimVersion;
      },
      (error) => {
        this.objectNotFound = true;
      }
    );
  }

  onPrintSelected(template: GroupedItem): void {
    const repoOperationId =
      template.id === Template.APPLICATION_TO_THE_MANAGMENT_BOARD_COLECTION ||
      template.id === Template.APPLICATION_TO_THE_KRU_COLECTION
        ? KukeRepoOperation.PROPOSALS
        : KukeRepoOperation.WORDINGS;
    this.router.toDocumentDetailsNewOperations(repoOperationId, template.id, this.parent.id, template.typeId);
  }

  get isRecovery(): boolean {
    return this.parent?.type && CollectionType.RECOVERY_TYPES.includes(this.parent?.type?.id);
  }

  isDuePremiums(): boolean {
    return this.parent.type && CollectionType.DUE_PREMIUMS === this.parent.type.id;
  }

  isRecoveryCreditInsurance(): boolean {
    return CollectionGuiService.isRecoveryCreditInsurance(this.parent);
  }
  isCreditInsurance(): boolean {
    return CollectionGuiService.isCreditInsurance(this.parent);
  }

  isRecoveryBonding(): boolean {
    return CollectionGuiService.isRecoveryBonding(this.parent);
  }

  get collectionRecoveryTypeHiddenIds(): number[] {
    return this.isRecoveryBonding() ? [CollectionRecoveryType.TOGETHER] : [];
  }

  get isOwnCollection(): boolean {
    const questionaireAnswer = this.version.questionnaire.find(
      (q) => q.question.id === CollectionQuestionnaire.NOT_LED_BY_INSURER
    );
    return questionaireAnswer ? questionaireAnswer.checked : false;
  }

  showNewClaimButton(): boolean {
    return !!(this.isCreditInsurance() && this.parent.debtor);
  }

  openChangeAccountDialog() {
    this.changeBankAccountConfirmationDialog.openAndExecuteOnConfirm(
      'collection.details.changeBankAccount',
      'collection.details.changeBankAccountConfirm',
      () => {
        this.changeBankAccountDialog.openAndExecuteOnConfirm(
          () =>
            new Promise<void>((resolve, reject) => {
              this.service.changeBankAccount(this.parent.id, this.bankAccount).subscribe(
                (c) => {
                  this.afterObjectSaved();
                  this.router.toCollectionDetails(c.id);
                  resolve();
                },
                (e) => reject(e)
              );
            })
        );
      }
    );
  }

  isAsymmetricTransaction(): boolean {
    const answer =
      this.version.questionnaire &&
      this.version.questionnaire.find(
        (q) => q.question.id === CollectionQuestionnaire.ASYMMETRIC_TRANSACTIONS_STATEMENT
      );
    return answer && answer.checked;
  }

  onContactRequest(clicked: boolean) {
    this.version.request = clicked ? CollectionGuiService.getNewRequest() : undefined;
  }

  goToTasks() {
    this.router.toTaskSearchWithBo(BusinessObjectType.COLLECTION, this.version.parent.id, false);
  }

  getEmpty(): CollectionPaymentsDto {
    return <CollectionPaymentsDto>{
      mainDebt: [],
      other: [],
      equivalentAmount: 0,
      equivalentCurrency: <DictionaryBaseDto>{id: Currency.PLN},
    };
  }

  getCollectionBalanceMode(): string {
    return CollectionGuiService.getCollectionBalanceMode(this.version);
  }

  get nextInstallmentDate(): Date {
    const nextInstallment =
      this.parent.activePaymentUnpaidSchedule &&
      this.parent.activePaymentUnpaidSchedule.find((s) => s.dueDate.getTime() > Date.now());
    return nextInstallment && nextInstallment.dueDate;
  }

  getPaymentScheduleItems(): CollectionPaymentScheduleDto[] {
    if (this.isCreditInsurance()) {
      const now = Date.now();
      return this.parent.activePaymentUnpaidSchedule.filter((s) => s.dueDate.getTime() < now && s.amountGross > 0);
    } else {
      return this.parent.lastSchedule ? [this.parent.lastSchedule] : [];
    }
  }

  getContactPersons(groupType: ContactPersonGroupType): ContactPersonDto[] {
    return this.version.contactPersonGroups.find((g) => g.type.id === groupType).persons;
  }

  toServiceContacts() {
    this.router.toServiceContacts({boTypeId: BusinessObjectType.COLLECTION, boId: this.version.parent.id});
  }

  toRelatedCases() {
    this.router.toCollectionRelatedCases(this.version.id);
  }

  toClaimDetailsNew() {
    if (this.kuke && this.portal) {
      this.portalCollectionClaimService.validateClaimFromCollection(this.parent.id).subscribe(
        (res) => {
          if (res) {
            this.router.toClaimDetailsNew({collectionId: this.parent.id});
          } else {
            this.openToClaimNotValid();
          }
        },
        (error) => this.openToClaimNotValid()
      );
    } else {
      this.router.toClaimDetailsNew({collectionId: this.parent.id});
    }
  }

  openToClaimNotValid() {
    this.toClaimNotValid.open(
      this.translateService.instant('collection.details.toClaimNotValidHeader'),
      this.translateService.instant('collection.details.toClaimNotValidMessage')
    );
  }

  get isPbgProduct() {
    return this.parent && this.parent.policy && this.parent.policy.type.id === PolicyContractType.KUKE_GSP_PBG;
  }

  notificationDateChanged(newNotificationDate: Date) {
    if (this.firstVersion) {
      this.version.requestDate = newNotificationDate;
    }
  }

  calculateCollectionDay(): number {
    return DateUtils.daysBeforeToday(this.parent.creationDate) + 1;
  }

  getDocument() {
    if (this.version.documentRepositoryId) {
      this.repositoryService.getRepositoryDocument(
        this.version.documentRepositoryId,
        this.version.parent.number + '.pdf'
      );
    }
  }

  get collectorSelectionDisabled() {
    return this.firstVersion && this.version?.parent?.type?.id !== CollectionType.RECOVERY_CREDIT_INSURANCE;
  }

  get otherCreditorEditable() {
    return !(this.portal && !!this.version?.parent?.id);
  }
}

// string when reading
export interface CollectionInitialParams {
  typeId?: string | number;
  readFromCache?: string | boolean;
}
