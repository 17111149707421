import {Component} from '@angular/core';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {BondingConsentsDto} from '../../../../bonding_shared';
import {AbstractConsentComponent} from '../abstract-consent.component';

@Component({
  selector: 'additional-documents-request-consent',
  template: `
    <div class="row flex">
      <!-- 29! -->
      <checkbox-entity
        class="start"
        name="additionalDocumentsRequest"
        [(ngModel)]="consents.additionalDocumentsRequest"
        [readonly]="readonly"
        [requiredTrue]="validate && true"
        #checkboxModel="ngModel"
      ></checkbox-entity>
      <div>
        <p>
          KUKE S.A. zastrzega sobie prawo zażądania dodatkowych dokumentów i informacji potwierdzających dane zawarte we
          wniosku i złożonych załącznikach lub których konieczność złożenia wyniknie w drodze analizy wniosku.
        </p>
        <p>Wypełnienie niniejszego formularza nie jest zobowiązaniem do zawarcia Umowy generalnej.</p>
        <p>
          Zgodnie z postanowieniami art. 35 Ustawy z dnia 11 września 2015 r. o działalności ubezpieczeniowej i
          reasekuracyjnej powyższe dane i informacje objęte są ochroną w zakresie wynikającym z wymienionego przepisu.
        </p>
        <error-message [control]="checkboxModel.control" [show]="showErrors"></error-message>
      </div>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}],
})
export class AdditionalDocumentsRequestConsentComponent extends AbstractConsentComponent<BondingConsentsDto> {}
