import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {CompanyCriteriaDto} from '../../../../bonding_shared';

@Component({
  selector: 'company-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <company-section
        [labelKey]="sectionLabel"
        [ngModel]="propertyValue(object)"
        [name]="property"
        [clearable]="false"
        [openSelectorEmitter]="openEmitter"
        [showLink]="showLink"
        [readOnly]="disabled || !searchable"
        [fullDetails]="fullDetails"
        [showInternalNumber]="showInternalNumber"
        [clientIdForInternalNumber]="clientIdForInternalNumber"
        [showPhysicalPerson]="showPhysicalPerson"
        [showSegment]="showSegment"
        [showSegmentVip]="showSegmentVip"
        [showRating]="showRating"
        [showInsuranceRating]="showInsuranceRating"
        [showPkd]="showPkd"
        [showIndustry]="showIndustry"
        [showNationalId]="showNationalId"
        [showStatNumber]="showStatNumber"
        [showType]="showType"
        [showPartnerNo]="showPartnerNo"
        [viewId]="viewId"
        [parentObjectId]="parentObjectId"
        (internalNumberClicked)="internalNumberClicked.emit()"
      >
      </company-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <company-selector-simple
        (selectItem)="onChange($event)"
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        [searchModeSwitchable]="searchModeSwitchable"
        [searchOnOpen]="searchOnOpen"
        [criteria]="criteria"
        [showPhysicalPersonSearchCriteria]="showPhysicalPersonSearchCriteria"
        [showInternalNumber]="showInternalNumber"
      ></company-selector-simple>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: CompanySectionSelectorComponent}],
})
export class CompanySectionSelectorComponent extends SectionSelector {
  @Input() criteria: CompanyCriteriaDto = <CompanyCriteriaDto>{};
  @Input() searchModeSwitchable = true;
  @Input() showInternalNumber = false;
  @Input() clientIdForInternalNumber: number;
  @Input() showPhysicalPersonSearchCriteria = false;
  @Input() showPhysicalPerson = true;
  @Input() searchOnOpen = true;
  @Input() showLink = true;
  @Input() searchable = true;
  @Input() showSegment = false;
  @Input() showSegmentVip = false;
  @Input() showRating = false;
  @Input() showInsuranceRating = false;
  @Input() showPkd = false;
  @Input() showIndustry = false;
  @Input() showNationalId = true;
  @Input() showStatNumber = true;
  @Input() showType = true;
  @Input() showPartnerNo = true;
  @Input() fullDetails = true;
  @Input() viewId: number;
  @Input() parentObjectId: number;
  @Output() internalNumberClicked = new EventEmitter<void>();
}
